prismanoteApp.directive('companyPriceSettings', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/company-price-settings.html',
    scope: {
      company: '=company',
      item: '=item'
    },
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      console.log('directives call for calculations', JSON.parse(JSON.stringify($scope.company)))
      if ($scope.item) $scope.item.priceIncVat = ($scope.item.price * (1 + ($scope.item.priceVat / 100))) // To show inc vat in directive
      console.log('directives call for calculations ITEM', $scope.item)
      // When coming from the purchase-order.pug directive then its direct product not company.product so we are doing it company.product
      // if ($scope.company && !$scope.company.product) $scope.company.product = JSON.parse(JSON.stringify($scope.company));
      // console.log('company: ', company);

      /**
             * {company} is form parent controller.
             * @param {company.products} company product details
             */
      $scope.calculateStorageFactorOrMargin = function (price, type) {
        console.log('Calucation For storage and margin')
        if (!$scope.calculations) $scope.calculations = {}

        if ($scope.open) $scope.open = false

        if ($scope.company.product.entryMethodCustomerValue){
          $scope.company.product.suggestedRetailerPriceMargin = Number(($scope.company.product.suggestedRetailerPriceForProduct / $scope.company.product.price))
        } else {
          if (type == 'storage') $scope.calculations.storageFactor = Number(($scope.company.product.price / $scope.company.product.purchasePrice))
          else $scope.calculations.margin = Number(($scope.company.product.price / $scope.company.product.purchasePrice).toString(2))
        }
      }
      // if($scope.company && $scope.company.product && ($scope.company.product.entryMethodCustomerValue == undefined || $scope.company.product.entryMethodCustomerValue == null)) $scope.displaySwitch = true;

      $scope.renderData = function (company) {
        console.log('company called: ', company)
        console.log(company.product.price, (1 + company.product.price + company.product.priceVat / 100))
        if (typeof (company.product.suggestedRetailerPriceForProduct) === 'string') company.product.suggestedRetailerPriceForProduct = Number(company.product.suggestedRetailerPriceForProduct)

        if (company.product.suggestedRetailerPriceForProduct && company.product.price && company.product.entryMethodCustomerValue) $scope.calculateSuggestedMargin(company.product.suggestedRetailerPriceForProduct, company.product.price)

        if (company.product.suggestedRetailerPriceForProduct && company.product.price && !company.product.entryMethodCustomerValue) $scope.calculateSuggestedStorageFactor(company.product.suggestedRetailerPriceForProduct, company.product.price)

        $scope.show = true
        if (company.product.entryMethodCustomerValue){
          $scope.calculateStorageFactorOrMargin(company.product.purchasePrice, 'margin')
          $scope.calculations.margin = Number(($scope.company.product.price / $scope.company.product.purchasePrice))
        }
        else if (company.product.entryMethodCustomerValue == false) $scope.calculateStorageFactorOrMargin(company.product.purchasePrice, 'storage')
        else $scope.displaySwitch = true
      }

      /**
             * @param {boolean} method if its true then sales method otherwise is purchase method
             */
      $scope.changeInputMethod = function (method) {
        console.log('changeInputMethod Method: ', method)

        // Purchase Method
        if (!method) {
          console.log('------- Purchase Method ---------')
          if ($scope.company.product.price != undefined && $scope.company.product.price != null && $scope.company.product.purchasePrice) {
            var storageFactor = $scope.company.product.price / $scope.company.product.purchasePrice
            $scope.calculations.storageFactor = Number(storageFactor)
          } else if ($scope.company.product.price && $scope.calculations && $scope.calculations.storageFactor) {
            $scope.calculatePurchasePriceFromStorageFactor($scope.calculations.storageFactor)
          } else if ($scope.company.product.purchasePrice && $scope.calculations && $scope.calculations.storageFactor) {
            $scope.calculateSellingPriceFromStorageFactor($scope.calculations.storageFactor)
          }

          if ($scope.company.product.suggestedRetailerPriceForProduct && $scope.company.product.price) $scope.calculateSuggestedStorageFactor($scope.company.product.suggestedRetailerPriceForProduct, $scope.company.product.price)
        } else {
          console.log('------- Selling Method ---------')
          if ($scope.company.product.price && $scope.company.product.purchasePrice) {
            var margin = $scope.company.product.price / $scope.company.product.purchasePrice
            $scope.calculations.margin = Number(margin)
            console.log('$scope.calculations.margin', $scope.calculations.margin)
          } else if ($scope.company.product.price && $scope.calculations && $scope.calculations.margin) {
            console.log('No Purchase Price')
            $scope.calculatePurchasePriceFromMargin($scope.calculations.margin)
          } else if ($scope.company.product.purchasePrice && $scope.calculations && $scope.calculations.margin) {
            console.log('No Price')
            $scope.calculateSellingPriceFromMargin($scope.calculations.margin)
          } else {
            console.log('Kasu j Nai')
          }
        }
      }

      // @param entryCustomer Value is based on {status}
      $scope.selectMethod = function (status) {
        $scope.company.product.entryMethodCustomerValue = status
        if (!$scope.calculations) $scope.calculations = {}
        if (status == false) { if ($scope.company.product.price && $scope.company.product.purchasePrice) $scope.calculations.storageFactor = Number(($scope.company.product.price / $scope.company.product.purchasePrice)) }

        $scope.displaySwitch = false
        $scope.changeInputMethod(status)
      }

      $scope.$watch('company.product.price', function () {
        if ($scope.open) $scope.open = false
        // if ($scope.company.product.entryMethodCustomerValue) $scope.calculations.margin = Number(($scope.company.product.price / $scope.company.product.purchasePrice).toFixed(2))
        // else
         $scope.company.product.suggestedRetailerPriceMargin = Number(($scope.company.product.suggestedRetailerPriceForProduct / $scope.company.product.price))
      })

      $scope.$watch('calculations.margin', function () {
        if ($scope.open) $scope.open = false
        if ($scope.calculations.margin) {
          $scope.company.product.purchasePrice = Number(($scope.company.product.price / $scope.calculations.margin))
        }
      })

      $scope.calculatePurchasePriceFromMargin = function (margin) {
        console.log('Margin', margin)
        if ($scope.open) $scope.open = false
        $scope.company.product.purchasePrice = Number(($scope.company.product.price / margin))
      }

      $scope.calculateSellingPriceFromMargin = function (margin) {
        console.log('Margin', margin)
        if ($scope.open) $scope.open = false
        $scope.company.product.price = $scope.company.product.purchasePrice * margin
        // $scope.company.product.price = ($scope.company.product.sellingPrice / (($scope.company.product.priceVat / 100) + 1));
      }

      $scope.calculateSellingPriceFromStorageFactor = function (factor) {
        console.log('factor', factor)
        if ($scope.open) $scope.open = false
        $scope.company.product.price = $scope.company.product.purchasePrice * factor
        // $scope.company.product.sellingPrice = ($scope.company.product.price * (($scope.company.product.priceVat / 100) + 1));
      }

      $scope.calculatePurchasePriceFromStorageFactor = function (factor) {
        if ($scope.open) $scope.open = false
        $scope.company.product.purchasePrice = Number(($scope.company.product.price / factor)) || 0
      }

      $scope.calculateSuggestedMargin = function (retailerPrice, price) {
        $scope.company.product.suggestedRetailerPriceMargin = Number((retailerPrice / price)) || 0
      }

      $scope.calculateSuggestedStorageFactor = function (retailerPrice, price) {
        $scope.company.product.suggestedRetailerPriceStorageFactor = Number((retailerPrice / (($scope.company.product.priceVat / 100) + 1) / price)) || 0
        console.log('$scope.company.product.suggestedRetailerPriceStorageFactor', $scope.company.product.suggestedRetailerPriceStorageFactor)
      }

      $scope.calculateSuggestedPriceAndSellingPrice = function (margin, entryMethodCustomerValue) {
        console.log('entryMethodCustomerValue', entryMethodCustomerValue)
        if (entryMethodCustomerValue) {
          $scope.company.product.price = ($scope.company.product.suggestedRetailerPriceForProduct / margin) || 0
        } else {
          $scope.company.product.suggestedRetailerPriceForProduct = (($scope.company.product.price * (($scope.company.product.priceVat / 100) + 1)) * margin) || 0
        }
      }
    }]
  }
})

prismanoteApp.directive('twoDecimal', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      
      ngModel.$formatters.push(function(value) {debugger
        return Math.round(value * 100) / 100;
      });
      
    }
  }
});