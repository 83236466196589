prismanoteApp.directive('productFilterByParticulars', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-particulars.html',
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      $scope.miscProperties = {
        swissMovement: false,
        dateFunction: false,
        antiAllergy: false,
        nickelFree: false,
        lightFunction: false
      }

      function getParticularsFilter () {
        $retailerFilters.getParticularsFilter()
          .then(function (particularsFilter) {
            particularsFilter = JSON.parse(particularsFilter)

            if (particularsFilter) {
              if (particularsFilter['watch.hasDateFunction']) $scope.miscProperties.dateFunction = true
              if (particularsFilter['watch.hasSwissMovement']) $scope.miscProperties.swissMovement = true
              if (particularsFilter['watch.hasLightFunction']) $scope.miscProperties.lightFunction = true
              if (particularsFilter['watch.isAntiAllergy']) $scope.miscProperties.antiAllergy = true
              if (particularsFilter['watch.isNickelFree']) $scope.miscProperties.nickelFree = true
            }

            $scope.setMiscPropertiesNew('localStorage')
          })
          .catch(function (error) {
            console.log('Error', error)
          })
      }

      getParticularsFilter()

      $scope.$on('particulars_filter_remove', function (event, sort) {
        var remove = sort.removeFilter

        if (remove.name === 'SwissMovement') {
          $scope.miscProperties.swissMovement = false
          $scope.setMiscPropertiesNew()
        } else if (remove.name == 'DateFunction') {
          $scope.miscProperties.dateFunction = false
          $scope.setMiscPropertiesNew()
        } else if (remove.name === 'LightFunction') {
          $scope.miscProperties.lightFunction = false
          $scope.setMiscPropertiesNew()
        } else if (remove.name === 'AntiAllergy') {
          $scope.miscProperties.antiAllergy = false
          $scope.setMiscPropertiesNew()
        } else if (remove.name === 'NickelFree') {
          $scope.miscProperties.nickelFree = false
          $scope.setMiscPropertiesNew()
        }
      })

      $scope.setMiscPropertiesNew = function (status) {
        var filter = {}
        var miscProperties = []

        var allMiscProperties = ['watch.hasSwissMovement', 'watch.hasDateFunction', 'watch.hasLightFunction', 'watch.isAntiAllergy', 'watch.isNickelFree']

        if ($scope.miscProperties) {
          if ($scope.miscProperties.swissMovement) {
            miscProperties.push('watch.hasSwissMovement')
            var temp = { name: 'SwissMovement', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (miscProperties.includes('watch.hasSwissMovement')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasSwissMovement'))
            var temp = { name: 'SwissMovement', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'SwissMovement', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.miscProperties.dateFunction) {
            miscProperties.push('watch.hasDateFunction')
            var temp = { name: 'DateFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (miscProperties.includes('watch.hasDateFunction')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasDateFunction'))
            var temp = { name: 'DateFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'DateFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.miscProperties.lightFunction) {
            miscProperties.push('watch.hasLightFunction')
            var temp = { name: 'LightFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (miscProperties.includes('watch.hasLightFunction')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasLightFunction'))
            var temp = { name: 'LightFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'LightFunction', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.miscProperties.antiAllergy) {
            miscProperties.push('watch.isAntiAllergy')
            var temp = { name: 'AntiAllergy', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (miscProperties.includes('watch.isAntiAllergy')) {
            miscProperties.splice(miscProperties.indexOf('watch.isAntiAllergy'))
            var temp = { name: 'AntiAllergy', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'AntiAllergy', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.miscProperties.nickelFree) {
            miscProperties.push('watch.isNickelFree')
            var temp = { name: 'NickelFree', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (miscProperties.includes('watch.isNickelFree')) {
            miscProperties.splice(miscProperties.indexOf('watch.isNickelFree'))
            var temp = { name: 'NickelFree', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'NickelFree', filter: 'Particulars' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          for (var i = 0; i < allMiscProperties.length; i++) {
            if (miscProperties.includes(allMiscProperties[i])) {
              filter[allMiscProperties[i]] = true
            } else {
              filter[allMiscProperties[i]] = undefined
            }
          }
        }

        $retailerFilters.setParticularsFilter(filter)
        if (status && status == 'localStorage') filter.status = status

        $scope.$emit('particulars_filter_directive', { filter: filter })
      }
    }]
  }
})
