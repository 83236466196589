prismanoteApp.directive('recommendationFilterByBrand', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-brand.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', '$rootScope', function ($scope, $rootScope) {

            console.log('------------------------------$scope.filter.brands called: ', $scope.filter);

            $scope.selectBrand = function () {
                console.log('selectBrand called: ', JSON.parse(JSON.stringify($scope.filter)));
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})