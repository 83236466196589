prismanoteApp.directive('productFilterByCollection', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-collection.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setCollectionFilter = function (collectionName) {
        var filter = {}

        if ($scope.collectionFilter) {
          if ($scope.collectionFilter.collectionName) {
            filter.collectionName = collectionName
          } else {
            filter.collectionName = undefined
          }

          $scope.getProducts({ filter: filter }, { reset: true })

            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }]
  }
})
