prismanoteApp.directive('recommendationFilterByStrapModel', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-strap-model.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', '$rootScope', function ($scope, $rootScope) {

            console.log('$scope.filter.selectStrapModel called: ', $scope.filter.selectStrapModel);

            $scope.selectStrapModel = function () {
                // console.log('selectStrapModel called: ', $scope.filter.selectStrapModel);
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})