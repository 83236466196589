prismanoteApp.directive('productFilterByPrice', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-price.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      Init_In_Price()
      $scope.$on('clear-the-filter', function (event) {
        Init_In_Price()
      })

      $scope.$on('clear-the-price-filter', function (event) {
        Init_In_Price()
      })

      function Init_In_Price () {
        $scope.priceRange = {
          min: 0,
          max: 1200,
          options: {
            floor: 0,
            step: 5,
            translate: function (value, sliderId, label) {
              switch (label) {
                case 'ceil':
                  return '€ ' + value + '+'
                case 'high':
                  if (value == 1200) {
                    return '€ ' + value + '+'
                  } else {
                    return '€ ' + value
                  }
                default:
                  return '€ ' + value
              }
            },
            onEnd: function () {
              if ($scope.isShop) {
                filter = {
                  price: {
                    $gte: $scope.priceRange.min / 1.21
                  }
                }
                if ($scope.priceRange.max != 1200 && $scope.priceRange.max != 0) {
                  filter.price.$lte = $scope.priceRange.max / 1.21
                }
              } else if ($scope.currentShop) {
                filter.price = {}
                filter.price = { $gte: $scope.priceRange.min }

                if ($scope.priceRange.max != 1200 && $scope.priceRange.max != 0) {
                  filter.price.$lte = $scope.priceRange.max
                } else if ($scope.priceRange.max == 1200) {
                  filter.price.$lte = $scope.priceRange.max
                }
              } else {
                filter = {
                  suggestedRetailPrice: {
                    $gte: $scope.priceRange.min / 1.21
                  }
                }
                if ($scope.priceRange.max != 1200 && $scope.priceRange.max != 0) {
                  filter.suggestedRetailPrice.$lte = $scope.priceRange.max / 1.21
                }
              }
              if (filter.price) {
                $scope.$emit('price_filter_directive', { great: filter.price.$gte, less: filter.price.$lte })
              } else {
                $scope.$emit('price_filter_directive', { great: filter.suggestedRetailPrice.$gte, less: filter.suggestedRetailPrice.$lte })
              }
            }

          }
        }
      }
    }]
  }
})
