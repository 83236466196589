prismanoteApp.directive('productFilterByJewelMaterialNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-jewel-material-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.setJewelMaterial = function () {
        var filter = { 'jewel.material': {} }
        var jewelMaterials = []

        if ($scope.jewelMaterial) {
          if ($scope.jewelMaterial.gold) {
            jewelMaterials.push('GOLD')
            if (_.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'GOLD', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('GOLD')) {
            jewelMaterials.splice(jewelMaterials.indexOf('GOLD'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.silver) {
            jewelMaterials.push('SILVER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'SILVER', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'SILVER', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('SILVER')) {
            jewelMaterials.splice(jewelMaterials.indexOf('SILVER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SILVER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SILVER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.titanium) {
            jewelMaterials.push('TITANIUM')
            if (_.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'TITANIUM', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('TITANIUM')) {
            jewelMaterials.splice(jewelMaterials.indexOf('TITANIUM'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.stainlessSteel) {
            jewelMaterials.push('STAINLESS_STEEL')
            if (_.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'STAINLESS_STEEL', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('STAINLESS_STEEL')) {
            jewelMaterials.splice(jewelMaterials.indexOf('STAINLESS_STEEL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.aluminium) {
            jewelMaterials.push('ALUMINIUM')
            if (_.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'ALUMINIUM', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('ALUMINIUM')) {
            jewelMaterials.splice(jewelMaterials.indexOf('ALUMINIUM'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.metal) {
            jewelMaterials.push('METAL')
            if (_.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'ALUMINIUM', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('METAL')) {
            jewelMaterials.splice(jewelMaterials.indexOf('METAL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.wood) {
            jewelMaterials.push('WOOD')
            if (_.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'WOOD', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('WOOD')) {
            jewelMaterials.splice(jewelMaterials.indexOf('WOOD'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.ceramics) {
            jewelMaterials.push('CERAMICS')
            if (_.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'CERAMICS', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('CERAMICS')) {
            jewelMaterials.splice(jewelMaterials.indexOf('CERAMICS'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.puLeather) {
            jewelMaterials.push('PU_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'PU_LEATHER', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('PU_LEATHER')) {
            jewelMaterials.splice(jewelMaterials.indexOf('PU_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.leather) {
            jewelMaterials.push('REAL_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'REAL_LEATHER', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('REAL_LEATHER')) {
            jewelMaterials.splice(jewelMaterials.indexOf('REAL_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.upperLeather) {
            jewelMaterials.push('UPPER_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'UPPER_LEATHER', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('UPPER_LEATHER')) {
            jewelMaterials.splice(jewelMaterials.indexOf('UPPER_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelMaterial.canvas) {
            jewelMaterials.push('CANVAS')
            if (_.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Jewel Material' }) < 0) $rootScope.selectdFilter.push({ name: 'CANVAS', filter: 'Jewel Material' })
          } else if (jewelMaterials.includes('CANVAS')) {
            jewelMaterials.splice(jewelMaterials.indexOf('CANVAS'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Jewel Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if (jewelMaterials.length == 0) {
            // jewelMaterials = ['TITANIUM', 'STAINLESS_STEEL', 'ALUMINIUM', 'METAL', 'PU_LEATHER', 'real leather', 'UPPER_LEATHER', 'PLASTIC_SILICONE', 'CANVAS', 'CERAMICS'];
          }

          filter['jewel.material'] = { $in: jewelMaterials }

          $scope.$emit('jewel_material_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
