prismanoteApp.controller('editTranslationFromKeyword', ['$scope', 'shopId', 'websiteId', '$uibModalInstance', 'grapeFactory', 'translationKeyword',
  function ($scope, shopId, websiteId, $uibModalInstance, grapeFactory, translationKeyword) {
    $scope.translation = {
      en: '',
      nl: '',
      es: '',
      fr: '',
      de: ''
    }

    $scope.keyword = translationKeyword
    $scope.isEdit = !!(translationKeyword && translationKeyword !== '')
    $scope.enSelected = false
    $scope.nlSelected = false
    $scope.esSelected = false
    $scope.deSelected = false
    $scope.frSelected = false

    $scope.defaultLanguage = 'nl'

    $scope.alert = {
      type: '',
      message: ''
    }

    $scope.selectedLanguages = []

    $scope.getTranslationUsingKeyword = function () {
      if ($scope.isEdit) {
        // We need to fetch translations using keyword
        return grapeFactory.getTranslationUsingKeyword(websiteId, shopId, $scope.keyword)
          .then(function (response) {
            if (response && response.data && response.data.result && response.data.result.translation && Object.keys(response.data.result.translation).length > 0) {
              $scope.translation.en = response.data.result.translation.en ? response.data.result.translation.en : ''
              $scope.translation.nl = response.data.result.translation.nl ? response.data.result.translation.nl : ''
              $scope.translation.es = response.data.result.translation.es ? response.data.result.translation.es : ''
              $scope.translation.fr = response.data.result.translation.fr ? response.data.result.translation.fr : ''
              $scope.translation.de = response.data.result.translation.de ? response.data.result.translation.de : ''
            }
          })
          .catch(function (error) {
            $scope.alert = {
              type: 'danger',
              message: error.data.message || 'Something went wrong'
            }
          })
      } else {
        $scope.keyword = 'translation-' + Date.now()
      }
    }

    $scope.getWebsiteDetails = function () {
      return grapeFactory.getWebsiteDetail(shopId, websiteId)
        .then(function (response) {
          $scope.websiteDetail = response.data.websiteDetail
          $scope.selectedLanguages = $scope.websiteDetail.availableLanguagesForTranslation && $scope.websiteDetail.availableLanguagesForTranslation.length ? $scope.websiteDetail.availableLanguagesForTranslation : []
          $scope.defaultLanguage = $scope.websiteDetail.defaultLanguage

          if ($scope.selectedLanguages.length) {
            for (var i = 0; i < $scope.selectedLanguages.length; i++) {
              if ($scope.selectedLanguages[i] === 'en') {
                $scope.enSelected = true
              } else if ($scope.selectedLanguages[i] === 'nl') {
                $scope.nlSelected = true
              } else if ($scope.selectedLanguages[i] === 'es') {
                $scope.esSelected = true
              } else if ($scope.selectedLanguages[i] === 'de') {
                $scope.deSelected = true
              } else if ($scope.selectedLanguages[i] === 'fr') {
                $scope.frSelected = true
              }
            }
          }

          $scope.getTranslationUsingKeyword()
        })
        .catch(function (error) {
          $scope.alert = {
            type: 'danger',
            message: error.data.message
          }
        })
    }

    $scope.setDefaultLanguage = function (language) {
      $scope.defaultLanguage = language
    }

    $scope.changeSelectedLanguage = function (language) {
      console.log('language', language)
    }

    $scope.customDomainOk = function () {
      var request = {
        translation: $scope.translation
      }

      grapeFactory
        .updateKeywordTranslation(websiteId, shopId, $scope.keyword, request)
        .then(function () {
          $uibModalInstance.close({
            status: true,
            keyword: $scope.keyword,
            translation: $scope.translation
          })
        })
        .catch(function (error) {
          // $uibModalInstance.close(true);
          $scope.alert.type = 'danger'
          $scope.alert.msg = error.data.message || 'Something went wrong. Please try again'
        })
    }

    $scope.closeAlert = function () {
      $scope.alert = {
        type: '',
        msg: ''
      }
    }
  }
])
