prismanoteApp.directive('productFilterByGenderNew', function () {
  var filter = {}
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-gender-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      $scope.status

      function getCurrentGenderFilters () {
        $retailerFilters.getGenderFilter()
          .then(function (genderFilter) {
            genderFilter = (genderFilter) ? JSON.parse(genderFilter) : null
            $scope.gents = $scope.ladies = $scope.boys = $scope.girls = false

            if (genderFilter && genderFilter.gender && genderFilter.gender.length) {
              for (var i = 0; i < genderFilter.gender.length; i++) {
                if (genderFilter.gender[i]['product.female'] && !genderFilter.gender[i]['product.kids']) $scope.ladies = true
                if (genderFilter.gender[i]['product.female'] && genderFilter.gender[i]['product.kids']) $scope.girls = true
                if (genderFilter.gender[i]['product.male'] && genderFilter.gender[i]['product.kids']) $scope.boys = true
                if (genderFilter.gender[i]['product.male'] && !genderFilter.gender[i]['product.kids']) $scope.gents = true
              }

              $scope.status = 'localStorage'
              $scope.setGender($scope.gents, $scope.ladies, $scope.boys, $scope.girls)
            } else {
              $scope.status = 'localStorage'
              $scope.setGender($scope.gents, $scope.ladies, $scope.boys, $scope.girls)
            }
          })
          .catch(function (error) {
            console.log('Error', error)
          })
      }

      getCurrentGenderFilters()

      $scope.$on('gender_filter_remove', function (event, sort) {
        var remove = sort.removeFilter
        if (remove.name === 'Men') {
          $scope.gents = false
          $scope.setGender(false, $scope.ladies, $scope.boys, $scope.girls)
        } else if (remove.name === 'Ladies') {
          $scope.ladies = false
          $scope.setGender($scope.gents, false, $scope.boys, $scope.girls)
        } else if (remove.name === 'Boy') {
          $scope.boys = false
          $scope.setGender($scope.gents, $scope.ladies, false, $scope.girls)
        } else if (remove.name === 'Girl') {
          $scope.girls = false
          $scope.setGender($scope.gents, $scope.ladies, $scope.boys, false)
        }
      })

      $scope.setGender = function (gents, ladies, boys, girls) {
        var flag = true;
        // console.log('-------- setGender!!!');
        // console.log('gents', gents);
        // console.log('ladies', ladies);
        // console.log('boys', boys);
        // console.log('girls', girls);
        // console.log(flag);

        $scope.gents = gents
        $scope.ladies = ladies
        $scope.boys = boys
        $scope.girls = girls
        // console.log('$rootScope.selectdFilter');
        // console.log(JSON.stringify($rootScope.selectdFilter, null, 2));
        var tempLength = $rootScope.selectdFilter.length
        for (var i = 0; i < tempLength; i++) {
          if ($rootScope.selectdFilter[i].filter == 'Gender') {
            // console.log(JSON.stringify($rootScope.selectdFilter, null, 2));
            $rootScope.selectdFilter.splice(i, 1)
            tempLength--
            i--
            // console.log('----------- inside loop!!');
            // console.log(JSON.stringify($rootScope.selectdFilter, null, 2));
          }
        }
        // console.log('----------- after loop!!');
        // console.log(JSON.stringify($rootScope.selectdFilter, null, 2));

        filter.gender = {}

        // console.group('Gender Filter')

        if ($scope.gents) {
          // console.log('file name: product-filter-by-gender-new-directiv.js');
          console.log('$scope.gents', $scope.gents)
          if ($scope.ladies) {
            console.log('$scope.ladies', $scope.ladies)
            if ($scope.boys) {
              console.log('$scope.ladies', $scope.boys)
              if ($scope.girls) {
                filter = {}
              } else {
                filter = {
                  gender: [
                    {
                      'product.kids': false,
                      'product.male': true
                    },
                    {
                      'product.male': true,
                      'product.kids': true

                    }
                  ]
                }

                var temp = { name: 'Men', filter: 'Gender' }
                var index = _.findIndex($rootScope.selectdFilter, temp)
                if (index < 0) {
                  $rootScope.selectdFilter.push(temp)
                }

                var temp1 = { name: 'Boy', filter: 'Gender' }
                var index1 = _.findIndex($rootScope.selectdFilter, temp1)
                if (index1 < 0) {
                  $rootScope.selectdFilter.push(temp1)
                }
              }
            } else {
              if ($scope.girls) {
                console.log('$scope.girls', $scope.girls)
                filter = {
                  gender: [
                    {
                      'product.kids': false
                    },
                    {
                      'product.female': true
                    }
                  ]
                }

                var index = _.findIndex($rootScope.selectdFilter, { name: 'Ladies', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Ladies', filter: 'Gender' })

                // gents, ladies
              } else {
                console.log('Only Gents and Ladies')
                filter = {
                  gender: [
                    {
                      'product.kids': false,
                      'product.male': true
                    },
                    {
                      'product.female': true,
                      'product.kids': false
                    }
                  ]
                }

                var index = _.findIndex($rootScope.selectdFilter, { name: 'Men', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Men', filter: 'Gender' })

                var index1 = _.findIndex($rootScope.selectdFilter, { name: 'Ladies', filter: 'Gender' })
                if (index1 < 0) $rootScope.selectdFilter.push({ name: 'Ladies', filter: 'Gender' })
              }
            }
          } else {
            if ($scope.boys) {
              // gents, boys, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      'product.kids': true
                    },
                    {
                      'product.male': true
                    }
                  ]
                }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Boy', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Boy', filter: 'Gender' })
                // gents, boys
              } else {
                filter = { gender: [{ 'product.male': true, 'product.kids': false }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Men', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Men', filter: 'Gender' })
              }
            } else {
              // gents, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      'product.kids': true,
                      'product.female': true
                    },
                    {
                      'product.kids': false,
                      'product.male': true
                    }
                  ]
                }

                var index = _.findIndex($rootScope.selectdFilter, { name: 'Boy', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Boy', filter: 'Gender' })

                var index1 = _.findIndex($rootScope.selectdFilter, { name: 'Men', filter: 'Gender' })
                if (index1 < 0) $rootScope.selectdFilter.push({ name: 'Men', filter: 'Gender' })

                // gents
              } else {
                filter = { gender: [{ 'product.male': true, 'product.kids': false }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Men', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Men', filter: 'Gender' })
              }
            }
          }
        } else {
          if ($scope.ladies) {
            // console.log('------- else ladies!!')
            if ($scope.boys) {
              // ladies, boys, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      'product.kids': true
                    },
                    {
                      'product.female': true
                    }
                  ]
                }

                var index = _.findIndex($rootScope.selectdFilter, { name: 'Girl', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Girl', filter: 'Gender' })

                // ladies, boys
              } else {
                filter = {
                  gender: [
                    {
                      'product.kids': false,
                      'product.female': true
                    },
                    {
                      'product.kids': true,
                      'product.male': true
                    }
                  ]
                }

                var index = _.findIndex($rootScope.selectdFilter, { name: 'Ladies', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Ladies', filter: 'Gender' })

                var index1 = _.findIndex($rootScope.selectdFilter, { name: 'Boy', filter: 'Gender' })
                if (index1 < 0) $rootScope.selectdFilter.push({ name: 'Boy', filter: 'Gender' })
              }
            } else {
              // ladies, girls
              if ($scope.girls) {
                filter = { gender: [{ 'product.female': true, 'product.kids': false }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Ladies', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Ladies', filter: 'Gender' })
                // ladies
              } else {
                filter = { gender: [{ 'product.female': true, 'product.kids': false }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Ladies', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Ladies', filter: 'Gender' })
              }
            }
          } else {
            if ($scope.boys) {
              console.log('$scope.boys', $scope.boys)
              // boys, girls
              if ($scope.girls) {
                console.log('$scope.girls', $scope.girls)
                filter = { gender: [{ 'product.kids': true, 'product.male': true }, { 'product.kids': true, 'product.female': true }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Boy', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Boy', filter: 'Gender' })

                var index1 = _.findIndex($rootScope.selectdFilter, { name: 'Girl', filter: 'Gender' })
                if (index1 < 0) $rootScope.selectdFilter.push({ name: 'Girl', filter: 'Gender' })
                // boys
              } else {
                console.log('Only Boy')
                filter = { gender: [{ 'product.kids': true, 'product.male': true }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Boy', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Boy', filter: 'Gender' })
              }
            } else {
              // girls
              if ($scope.girls) {
                console.log('Only Girls')
                filter = { gender: [{ 'product.kids': true, 'product.female': true }] }
                var index = _.findIndex($rootScope.selectdFilter, { name: 'Girl', filter: 'Gender' })
                if (index < 0) $rootScope.selectdFilter.push({ name: 'Girl', filter: 'Gender' })
                // nothing selected, default to: gents, ladies, boys, girls.
              } else {
                //console.log(flag);
                flag = false;
                //console.log('-------------- Calling inside last else!!');
                filter = {}
                $retailerFilters.removeGenderFilter()
              }
            }
          }
        }
        delete filter.male
        delete filter.female
        delete filter.kids
        //delete filter.ladies
        //console.log('-------------- filter before setGenderFilter call !!');
        //console.log(JSON.stringify(filter, null, 2));
        //console.log(flag);
        if(flag) $retailerFilters.setGenderFilter(JSON.stringify(filter))

        if ($scope.status && $scope.status == 'localStorage') {
          delete $scope.status
          filter.status = 'localStorage'
        }

        if (filter) JSON.parse(JSON.stringify(filter))

        console.groupEnd()
        // console.log(JSON.stringify(filter, null, 2));

        // console.log(JSON.stringify($rootScope.selectdFilter, null, 2));
        // console.log(filter);
        $scope.$emit('gender_filter_directive', { filter: filter })
      }
    }]
  }
})
