prismanoteApp.directive('productFilterByMiscProperties', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-misc-properties.html',
    scope: false,
    controller: ['$scope', '$parse', function ($scope, $parse) {
      $scope.miscProperties = {
        swissMovement: false,
        dateFunction: false,
        antiAllergy: false,
        nickelFree: false,
        lightFunction: false
      }

      $scope.setMiscProperties = function () {
        console.log('product handling guy The Old Directive is called')
        var filter = {}
        var miscProperties = []

        var allMiscProperties = ['watch.hasSwissMovement', 'watch.hasDateFunction', 'watch.hasLightFunction', 'watch.isAntiAllergy', 'watch.isNickelFree']

        if ($scope.miscProperties) {
          if ($scope.miscProperties.swissMovement) {
            miscProperties.push('watch.hasSwissMovement')
          } else if (miscProperties.includes('watch.hasSwissMovement')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasSwissMovement'))
          }

          if ($scope.miscProperties.dateFunction) {
            miscProperties.push('watch.hasDateFunction')
          } else if (miscProperties.includes('watch.hasDateFunction')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasDateFunction'))
          }

          if ($scope.miscProperties.lightFunction) {
            miscProperties.push('watch.hasLightFunction')
          } else if (miscProperties.includes('watch.hasLightFunction')) {
            miscProperties.splice(miscProperties.indexOf('watch.hasLightFunction'))
          }

          if ($scope.miscProperties.antiAllergy) {
            miscProperties.push('watch.isAntiAllergy')
          } else if (miscProperties.includes('watch.isAntiAllergy')) {
            miscProperties.splice(miscProperties.indexOf('watch.isAntiAllergy'))
          }

          if ($scope.miscProperties.nickelFree) {
            miscProperties.push('watch.isNickelFree')
          } else if (miscProperties.includes('watch.isNickelFree')) {
            miscProperties.splice(miscProperties.indexOf('watch.isNickelFree'))
          }

          for (var i = 0; i < allMiscProperties.length; i++) {
            if (miscProperties.includes(allMiscProperties[i])) {
              filter[allMiscProperties[i]] = true
            } else {
              filter[allMiscProperties[i]] = undefined
            }
          }
        }

        $scope.$emit('particulars_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        $scope.miscProperties = {
          swissMovement: false,
          dateFunction: false,
          antiAllergy: false,
          nickelFree: false,
          lightFunction: false
        }
      })

      $scope.$on('clear-the-particulars-filter', function (event, params) {
        $scope.miscProperties = {
          swissMovement: false,
          dateFunction: false,
          antiAllergy: false,
          nickelFree: false,
          lightFunction: false
        }
      })
    }]
  }
})
