prismanoteApp.directive('customFilterByCompanyProductProperties', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-filter-by-company-product-properties.html',
        scope: {
            filter: '=filter',
        },
        controller: ['$scope', function ($scope) {
            // console.log('customFilterByCompanyProductProperties: ',  $scope.filter);
        }]
    }
})