prismanoteApp.directive('goldPurchaseTransaction', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/gold-purchase-transaction.html',
    scope: {
      customer: '=customer',
      updateCustomerFromDirective: '&updateCustomerFromDirective'
    },
    controller: [
      '$scope', '$rootScope', '$shop', '$transaction', '$state', '$q', '$customer', 'prompt', '$language', '$productConfig', '$api', '$CashRegistry', '$timeout', 'FileUploader', 'dialogs',
      function ($scope, $rootScope, $shop, $transaction, $state, $q, $customer, prompt, $language, $productConfig, $api, $CashRegistry, $timeout, FileUploader, dialogs) {
        console.log('From gold-purchase-transaction')

        $scope.taxes = []
        $scope.products = []
        $scope.companies = []
        $scope.checkoutWithoutCustomer = false
        $scope.loading = true
        $scope.alert = {}
        $scope.editItem = false
        $scope.noMoreOrdersCanAdd = false
        $scope.editItemIndex
        $scope.goldPurchaseTransaction = {
          details: [],
          language: $rootScope.language,
          brand: {},
          company: {},
          type: 'gold-purchase',
          status: 'delivered',
          material: 'GOLD',
          category: 'UNKNOWN'
        }
        $scope.offerItem = {
          priceVat: 0,
          quantity: -1,
          total: -1,
          price: 1,
          name: $language.translate('GOLD_PURCHASE')
        }
        $scope.trans = {
          items: []
        }

        var uploader = $scope.uploader = new FileUploader({
          url: 'api/transactions/upload'
        })

        uploader.filters.push({
          name: 'duplicateFilesFilter',
          fn: function (item) {
            var index = _.findIndex($scope.uploader.queue, function (q) {
              return q.file.name == item.name
            })
            return index < 0
          }
        })

        // If customer change or seleted then we need to set into giftcard transaction details. And updated transaction we will get into $watch
        $scope.$watch('customer', function () {
          if ($scope.customer && $scope.customer._id) {
            $scope.customer = $scope.customer
            $scope.goldPurchaseTransaction.customer = $scope.customer._id
          }
        })

        // Get shop brands
        $scope.getShopBrands = function () {
          $scope.shopBrands = []
          $shop.getBrandsByShop($rootScope.currentShop.nameSlug)
            .then(function (brands) {
              $scope.shopBrands = brands.brands
            })
            .catch(function (error) {
              console.error('Error! While getting Shop Brands')
              console.error(error)
            })
        }

        // search in brands
        $scope.searchBrands = function (str) {
          var matches = []
          $scope.shopBrands.forEach(function (item) {
            if (
              item._id &&
                            Object.keys(item._id).length > 0 &&
                            (
                              item._id[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0
                            ) ||
                            (
                              item._id.nameSlug &&
                                item._id.nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0
                            )
            ) {
              matches.push(item)
            }
          })

          return matches
        }

        // Remove selected brand
        $scope.removeBrand = function () {
          $scope.goldPurchaseTransaction.brand = null
        }

        // Selected brand
        $scope.selectedBrand = function (selected) {
          try {
            $scope.goldPurchaseTransaction.brand = {
              _id: selected.originalObject._id._id,
              name: selected.originalObject.name || selected.originalObject._id[$rootScope.language].name
            }
          } catch (error) {
            console.error('error while select brand: ', error)
          }
        }

        // Fetch all posible tax rates which is attached to this shop. Initially it will be 0 because of gold purchase
        $scope.getTaxes = function () {
          $scope.offerItem.priceVat = 0

          if ($scope.taxes && $scope.taxes.length) {
            return
          }

          $shop.getTaxForShop($rootScope.currentShop._id)
            .then(function (res) {
              $scope.taxes = res.taxes.rates
              $scope.loading = false
              $scope.alert = {}
            })
            .catch(function (reason) {
              console.error('Error while getting taxes for this shop', reason)
              $scope.alert = {
                type: 'danger',
                message: 'Something went wrong. Please try agaign'
              }
              $scope.loading = false
            })
        }

        // Open customer selection pop-up
        $scope.openSearchCustomerModal = function () {
          $rootScope.checkModule('PACKAGE_CUSTOMER_MANAGEMENT', true, true)
            .then(function () {
              $customer.openSearchCustomerModal()
                .then(function (result) {
                  $scope.customer = result
                  $scope.updateCustomerFromDirective({ customer: result })
                  $scope.checkoutWithoutCustomer = false
                })
                .catch(function (reason) {
                  console.error('REASON', reason)
                })
            })
        }

        // Find all products
        $scope.findAllProducts = function (searchvalue) {
          if (!searchvalue || searchvalue.length < 3) {
            return
          }

          searchvalue = $productConfig.retrunValidProductNumber(searchvalue)
          $scope.offerItem.name = searchvalue
          $scope.products = []
          $scope.productNumberStatus = 'default'

          var data = {
            nameSlug: $rootScope.currentShop.nameSlug,
            searchMode: $scope.selectedMode === 'OFFER' && $scope.searchMode !== 'exact' ? $scope.searchMode : 'stock',
            searchText: searchvalue,
            shopId: $rootScope.currentShop._id,
            shopProducts: true,
            useFor: 'service-app'
          }

          $api.put('purchase-order-products-search', data)
            .then(function (res) {
              if (res.status != 200) {
                return
              }
              // if (res.data.data.status != undefined || res.data.data.status != null){
              $scope.productNumberStatus = parseInt(res.data.data.status)
              $scope.products = res.data.data.products
              // }
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }

        // Delete search result
        $scope.deleteSearchResults = function () {
          $scope.products = []
        }

        $scope.useAsNew = function () {
          $scope.products = []
          $scope.searching = false
        }

        // Remove VAT
        $scope.noVat = function (index) {
          var priceWithoutVat = $scope.offerItem.price / (1 + ($scope.offerItem.priceVat / 100))
          var price = Math.round(((priceWithoutVat + Number.EPSILON) * 100)) / 100
          $scope.offerItem.price = price
          $scope.offerItem.priceVat = 0
          $scope.offerItem.vatRemoved = true
        }

        // Delete item
        $scope.deleteItem = function (index) {
          $scope.offerItem = {}
          $scope.cancelItem()
          $scope.goldPurchaseTransaction.details.splice(index, 1)
          $scope.noMoreOrdersCanAdd = false
        }

        // Cancle item
        $scope.cancelItem = function () {
          $scope.productAlert = null
          $scope.offerIndex = null
          $scope.offerItem = {}
          $scope.editItem = false
          $scope.noMoreOrdersCanAdd = !!$scope.goldPurchaseTransaction.details.length
        }

        // Add item
        $scope.addItem = function (product) {
          $scope.productAlert = null

          $scope.products = []
          if (product && product.brand && product.brand._id) {
            // got brand. So, we are going to check for this brand is attached to our shop and that attached brand have favourite supplier exists or not? If not then need to open pop-up for supplier
            $scope.goldPurchaseTransaction.brand = {
              _id: product.brand._id,
              name: product.brand.name || product.brand[$rootScope.language].name
            }
          }

          $timeout(function () {
            angular.element('#instantResults').addClass('instant-hide')
          }, 300)

          return $CashRegistry.makeProductTextAndGetStock(product, $scope.goldPurchaseTransaction.details)
            .then(function (data) {
              $scope.offerItem = {
                name: data.name,
                productNumber: product.articleNumber ? product.articleNumber : product.variants[0].productNumber,
                ean: product.variants[0].ean,
                quantity: -1,
                price: 1,
                productId: product._id,
                photo: product.images && product.images.length > 0 ? {
                  src: product.images[0].src,
                  alt: product.images[0].alt
                } : null,
                priceVat: 0,
                vatRemoved: false
              }

              $scope.loading = false
            })
            .catch(function (error) {
              console.error(error)
            })
        }

        $scope.saveItem = function (item, tax, index, noSave) {
          $scope.productAlert = null
          item.quantity = item.quantity > 0 ? -item.quantity : item.quantity
          item.total = item.price * item.quantity

          if (index >= 0) {
            // Alternate mode update
            $scope.goldPurchaseTransaction.details[index] = item
            $scope.offerItem = {}
          } else {
            if (!$scope.editItem) {
              $scope.goldPurchaseTransaction.details.push(angular.copy(item))
            } else {
              $scope.goldPurchaseTransaction.details[$scope.offerIndex] = item
            }

            $scope.offerItem = {
              priceVat: 0
            }

            $scope.editItem = false
            $scope.offerIndex = null
            $scope.noMoreOrdersCanAdd = true
          }
        }

        // Get total quantity
        $scope.getTotalQuantity = function () {
          var total = 0; var counter = 0
          for (var i = 0; i < $scope.goldPurchaseTransaction.details.length; i++) {
            total = total + $scope.goldPurchaseTransaction.details[i].quantity
            counter++
            if (counter == $scope.goldPurchaseTransaction.details.length) {
              return total
            }
          }
        }

        // Get transaction total
        $scope.getTotalPrice = function () {
          var total = 0; var counter = 0

          for (var i = 0; i < $scope.goldPurchaseTransaction.details.length; i++) {
            console.log('$scope.goldPurchaseTransaction.details[i]', $scope.goldPurchaseTransaction.details[i])

            total = total + $scope.goldPurchaseTransaction.details[i].total
            counter++

            console.log('total', total)

            if (counter == $scope.goldPurchaseTransaction.details.length) {
              return total
            }
          }
        }

        // Open item for edit
        $scope.openItem = function (item, index) {
          $scope.offerItem = angular.copy(item)
          $scope.editItem = true
          $scope.offerIndex = index
          $scope.noMoreOrdersCanAdd = false
        }

        // Add comment into particular photo
        $scope.addCommentToPhoto = function (index) {
          $scope.editComment = index
        }

        // Save photo comment
        $scope.savePhotoComment = function () {
          $scope.editComment = null
        }

        // Function using while uploading images to server
        uploader.onProgressAll = function (progress) {
          if (progress < 100) {
            $rootScope.$broadcast('dialogs.wait.progress', { progress: progress })
          } else {
            $rootScope.$broadcast('dialogs.wait.complete')
          }
        }

        // Function while successfully single image upload to server
        uploader.onSuccessItem = function (item, response, status, headers) {
          // Push every single file in an array to store them later on the transaction
          $scope.allFiles.push({
            src: response.file,
            comment: item.formData.comment
          })
        }

        // Function while successfully upload all the images to server
        uploader.onCompleteAll = function () {
          // All files are uploaded, now we can merge all details and create the transaction
          return $scope.createTransactionAfterImageUpload()
        }

        // Function while getting any error while upload image to server
        uploader.onErrorItem = function (fileItem, response, status, headers) {
          $scope.loading = false
          console.info('onErrorItem', fileItem, response, status, headers)
          $scope.alert = {
            type: 'danger',
            msg: response.message
          }
        }

        // Open customer selection pop-up
        $scope.openSearchCustomerModal = function () {
          $rootScope.checkModule('PACKAGE_CUSTOMER_MANAGEMENT', true, true)
            .then(function () {
              $customer.openSearchCustomerModal()
                .then(function (result) {
                  $scope.customer = result
                  $scope.updateCustomerFromDirective({ customer: result })
                  $scope.checkoutWithoutCustomer = false
                })
                .catch(function (reason) {
                  console.error('REASON', reason)
                })
            })
        }

        $scope.beforeCreateTransactionForCustomerChecking = function () {
          var searchTranslation = $language.translate('SEARCH_CUSTOMER')
          var withoutTranslation = $language.translate('CONTINUE_WITHOUT')

          prompt({
            title: $language.translate('ALERT'),
            message: $language.translate('ARE_YOU_SURE_TO_CONTINUE_WITHOUT_CUSTOMER'),
            buttons: [
              {
                label: searchTranslation,
                primary: true
              },
              {
                label: withoutTranslation,
                cancel: true,
                primary: false
              }
            ]
          })
            .then(function (result) {
              if (result.label === searchTranslation) {
                $scope.openSearchCustomerModal()
              } else if (result.label === withoutTranslation) {
                $scope.checkoutWithoutCustomer = true
                $scope.createTransaction()
              }
              $scope.loading = false
            })
            .catch(function (error) {
              $scope.loading = false
            })
        }

        $scope.makeObjectForShopPurchaseTransaction = function (goldPurchaseTransaction) {
          return $q(function (resolve, reject) {
            var existingDetails = goldPurchaseTransaction.details
            var newDetailsForShopPurchaseTransaction = []

            for (var i = 0; i < existingDetails.length; i++) {
              newDetailsForShopPurchaseTransaction.push({
                name: 'Payment for goldpurchase: ',
                quantity: existingDetails[i].quantity,
                price: existingDetails[i].price,
                isNewProduct: false,
                isRestictForEdit: true,
                priceVat: existingDetails[i].priceVat,
                productId: existingDetails[i].productId ? existingDetails[i].productId : null,
                transaction: goldPurchaseTransaction._id,
                purchasePrice: existingDetails[i].purchasePrice,
                parentTransactionDetailId: goldPurchaseTransaction._id,
                total: existingDetails[i].total
              })
            }

            return resolve({
              details: newDetailsForShopPurchaseTransaction,
              parentTransactionId: goldPurchaseTransaction._id,
              shopId: goldPurchaseTransaction.shopId,
              type: 'shop-purchase',
              customer: goldPurchaseTransaction.customer ? goldPurchaseTransaction.customer : null
            })
          })
        }

        // Create gold purchase transaction. But this is mainly use for upload image if attached and check for customer if not attached
        $scope.createTransaction = function () {
          $scope.loading = true
          $scope.goldPurchaseTransaction.shopId = $rootScope.currentShop._id

          // Check customer is attached or not. If not then we need to open model to warn retailer
          if (!$scope.customer && !$scope.checkoutWithoutCustomer) {
            return $scope.beforeCreateTransactionForCustomerChecking()
          }

          // Check for image is attached by user
          if ($scope.uploader.queue && $scope.uploader.queue.length) {
            // image attached we need to update and create transaction
            $scope.allFiles = []
            uploader.uploadAll()
            dialogs.wait($language.translate('UPLOADING'), $language.translate('PLEASE_WAIT_WHILE_THE_PHOTOS_ARE_UPLOADED'), 0)
          } else {
            // Image not attached we directly need to create transaction
            $scope.createTransactionAfterImageUpload()
          }
        }

        // Create gold purchase transaction with attaching the image
        $scope.createTransactionAfterImageUpload = function () {
          // Create transaction
          $scope.goldPurchaseTransaction.details[0].photos = $scope.allFiles
          $transaction.createGoldPurchaseTransaction($scope.goldPurchaseTransaction)
            .then(function (newCreateTransaction) {
              return $scope.makeObjectForShopPurchaseTransaction(newCreateTransaction.data.result.transaction)
            })
            .then(function (shopPurchaseTransactionObj) {
              $state.go('retailer.till')
            })
            .catch(function (error) {
              $scope.loading = false
              $scope.alert = {
                type: 'danger',
                msg: error.data.message || 'Something went wrong'
              }
            })
        }
      }]
  }
})
