prismanoteApp.directive('recommendationFilterBySupplier', function () {
    return {
        restrict: 'E',
        replace: 'true',
        scope: {
            filter: '=xyz',
        },
        templateUrl: '../views/directives/recommendation-filter-by-supplier.html',
        controller: ['$rootScope', '$scope',
            function ($rootScope, $scope) {

                // console.log('selectSupplier called: ', $scope.filter.selectedSupplierId, $scope.filter.suppliers);
                $scope.selectSupplier = function (item) {
                    return;
                    console.log('selectSupplier called: ', $scope.filter.selectedSupplierId, item);
                    // if ($scope.filter.selectedSupplierId)
                    var selectedSuppliers = $scope.filter.suppliers.filter(function (el) {
                        return el.isSelected;
                    }).map(function (ele) {
                        return ele._id;
                    });
                    $scope.filter.selectedSuppliers = selectedSuppliers;
                    $scope.$emit('recommendation_filter_directive', $scope.filter);
                }
            }]
    }
})
