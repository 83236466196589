prismanoteApp.directive('productFilterByGender', function () {
  var filter = {}
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-gender.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $rootScope.$on('setTheGender', function (event, params) {
        // params will have list of categories
        if (params == 'men_watch' || params == 'men_jewel') {
          $scope.gents = true
          $scope.ladies = false
          $scope.setGender()
        } else if (params == 'ladies_watch' || params == 'ladies_jewel') {
          $scope.ladies = true
          $scope.gents = false
          $scope.setGender()
        } else if (params == 'boys_watch' || params == 'boys_jewel') {
          $scope.boys = true
          $scope.setGender()
        } else if (params == 'girls_watch' || params == 'girls_jewel') {
          $scope.girls = true
          $scope.setGender()
        }
      })

      $rootScope.$on('gender_filter', function (event, params) {
        $scope.gents = params.gents
        $scope.ladies = params.ladies
        $scope.boys = params.boys
        $scope.girls = params.girls
        $scope.setGender()
      })

      $scope.setGender = function (gents, ladies, boys, girls) {
        $scope.gents = gents
        $scope.ladies = ladies
        $scope.boys = boys
        $scope.girls = girls

        filter.gender = {}
        if ($scope.gents) {
          if ($scope.ladies) {
            if ($scope.boys) {
              if ($scope.girls) {
                filter = {}

                // gents, ladies, boys
              } else {
                filter = {
                  gender: [
                    {
                      kids: false,
                      male: true
                    },
                    {
                      male: true,
                      kids: false

                    },
                    {
                      male: true,
                      kids: true

                    }
                  ]
                }
              }
            } else {
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      kids: false
                    },
                    {
                      female: true
                    }
                  ]
                }

                // gents, ladies
              } else {
                filter = {
                  gender: [
                    {
                      kids: false,
                      male: true
                    },
                    {
                      female: true,
                      kids: false
                    }
                  ]
                }
              }
            }
          } else {
            if ($scope.boys) {
              // gents, boys, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      kids: true
                    },
                    {
                      male: true
                    }
                  ]
                }

                // gents, boys
              } else {
                filter = { gender: [{ male: true, kids: false }] }
              }
            } else {
              // gents, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      kids: true,
                      female: true
                    },
                    {
                      kids: false,
                      male: true
                    }
                  ]
                }

                // gents
              } else {
                filter = { gender: [{ male: true, kids: false }] }
              }
            }
          }
        } else {
          if ($scope.ladies) {
            if ($scope.boys) {
              // ladies, boys, girls
              if ($scope.girls) {
                filter = {
                  gender: [
                    {
                      kids: true
                    },
                    {
                      female: true
                    }
                  ]
                }

                // ladies, boys
              } else {
                filter = {
                  gender: [
                    {
                      kids: false,
                      female: true
                    },
                    {
                      kids: true,
                      male: true
                    }
                  ]
                }
              }
            } else {
              // ladies, girls
              if ($scope.girls) {
                filter = { gender: [{ female: true, kids: false }] }

                // ladies
              } else {
                filter = { gender: [{ female: true, kids: false }] }
              }
            }
          } else {
            if ($scope.boys) {
              // boys, girls
              if ($scope.girls) {
                filter = { gender: [{ kids: true, male: true }, { kids: true, female: true }] }

                // boys
              } else {
                filter = { gender: [{ kids: true, male: true }] }
              }
            } else {
              // girls
              if ($scope.girls) {
                filter = { gender: [{ kids: true, female: true }] }

                // nothing selected, default to: gents, ladies, boys, girls.
              } else {
                filter = {}
              }
            }
          }
        }
        delete filter.male
        delete filter.female
        delete filter.kids
        $scope.$emit('gender_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        $scope.gents = false
        $scope.ladies = false
        $scope.boys = false
        $scope.girls = false
      })

      $scope.$on('clear-the-gender-filter', function (event, params) {
        $scope.gents = false
        $scope.ladies = false
        $scope.boys = false
        $scope.girls = false
      })
    }]
  }
})
