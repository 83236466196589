prismanoteApp.directive('recommendationFilterByGender', function () {
    var filter = {};
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-gender.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {

            console.log('recommendationFilterByGender called: ', $scope.filter.gender);

            $scope.genderSelected = function () {
                console.log('genderSelected called: ', $scope.filter.gender);
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})
