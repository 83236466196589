prismanoteApp.directive('productFilterByWaterproofLevel', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-waterproof-level.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      $scope.waterproofLevel = {
        min: 0,
        options: {
          floor: 0,
          ceil: 20,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'floor':
                return value + ' ATM'
              case 'model':
                if (value == 0) {
                  return value + ' ATM'
                } else {
                  return value + '+ ATM'
                }
              default:
                return value + '+ ATM'
            }
          },
          onEnd: function () {
            if ($scope.waterproofLevel.min != 20) {
              filter['watch.waterproofLevel'] = { $gte: $scope.waterproofLevel.min }
            }

            $scope.getProducts({ filter: filter }, { getShopProducts: true, reset: true })

              .catch(function (reason) {
                console.error(reason)
              })
          }
        }
      }
    }]
  }
})
