prismanoteApp.directive('countryList', function () {
  return {
    restrict: 'E',
    scope: {
      ngModel: '=',
      required: '=ngRequired',
      label: '@label',
      ngChange: '@'
    },
    templateUrl: '../views/directives/country-list.html',
    controller: ['$scope', 'countryService', '$rootScope', function ($scope, countryService, $rootScope) {
      $scope.countries = []
      var countries = countryService.getTranslatedCountryList($rootScope.language.toUpperCase())

      if ($rootScope.currentShop && $rootScope.currentShop.address && $rootScope.currentShop.address.country) {
        var currentCountry = $rootScope.currentShop.address.country.toUpperCase()
      } else {
        var currentCountry = $rootScope.language.toUpperCase()
      }

      for (var code in countries) {
        $scope.countries.push({
          code: code,
          name: countries[code],
          order: currentCountry == code ? 0 : 10
        })
      }

      function loadDirective () {
        var index = _.findIndex($scope.countries, { code: $scope.ngModel.toUpperCase() })
        if (index >= 0) {
          $scope.country = $scope.countries[index]
        }
      }

      if ($scope.ngModel) {
        loadDirective()
      }

      $scope.$watch('ngModel', function (newValue, oldValue) {
        if (newValue) {
          loadDirective()
        }
      })

      $scope.updateCountry = function (selected) {
        setTimeout(function () {
          $scope.$apply(function () {
            $scope.country = selected
            $scope.ngModel = selected.code
          })
        }, 50)
      }
    }]
  }
})
