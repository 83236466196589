prismanoteApp.directive('customFilterByClientGroup', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-filter-by-client-group.html',
        scope: {
            filter: '=filter',
        },
        controller: ['$scope', function ($scope) {

            // console.log('DIRECTIVE: clientGroupCollections called: ', $scope.filter.clientGroupCollections);

            $scope.selectPrivateLableCollection = function () {
                // console.log('selectPrivateLableCollection called: ', $scope.filter.clientGroupCollections);
                var selectedPrivateLableCollections = $scope.filter.clientGroupCollections.filter(function (el) {
                    return el.isSelected;
                }).map(function (ele) {
                    return ele._id;
                });
                $scope.filter.selectedclientGroupCollections = selectedPrivateLableCollections;
                console.log('HI: $scope.filter.selectedclientGroupCollections: ', $scope.filter.selectedclientGroupCollections);
            }
        }]
    }
})