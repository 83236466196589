prismanoteApp.directive('productFilterByIndication', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-indication.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.CHRONO_MULTI = false
      $scope.ANALOG = false
      $scope.DIGITAL = false
      $scope.ANALOG_DIGITAL = false

      shopFiltersAvailbeOrNot = function () {
        if ($rootScope.shopFilters.watchIndication.indexOf('CHRONO_MULTI') != -1) $scope.CHRONO_MULTI = true

        if ($rootScope.shopFilters.watchIndication.indexOf('ANALOG') != -1) $scope.ANALOG = true

        if ($rootScope.shopFilters.watchIndication.indexOf('DIGITAL') != -1)$scope.DIGITAL = true

        if ($rootScope.shopFilters.watchIndication.indexOf('ANALOG_DIGITAL') != -1) $scope.ANALOG_DIGITAL = true
      }

      shopFiltersAvailbeOrNot()

      $scope.setIndication = function () {
        var filter = { 'watch.indication': {} }
        var indications = []

        if ($scope.indication) {
          if ($scope.indication.chronoMulti) {
            indications.push('CHRONO_MULTI')
          } else if (indications.indexOf('CHRONO_MULTI') != -1) {
            indications.splice(indications.indexOf('CHRONO_MULTI'))
          }

          if ($scope.indication.analog) {
            indications.push('ANALOG')
          } else if (indications.indexOf('ANALOG') != -1) {
            indications.splice(indications.indexOf('ANALOG'))
          }

          if ($scope.indication.digital) {
            indications.push('DIGITAL')
          } else if (indications.indexOf('DIGITAL') != -1) {
            indications.splice(indications.indexOf('DIGITAL'))
          }

          if ($scope.indication.analogDigital) {
            indications.push('ANALOG_DIGITAL')
          } else if (indications.indexOf('ANALOG_DIGITAL') != -1) {
            indications.splice(indications.indexOf('ANALOG_DIGITAL'))
          }
        }

        var filter

        if (indications.length == 0) {
          indications = ['CHRONO_MULTI', 'ANALOG', 'DIGITAL', 'ANALOG_DIGITAL']
        }

        filter['watch.indication'] = { $in: indications }
        $scope.$emit('indication_filter_directive', { filter: filter })
      }
    }]
  }
})
