prismanoteApp.directive('recommendationFilterByStrapWidth', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-strap-width.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', function ($scope) {

            // console.log('filter called: ', $scope.filter);

            console.log('$scope.filter.strapWidths called: ', $scope.filter.strapWidths);

            $scope.selectStrapwidth = function () {
                console.log('selectStrapwidth called: ', $scope.filter.strapWidths);
                $scope.filter.category.strap = true;
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})
