prismanoteApp.directive('recommendationSort', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-sort.html',
        scope: {
            filter: '=xyz'
        },
        controller: ['$scope', function ($scope) {

            $scope.setLimit = function () {
                console.log('setLimit called: ', $scope.filter.limit);
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    };
});
