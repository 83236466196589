prismanoteApp.directive('productFilterByWristPerimeter', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-wrist-perimeter.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      $scope.wristPerimeter = {
        min: 150,
        max: 250,
        options: {
          floor: 150,
          ceil: 250,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'ceil':
                return value + ' mm +'
              case 'model':
                return value + ' mm'
              case 'high':
                if (value == 250) {
                  return value + ' mm +'
                } else {
                  return value + ' mm'
                }
              default:
                return value + ' mm'
            }
          },
          onEnd: function () {
            if ($scope.wristPerimeter.min != 150) {
              filter['watch.strap.wristPerimeter'] = { $gte: $scope.wristPerimeter.min }
            }

            if ($scope.wristPerimeter.max < 250) {
              filter['watch.strap.wristPerimeter'] = { $lte: $scope.wristPerimeter.max }
            }

            if ($scope.wristPerimeter.min == 150 && $scope.wristPerimeter.max == 250) {
              filter['watch.strap.wristPerimeter'] = undefined
            } else if ($scope.wristPerimeter.min == 150) {
              filter['watch.strap.wristPerimeter'].$gte = undefined
            } else if ($scope.wristPerimeter.max == 250) {
              filter['watch.strap.wristPerimeter'].$lte = undefined
            }

            $scope.getProducts({ filter: filter }, { getShopProducts: true, reset: true })

              .catch(function (reason) {
                console.error(reason)
              })
          }
        }
      }
    }]
  }
})
