prismanoteApp.directive('productFilterByColorNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-color-new.html',
    scope: false,
    controller: ['$scope', '$productConfig', '$rootScope', function ($scope, $productConfig, $rootScope) {
      var filter = { 'watch.dial.color': { $in: [] }, 'watch.strap.color': { $in: [] }, 'watch.case.color': { $in: [] } }

      // $scope.caseColors = $scope.jewelColors = Object.assign($scope.dialColors, $scope.strapColors);

      Initialization()

      function Initialization () {
        $scope.dialColors = JSON.parse(JSON.stringify($productConfig.getDialColors()))
        $scope.strapColors = JSON.parse(JSON.stringify($productConfig.getStarpColors()))
        $scope.caseColors = JSON.parse(JSON.stringify($productConfig.getDialColors()))
      }

      $scope.setDialColor = function () {
        filter['watch.dial.color'].$in = []

        var dialColorsLength = $scope.dialColors.length
        for (var i = 0; i < dialColorsLength; i++) {
          if ($scope.dialColors[i].selected) {
            filter['watch.dial.color'].$in.push($scope.dialColors[i].key)
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.dialColors[i].key, filter: 'Dial Color' })
            if (index < 0) $rootScope.selectdFilter.push({ name: $scope.dialColors[i].key, filter: 'Dial Color' })
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.dialColors[i].key, filter: 'Dial Color' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }
        }

        $scope.$emit('color_filter_directive', { filter: filter })
      }

      $scope.setStrapColor = function () {
        filter['watch.strap.color'].$in = []
        var strapColorsLength = $scope.strapColors.length
        for (var i = 0; i < strapColorsLength; i++) {
          if ($scope.strapColors[i].selected) {
            filter['watch.strap.color'].$in.push($scope.strapColors[i].key)
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.strapColors[i].key, filter: 'Strap Color' })
            if (index < 0) $rootScope.selectdFilter.push({ name: $scope.strapColors[i].key, filter: 'Strap Color' })
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.strapColors[i].key, filter: 'Strap Color' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }
        }

        $scope.$emit('color_filter_directive', { filter: filter })
      }

      $scope.setCaseColor = function () {
        filter['watch.case.color'].$in = []
        var caseColorsLength = $scope.caseColors.length
        for (var i = 0; i < caseColorsLength; i++) {
          if ($scope.caseColors[i].selected) {
            filter['watch.case.color'].$in.push($scope.caseColors[i].key)
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.caseColors[i].key, filter: 'Case / Jewel Color' })
            if (index < 0) $rootScope.selectdFilter.push({ name: $scope.caseColors[i].key, filter: 'Case / Jewel Color' })
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: $scope.caseColors[i].key, filter: 'Case / Jewel Color' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }
        }

        $scope.$emit('color_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        Initialization()
      })

      $scope.$on('clear-the-color-filter', function (event, params) {
        Initialization()
      })
    }]
  }
})
