prismanoteApp.directive('productFilterByWaterproofLevelNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-waterproof-level-new.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      $scope.waterproofLevel = {
        min: 0,
        max: 1000,
        options: {
          floor: 0,
          ceil: 1000,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'floor':
                return value + ' ATM'
              case 'model':
                if (value == 0) {
                  return value + ' ATM'
                } else {
                  return value + '+ ATM'
                }
              default:
                return value + '+ ATM'
            }
          },
          onEnd: function () {
            console.log('$scope.waterproofLevel', $scope.waterproofLevel)
            if ($scope.waterproofLevel.min == 0 && $scope.waterproofLevel.max == 0) {
              filter['watch.waterproofLevel'] = undefined
            } else if ($scope.waterproofLevel.min > -1 && $scope.waterproofLevel.max < 3000) {
              filter['watch.waterproofLevel'] = { $gte: $scope.waterproofLevel.min, $lte: $scope.waterproofLevel.max }
            } else if ($scope.waterproofLevel.min == 0 && $scope.waterproofLevel.max > 3000) {
              filter['watch.waterproofLevel'] = { $gte: $scope.waterproofLevel.min, $lte: $scope.waterproofLevel.max }
            } else if ($scope.waterproofLevel.min > 0 && $scope.waterproofLevel.max == 3000) {
              filter['watch.waterproofLevel'] = { $gte: $scope.waterproofLevel.min, $lte: $scope.waterproofLevel.max }
            } else if ($scope.waterproofLevel.min == 0 && $scope.waterproofLevel.max == 3000) {
              filter['watch.waterproofLevel'] = { $gte: $scope.waterproofLevel.min, $lte: $scope.waterproofLevel.max }
            } else {
              filter['watch.waterproofLevel'] = undefined
            }

            console.log('Filter water : ', filter)
            $scope.$emit('waterproofLevel_filter_directive', { filter: filter })
          }
        }
      }
    }]
  }
})
