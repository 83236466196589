prismanoteApp.directive('productFilterBySupplierNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-filter-by-supplier-new.html',
    controller: ['$rootScope', '$scope', '$state', '$retailerFilters', '$shop',
      function ($rootScope, $scope, $state, $retailerFilters, $shop) {
        $scope.customSupplierFilter = []
        $scope.filterSuppliers = []
        $scope.selectedSuppliers = []
        $scope.status

        function getCurrentSelectedSupplier () {
          $retailerFilters.getSupplierFilter()
            .then(function (supplierFilter) {
              supplierFilter = JSON.parse(supplierFilter)
              if (supplierFilter && supplierFilter.supplier.$in && supplierFilter.supplier.$in.length) {
                var localStorageSupplierLength = supplierFilter.supplier.$in.length
                $scope.filterSuppliers.map(function (element) {
                  for (var i = 1; i < localStorageSupplierLength; i++) {
                    if (element._id == supplierFilter.supplier.$in[i]) {
                      element.status = true
                      $scope.selectedSuppliers.push(element)
                    }
                  }
                })

                $scope.status = 'localStorage'
                $scope.setSupplier(supplierFilter.supplier.$in[0], true)
              } else {
                $scope.status = 'localStorage'
                $scope.setSupplier(null, null)
              }
            })
            .catch(function (error) {
              console.error(error)
            })
        }

        $scope.$on('supplier_filter_remove', function (event, sort) {
          var remove = sort.removeFilter
          var index = _.findIndex($scope.filterSuppliers, { name: remove.name })
          $scope.filterSuppliers[index].status = false
          $scope.setSupplier($scope.filterSuppliers[index]._id, false)
        })

        $scope.getMySupplierClass = function (brandId) {
          // return $.inArray(brandId,$scope.filterChecker.brandsInProds)?'' : 'bg-danger';
          return ''
        }

        $scope.brandExistInProducts = function () {
          return 'bg-success'
        }
        /* Reload products if the brand selection has changed. */
        $scope.selectedSuppliers = []

        $scope.setSupplier = function (selectedSuppplerId, status) {
          var filter = { supplier: { $in: [] } }
          // $scope.selectedSuppliers = [];
          if (selectedSuppplerId) {
            $scope.filterSuppliers.map(function (element) {
              if (((element._id).toString() == (selectedSuppplerId).toString()) && status) {
                element.status = true
                $scope.selectedSuppliers.push(element)
              } else if (((element._id).toString() == selectedSuppplerId) && status) {
                element.status = true
                $scope.selectedSuppliers.push(element)
              } else if (((element._id).toString() == (selectedSuppplerId).toString()) && !status) {
                element.status = false
                var index = $scope.selectedSuppliers.indexOf(element)
                if (index > -1) $scope.selectedSuppliers.splice(index, 1)
              } else if (((element._id).toString() == selectedSuppplerId) && status) {
                element.status = false
                var index = $scope.selectedSuppliers.indexOf(element)
                if (index > -1) $scope.selectedSuppliers.splice(index, 1)
              }
            })
          }

          /* Add selected brands to filter. If no brands are selected, add all brands to the filter, but leave selected brands empty for next time this function is called (when a brand is selected again). */
          var tempLength = $rootScope.selectdFilter.length
          for (var i = 0; i < tempLength; i++) {
            if ($rootScope.selectdFilter[i].filter == 'Supplier') {
              $rootScope.selectdFilter.splice(i, 1)
              tempLength--
              i--
            }
          }

          if ($scope.selectedSuppliers.length == 0) {
            filter.supplier.$in = []
          } else {
            filter.supplier.$in = $scope.selectedSuppliers.map(function (supplier) { return supplier._id })
            for (var i = 0; i < filter.supplier.$in.length; i++) {
              var indexInSupplier = _.findIndex($scope.selectedSuppliers, function (o) {
                if (o._id) {
                  if (o._id === filter.supplier.$in[i]) return o
                }
              })

              if (indexInSupplier > -1 && $scope.selectedSuppliers[indexInSupplier].status) {
                if (indexInSupplier > -1) {
                  var temp = { name: $scope.selectedSuppliers[indexInSupplier].name, filter: 'Supplier' }
                  var index = _.findIndex($rootScope.selectdFilter, temp)
                  if (index < 0) $rootScope.selectdFilter.push(temp)
                }
              }
            }
          }

          if ($scope.status && $scope.status == 'localStorage') {
            filter.status = 'localStorage'
            delete $scope.status
          } else {
            delete filter.status
          }

          $retailerFilters.setSupplierFilter(filter)
          // console.log('Ram Ravan', filter)
          $scope.$emit('supplier_filter_directive', { filter: filter })
        }

        $scope.setFilterSuppliers = function (shop) {
          if ($rootScope && $rootScope.currentShop) {
            /* Get the matching selectable filter brands from the db (only include brands with actual products). */
            // $api.get("get-all-shop-companies/" + $rootScope.currentShop.nameSlug)
            $shop.getShopsSuppliersAssortment($rootScope.currentShop.nameSlug)
              .then(function (response) {
                $scope.filterSuppliers = response.companies
                getCurrentSelectedSupplier()
              })
              .catch(function (reason) {
                console.error(reason)
              })
          } else if ($rootScope && $rootScope.currentCompany) {
            // console.log("currently We are not stroing the supplier in product and alos in companies ");
            // console.log("So we can not the getting the particular company wise supplier.")
            // console.log("So have some need to talk with Jolmer.")
          }
        }

        /* Find out which brands to include in the filter (in the retailer portal). */

        if ($state.includes('retailer')) {
          // $retailer.getShop()
          // 	.then(function (shop) {
          $scope.setFilterSuppliers()
          // 	})
          // 	.catch(function (reason) {
          // 		console.error(reason);
          // 	});

          /* Find out which brands to include in the filter (in the shop/proshop). */
        }
        // else if ($state.includes('shop') || $state.includes('proshop')) {
        // 	//get the shop brands to filter brands which are not on the shop
        // 	var nameSlug = $stateParams.nameSlug;
        // 	$api.get('shops/' + nameSlug)
        // 		.then(function (response) {
        // 			$scope.setFilterSuppliers(response.data.shop);
        // 		})
        // 		.catch(function (reason) {
        // 			$scope.setFilterSuppliers();
        // 		})

        // 	/* Find out which brands to include in the filter (in the PrismaNote general products overview or other places where this filter is used). */
        // }
        else {

          // $scope.setFilterSuppliers();

        }
      }]
  }
})
