prismanoteApp.directive('productAddAdminPortal', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-add-admin-portal.html',
    controller: ['$scope', '$api', function ($scope, $api) {
      $scope.findProducts = function (value) {
        $scope.hits = []
        if (value != '') {
          $api.get('products', { limit: 6, offset: 0, short: true, filter: { _id: undefined, $text: { $search: value }, isVerified: true } }, { reset: true })

            .then(function (response) {
              $scope.hits = response.data.products
            })

            .catch(function (reason) {
              console.error(reason)
            })

          $scope.setLimit()
        }
      }
      $scope.searchProducts = function (value) {
        console.log('SEARCH PROD ADMIN ', value)
        $scope.hits = []
        if (value != '') {
          // Make a request for find search product listing
          $api.get('seach-product-in-admin/' + value)
            .then(function (response) {
              $scope.hits = response.data.products
            })
            .catch(function (reason) {
              console.error(reason)
            })

          $scope.setLimit()
        }
      }
    }]
  }
})
