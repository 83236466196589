prismanoteApp.directive('productSortNew', function () {
	return {
		restrict: 'E',
		replace: 'true',
		templateUrl: '../views/directives/product-sort-new.html',
		scope: false,
		controller: ['$scope', function ($scope) {

			$scope.default = {articleNumber:'asc'};
			// TODO fix price asc/desc in retailer portal
			$scope.sortProducts = function () {
				console.log('HELLLLLLO SORTTT PROUDCTS');
				if ($scope.sortBy != null && typeof $scope.sortBy === 'object') {
					var sortBy = angular.copy($scope.sortBy);
					console.log('SORT LOG: ', sortBy, $scope.sortBy);
					$scope.$emit('sort_filter_directive', { sort: sortBy });
				}
			}

			$scope.setLimit = function (limit) {
				console.log('SET LIMIT: ', limit);
				$scope.$emit('limit_filter_directive', { limit: limit });
			}

			$scope.$on('clear-the-filter', function (event, params) {
				delete sortBy;
				$scope.sortBy = $scope.default;
				$scope.limit = 24;
			});

			$scope.$on('clear-the-sort-filter', function (event, params) {
				delete sortBy;
				$scope.sortBy = $scope.default;
				$scope.limit = 24;
			});
		}]
	};
});
