prismanoteApp.directive('productFilterByCaseShape', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-shape.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setCaseShape = function () {
        var filter = { 'watch.case.shape': {} }
        var caseShapes = []

        if ($scope.caseShape) {
          if ($scope.caseShape.rectangle) {
            caseShapes.push('RECTANGLE')
          } else if (caseShapes.includes('RECTANGLE')) {
            caseShapes.splice(caseShapes.indexOf('RECTANGLE'))
          }

          if ($scope.caseShape.round) {
            caseShapes.push('ROUND')
          } else if (caseShapes.includes('ROUND')) {
            caseShapes.splice(caseShapes.indexOf('ROUND'))
          }

          if ($scope.caseShape.oval) {
            caseShapes.push('OVAL')
          } else if (caseShapes.includes('OVAL')) {
            caseShapes.splice(caseShapes.indexOf('OVAL'))
          }

          if ($scope.caseShape.square) {
            caseShapes.push('SQUARE')
          } else if (caseShapes.includes('SQUARE')) {
            caseShapes.splice(caseShapes.indexOf('SQUARE'))
          }

          if ($scope.caseShape.other) {
            caseShapes.push('OTHER_SHAPE')
          } else if (caseShapes.includes('OTHER_SHAPE')) {
            caseShapes.splice(caseShapes.indexOf('OTHER_SHAPE'))
          }

          if (caseShapes.length == 0) {
            caseShapes = ['RECTANGLE', 'ROUND', 'OVAL', 'SQUARE', 'OTHER_SHAPE']
          }

          filter['watch.case.shape'] = { $in: caseShapes }

          $scope.getProducts({ filter: filter }, { reset: true })

            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }]
  }
})
