prismanoteApp.directive('productFilterByCaseShapeNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-shape-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.setCaseShape = function () {
        var filter = { 'watch.case.shape': {} }
        var caseShapes = []

        if ($scope.caseShape) {
          if ($scope.caseShape.rectangle) {
            caseShapes.push('RECTANGLE')
            if (_.findIndex($rootScope.selectdFilter, { name: 'RECTANGLE', filter: 'Case Shape' }) < 0) $rootScope.selectdFilter.push({ name: 'RECTANGLE', filter: 'Case Shape' })
          } else if (caseShapes.includes('RECTANGLE')) {
            caseShapes.splice(caseShapes.indexOf('RECTANGLE'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'RECTANGLE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'RECTANGLE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.caseShape.round) {
            caseShapes.push('ROUND')
            if (_.findIndex($rootScope.selectdFilter, { name: 'ROUND', filter: 'Case Shape' }) < 0) $rootScope.selectdFilter.push({ name: 'ROUND', filter: 'Case Shape' })
          } else if (caseShapes.includes('ROUND')) {
            caseShapes.splice(caseShapes.indexOf('ROUND'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ROUND', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ROUND', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.caseShape.oval) {
            caseShapes.push('OVAL')
            if (_.findIndex($rootScope.selectdFilter, { name: 'OVAL', filter: 'Case Shape' }) < 0) $rootScope.selectdFilter.push({ name: 'OVAL', filter: 'Case Shape' })
          } else if (caseShapes.includes('OVAL')) {
            caseShapes.splice(caseShapes.indexOf('OVAL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'OVAL', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'OVAL', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.caseShape.square) {
            caseShapes.push('SQUARE')
            if (_.findIndex($rootScope.selectdFilter, { name: 'SQUARE', filter: 'Case Shape' }) < 0) $rootScope.selectdFilter.push({ name: 'SQUARE', filter: 'Case Shape' })
          } else if (caseShapes.includes('SQUARE')) {
            caseShapes.splice(caseShapes.indexOf('SQUARE'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SQUARE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SQUARE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.caseShape.other) {
            caseShapes.push('OTHER_SHAPE')
            if (_.findIndex($rootScope.selectdFilter, { name: 'OTHER_SHAPE', filter: 'Case Shape' }) < 0) $rootScope.selectdFilter.push({ name: 'OTHER_SHAPE', filter: 'Case Shape' })
          } else if (caseShapes.includes('OTHER_SHAPE')) {
            caseShapes.splice(caseShapes.indexOf('OTHER_SHAPE'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'OTHER_SHAPE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'OTHER_SHAPE', filter: 'Case Shape' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if (caseShapes.length == 0) caseShapes = []

          filter['watch.case.shape'] = { $in: caseShapes }

          $scope.$emit('case_shape_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
