prismanoteApp.directive('ngEnter', function () {
  return function (scope, elem, attrs) {
    elem.bind('keydown keypress', function (event) {
      // 13 represents enter button
      if (event.which === 13) {
        scope.$apply(function () {
          scope.$eval(attrs.ngEnter)
        })

        event.preventDefault()
      }
    })
  }
})
