prismanoteApp.directive('productFilterByShopPropertiesNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-shop-properties-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      // console.log('product handling guy This is new Directive for the product filter by shop property new')

      function getCurrentProductFiler () {
        $retailerFilters.getProductFilter()
          .then(function (productFilter) {
            // console.log('productFilter in directive', productFilter)
            $scope.shopProperties = {}
            productFilter = JSON.parse(productFilter)
            if (productFilter && productFilter.isBestseller) $scope.shopProperties.isBestseller = true
            if (productFilter && productFilter.isFeatured) $scope.shopProperties.isFeatured = true
            if (productFilter && productFilter.discount) $scope.shopProperties.discount = true
            if (productFilter && productFilter.inStock) $scope.shopProperties.inStock = true
            if (productFilter && productFilter.show) $scope.shopProperties.show = true
            if (productFilter && productFilter.notOnWebShop) $scope.shopProperties.notOnWebShop = true
            if (productFilter && productFilter.showProductWithImage) $scope.shopProperties.showProductWithImage = true
            // console.log('product handling guy Check Here Please')
            $scope.setShopProperties('localStorage')
          })
          .catch(function (error) {
            console.error('Error', error)
          })
      }

      getCurrentProductFiler()

      $scope.$on('shop_filter_remove', function (event, sort) {
        // console.log('$sort =======>', sort)
        var remove = sort.removeFilter

        if (remove.name === 'Discount') {
          $scope.shopProperties.discount = false
          $scope.setShopProperties()
        } else if (remove.name == 'Featured') {
          $scope.shopProperties.isFeatured = false
          $scope.setShopProperties()
        } else if (remove.name == 'In Stock') {
          $scope.shopProperties.inStock = false
          $scope.setShopProperties()
        } else if (remove.name === 'Bestseller') {
          $scope.shopProperties.isBestseller = false
          $scope.setShopProperties()
        } else if (remove.name === 'For Sale') {
          $scope.shopProperties.show = false
          $scope.setShopProperties()
        } else if (remove.name === 'Not On Webshop') {
          $scope.shopProperties.notOnWebShop = false
          $scope.setShopProperties()
        } else if (remove.name === 'Show with Image') {
          $scope.shopProperties.showProductWithImage = false
          $scope.setShopProperties()
        }
      })

      $scope.shopProperties = {
        inStock: false,
        discount: false,
        isBestseller: false,
        show: false,
        notOnWebShop: false,
        isFeatured: false,
        showProductWithImage: false
      }

      $rootScope.$on('setTheStock', function (event, params) {
        $scope.shopProperties = {
          inStock: true,
          discount: false,
          isBestseller: false,
          show: true,
          notOnWebShop: false,
          isFeatured: false,
          showProductWithImage: false
        }
        $scope.setShopProperties('localStorage')
      })

      $rootScope.$on('removeShow', function (event, params) {
        $scope.shopProperties.show = false
        $scope.setShopProperties('localStorage')
      })

      $scope.setShopProperties = function (status) {
        var filter = {}

        if ($scope.shopProperties) {
          if ($scope.shopProperties.discount) {
            filter.discount = true
            if (_.findIndex($rootScope.selectdFilter, { name: 'Discount', filter: 'shop' }) < 0) $rootScope.selectdFilter.push({ name: 'Discount', filter: 'shop' })
          } else {
            filter.discount = undefined
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Discount', filter: 'shop' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.shopProperties.inStock) {
            filter.inStock = true
            if (_.findIndex($rootScope.selectdFilter, { name: 'In Stock', filter: 'shop' }) < 0) $rootScope.selectdFilter.push({ name: 'In Stock', filter: 'shop' })
          } else {
            filter.inStock = undefined
            var index = _.findIndex($rootScope.selectdFilter, { name: 'In Stock', filter: 'shop' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.shopProperties.isBestseller) {
            filter.isBestseller = true
            if (_.findIndex($rootScope.selectdFilter, { name: 'Bestseller', filter: 'shop' }) < 0) $rootScope.selectdFilter.push({ name: 'Bestseller', filter: 'shop' })
          } else {
            filter.isBestseller = false
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Bestseller', filter: 'shop' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.shopProperties.show) {
            filter.show = true
            if (_.findIndex($rootScope.selectdFilter, { name: 'For Sale', filter: 'shop' }) < 0) $rootScope.selectdFilter.push({ name: 'For Sale', filter: 'shop' })
          } else {
            filter.show = false
            var index = _.findIndex($rootScope.selectdFilter, { name: 'For Sale', filter: 'shop' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.shopProperties.notOnWebShop) {
            filter.notOnWebShop = true
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Not On Webshop', filter: 'shop' })
            if (index < 0) {
              $rootScope.selectdFilter.push({ name: 'Not On Webshop', filter: 'shop' })
            }
          } else {
            filter.notOnWebShop = false
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Not On Webshop', filter: 'shop' })
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.shopProperties.showProductWithImage) {
            filter.showProductWithImage = true
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Show with Image', filter: 'shop' })
            if (index < 0) {
              $rootScope.selectdFilter.push({ name: 'Show with Image', filter: 'shop' })
            }
          } else {
            filter.showProductWithImage = false
            var index = _.findIndex($rootScope.selectdFilter, { name: 'Show with Image', filter: 'shop' })
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.shopProperties.isFeatured) {
            filter.isFeatured = true
            if (_.findIndex($rootScope.selectdFilter, { name: 'FEATURED', filter: 'shop' }) < 0) $rootScope.selectdFilter.push({ name: 'FEATURED', filter: 'shop' })
          } else {
            filter.isFeatured = undefined
            var index = _.findIndex($rootScope.selectdFilter, { name: 'FEATURED', filter: 'shop' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          $retailerFilters.setProductFilter(filter)
          if (status && status == 'localStorage') filter.status = status

          // console.log('Hui hui', status)
          $scope.$emit('product_filter_directive', { filter: filter })
        } else {
          $retailerFilters.setProductFilter(filter)
          if (status && status == 'localStorage') filter.status = status
          $scope.$emit('product_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
