prismanoteApp.directive('goldPurchaseTransactionDetail', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/gold-purchase-transaction-detail.html',
    scope: {
      transaction: '=transaction'
    },
    controller: [
      '$scope', '$rootScope', '$transaction', '$state', '$customer', 'prompt', '$language', '$api', '$pdf', '$printer', '$employee',
      function ($scope, $rootScope, $transaction, $state, $customer, prompt, $language, $api, $pdf, $printer, $employee) {
        $scope.alert = {}
        $scope.totalTax = 0
        $scope.serviceStatus = 0

        $scope.getServiceStatus = function (status) {
          if (status === 'quotation') {
            $scope.serviceStatus = 0
          }else if (status === 'delivered') {
            $scope.serviceStatus = 1
          }else if (status === 'cancelled') {
            $scope.serviceStatus = 2
          }
        }

        $scope.back = function () {
          window.history.back()
        }

        $scope.openCustomer = function () {
          $state.go('retailer.customer', {
            id: $scope.transaction.customer._id
          })
        }

        $scope.openChangeCustomerModal = function () {
          $customer.openSearchCustomerModal(false)
            .then(function (result) {
              $scope.transaction.customer = result
              if (!$scope.transaction.log) {
                $scope.transaction.log = []
              }
              $scope.transaction.log.push({
                date: new Date(),
                user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
                text: 'Customer Changed',
                isFor: 'retailerPortal',
                eType: 'retailer'
              })
              $scope.updateTransaction()
            })
            .catch(function (reason) {
              console.error('REASON', reason)
            })
        }

        $scope.openTransaction = function (id, type) {
          $transaction.openTransaction(id, type, $scope.transaction.oldTill)
        }

        $scope.getTotalPrice = function () {
          var total = 0; var counter = 0; var vat = 0
          for (var i = 0; i < $scope.transaction.details.length; i++) {
            vat += (($scope.transaction.details[i].price * ($scope.transaction.details[i].priceVat / 100)) * $scope.transaction.details[i].quantity)
            total += $scope.transaction.details[i].total
            counter++
            if (counter === $scope.transaction.details.length) {
              $scope.totalTax = vat
              return total
            }
          }
        }

        $scope.updateStatus = function (status) {
          prompt({
            title: $language.translate('ALERT'),
            message: $language.translate('MAKE_SURE_YOU_CAN_NOT_CHANGE_ANYTHING_IN_THIS_TRANSACTION_ONCE_MARK_AS_CANCELLED_OR_COMPLETED_EVEN_CUSTUMER_OR_YOU_CAN_NOT_USE_THIS_GIFTCARD_NUMBER_AGAIN')
          })
            .then(function (data) {
              if (data && !data.cancel) {
                return $transaction.updateGiftCardStatus(status, $scope.transaction._id, $rootScope.currentShop._id)
              } else {

              }
            })
            .then(function (updatedResponse) {
              if (updatedResponse) {
                $scope.alert = {
                  type: 'success',
                  msg: updatedResponse.data.message
                }
                $scope.transaction.status = status
              }
            })
            .catch(function (error) {
              $scope.alert = {
                type: 'danger',
                msg: error.data.message
              }
            })
        }

        $scope.printStorePurchaseReceipt = function () {
          $scope.makeReceipt('document')
        }

        $scope.makeReceipt = function (type, payment) {
          if (type === 'string') {
            $scope.sendingMail = true
            $scope.mailSent = false
          }

          $pdf.downloadPdf('generate-shop-receipt', {
            transactionId: $scope.transaction._id,
            email: $scope.transaction.customer.email,
            shopId: $rootScope.currentShop._id,
            payMethods: $scope.payMethods,
            typeDocument: type,
            deviceId: $rootScope.currentDevice._id
          }, $scope.transaction.number + '.pdf',
          type === 'document' ? 'arraybuffer' : 'string')
            .then(function (data) {
              if (type !== 'document') {
                $scope.mailAlert = {
                  type: 'success',
                  msg: $language.translate('MAIL_SEND')
                }
              }
            })
            .catch(function (reason) {
              console.error(reason)
              $scope.alert = {
                type: 'danger',
                msg: reason
              }
            })
        }

        $scope.openUploadModal = function () {
          $transaction.openUploadFilesModal($scope.transaction.details[0], true)
            .then(function (files) {
              $scope.transaction.details[0].photos = $scope.transaction.details[0].photos.concat(files)
              $transaction.updateTransactionDetailItem($scope.transaction._id, $scope.transaction.details[0], $rootScope.currentShop._id)
                .then(function (response) {
                  toastr.success($language.translate('TRANSACTION_UPDATED'))
                })
                .catch(function (reason){
                  toastr.error(reason)
                })
            })
            .catch(function (reason){
              console.error('error in uploadFileModal', reason)
            })
        }

        $scope.printThermal = function () {
          $printer.checkEnabled($rootScope.currentShop)
            .then(function () {
              $printer.printShopReceipt({
                shop: $rootScope.currentShop,
                transaction: $scope.transaction
              })
                .then(function (result) {
                })
                .catch(function (reason) {
                  console.error('Something went wrong in $printer', reason)
                  dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
                })
            })
            .catch(function (reason) {
              console.info(reason)
              dialogs.notify(undefined, $language.translate('PRINTER_IS_NOT_ENABLED'), { size: 'sm' })
            })
        }

        $scope.changedDate = function (type) {
          if (type === 'intakeDate') {
            $scope.intakeDate = !$scope.intakeDate
            $scope.intakeDateChanged = true
          }

          if (type === 'repairDate') {
            $scope.repairDate = !$scope.repairDate
            $scope.repairDateChanged = true
          }
        }

        $scope.getTransaction = function () {
          $transaction.getTransaction($scope.transaction._id, true, false, $rootScope.currentShop._id)
            .then(function (transaction) {
              $scope.transaction = transaction
              $scope.transaction.dateCreated = new Date($scope.transaction.dateCreated)
              $scope.intakeDateChanged = false
              $scope.repairDateChanged = false
              $scope.getServiceStatus()
            })
            .catch(function () {

            })
        }

        $scope.updateTransaction = function () {
          if ((!$scope.transaction.createdBy || !$scope.transaction.createdBy.name) && $scope.isRemovedAssitedBy) return
          $scope.loading = true
          if (!$scope.transaction.log) {
            $scope.transaction.log = []
          }
          if ($scope.intakeDateChanged) {
            var log = {
              date: new Date(),
              user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
              text: 'Intake date changed',
              isFor: 'retailerPortal',
              eType: 'retailer'
            }
            $scope.transaction.log.push(log)
          }

          if ($scope.repairDateChanged) {
            var log = {
              date: new Date(),
              user: ($rootScope.currentEmployee ? $rootScope.currentEmployee._id : $rootScope.user._id),
              text: 'Repair date changed',
              isFor: 'retailerPortal',
              eType: 'retailer'
            }
            $scope.transaction.log.push(log)
          }

          $scope.transaction.shopId = { _id: $scope.transaction.shopId._id }
          $transaction.updateGoldPurchaseTransaction($scope.transaction._id, $scope.transaction)
            .then(function (result) {
              $scope.loading = false
              $scope.getTransaction()
            })
            .catch(function (reason) {
              $scope.loading = false
              dialogs.error($language.translate('ERROR'), $language.translate('AN_ERROR_OCCURED') + ': ' + reason, { size: 'sm' })
            })
        }

        $scope.removeBrand = function () {
          $scope.transaction.brand = null
        }

        $scope.removeAssitedBy = function () {
          $scope.transaction.createdBy.name = null
          $scope.isRemovedAssitedBy = true
        }

        $scope.selectedBrand = function (selected) {
          if (selected) {
            if (selected.originalObject) {
              selected = selected.originalObject
            }

            $scope.transaction.brand = {
              name: selected[$rootScope.language].name,
              _id: selected._id
            }
          }

          $scope.updateTransaction()
        }

        $scope.getBrands = function () {
          $api.get('brands')
            .then(function (response) {
              $scope.brands = response.data.brands
              $scope.brands.forEach(function (brand) {
                brand.name = brand[$rootScope.language].name
              })
            })
            .catch(function (reason) {
              console.error('Error while getting brands', reason)
            })
        }

        $scope.getEmployees = function () {
          $employee.getEmployees().then(function (response) {
            $scope.employees = response
          }).catch(function (error) {
          })
        }

        $scope.userSelected = function (selected) {
          $scope.isRemovedAssitedBy = false
          $scope.transaction.createdBy = {
            _id: selected.originalObject._id,
            name: selected.originalObject.firstName + ' ' + (selected.originalObject.lastNamePrefix ? selected.originalObject.lastNamePrefix + ' ' : '') + selected.originalObject.lastName
          }
          $scope.updateTransaction()
        }

        $scope.searchUsers = function (str) {
          var matches = []
          $scope.employees.forEach(function (item) {
            if ((item.firstName && item.firstName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.lastName && item.lastName.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.lastNamePrefix && item.lastNamePrefix.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.username && item.username.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.initials && item.initials.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
                (item.email && item.email.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)
            ) {
              matches.push(item)
            }
          })
          return matches
        }

        $scope.printRepairDocument = function (mode) {
          $api.get('shops/' + $rootScope.user.shops['0'].nameSlug)
            .then(function (response) {
              $scope.shopAdd = response.data.shop

              $pdf.downloadPdf('generate-repair-document', {
                transaction: $scope.transaction,
                mode: mode,
                language: $rootScope.language,
                showComment: true,
                deviceId: $rootScope.currentDevice._id,
                shopId: $rootScope.currentShop._id
              }, ($scope.transaction.number ? $scope.transaction.number + '_' : '') + mode + '_' + 'receipt.pdf')
                .then(function (data) {

                })
                .catch(function (reason) {
                  console.error(reason)
                })
            }).catch(function (reason) {
              console.error('Error while getting shop', reason)
            })
        }
      }]
  }
})
