prismanoteApp.directive('productFilterByJewelTypeNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-jewel-type-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      $scope.STANDARD_MODEL = false
      $scope.MILANESE_MESH = false
      $scope.NATO = false
      $scope.EXPANDABLE_STRETCH_STRAP = false
      $scope.SELECT_BAND = false
      $scope.CHAIN_OF_A_POCKET_WATCH = false
      $scope.SELECT_BAND_FOR_POCKET_WATCH = false
      $scope.PLASTIC_SILICONE = false

      $scope.$on('strap_type_filter_remove', function (event, sort) {
        console.log('$sort =======>', sort)
        var remove = sort.removeFilter

        if (remove.name === 'STANDARD MODEL') {
          $scope.jewelType.standardModel = false
          $scope.setJewelType()
        } else if (remove.name == 'MILANESE MESH') {
          $scope.jewelType.milaneseMesh = false
          $scope.setJewelType()
        } else if (remove.name === 'NATO') {
          $scope.jewelType.nato = false
          $scope.setJewelType()
        } else if (remove.name === 'EXPANDABLE STRETCH STRAP') {
          $scope.jewelType.expandableStretch = false
          $scope.setJewelType()
        } else if (remove.name === 'CHAIN OF A POCKET WATCH') {
          $scope.jewelType.chainOfPocketWatch = false
          $scope.setJewelType()
        } else if (remove.name === 'SELECT BAND') {
          $scope.jewelType.select = false
          $scope.setJewelType()
        }
      })

      $scope.setJewelType = function (status) {
        console.log('------------- setJewelType');
        var filter = { 'jewel.type': {} }
        var jewelTypes = []

        console.log('Hey product handling guy this Funcion Call')

        if ($scope.jewelType) {
          console.log(' $scope.jewelType ', $scope.jewelType)

          if ($scope.jewelType.brooch) {
            jewelTypes.push('BROOCH')
            var temp = { name: 'BROOCH', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('BROOCH')) {
            jewelTypes.splice(jewelTypes.indexOf('BROOCH'))
            var temp = { name: 'BROOCH', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'BROOCH', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.choker) {
            jewelTypes.push('CHOKER')
            var temp = { name: 'CHOKER', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('CHOKER')) {
            jewelTypes.splice(jewelTypes.indexOf('CHOKER'))
            var temp = { name: 'CHOKER', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'CHOKER', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.pendant) {
            jewelTypes.push('PENDANT')
            var temp = { name: 'PENDANT', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('PENDANT')) {
            jewelTypes.splice(jewelTypes.indexOf('PENDANT'))
            var temp = { name: 'PENDANT', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'PENDANT', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.necklace) {
            jewelTypes.push('NECKLACE')
            var temp = { name: 'NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('NECKLACE')) {
            jewelTypes.splice(jewelTypes.indexOf('NECKLACE'))
            var temp = { name: 'NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.tennis_necklace) {
            jewelTypes.push('TENNIS_NECKLACE')
            var temp = { name: 'TENNIS_NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('TENNIS_NECKLACE')) {
            jewelTypes.splice(jewelTypes.indexOf('TENNIS_NECKLACE'))
            var temp = { name: 'TENNIS_NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'TENNIS_NECKLACE', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.stud_earrings) {
            jewelTypes.push('STUD_EARRINGS')
            var temp = { name: 'STUD_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('STUD_EARRINGS')) {
            jewelTypes.splice(jewelTypes.indexOf('STUD_EARRINGS'))
            var temp = { name: 'STUD_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'STUD_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.hoop_earrings) {
            jewelTypes.push('HOOP_EARRINGS')
            var temp = { name: 'HOOP_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('HOOP_EARRINGS')) {
            jewelTypes.splice(jewelTypes.indexOf('HOOP_EARRINGS'))
            var temp = { name: 'HOOP_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'HOOP_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.creole_earrings) {
            jewelTypes.push('CREOLE_EARRINGS')
            var temp = { name: 'CREOLE_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('CREOLE_EARRINGS')) {
            jewelTypes.splice(jewelTypes.indexOf('CREOLE_EARRINGS'))
            var temp = { name: 'CREOLE_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'CREOLE_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          console.log('$scope.jewelType ', $scope.jewelType);
          if ($scope.jewelType.ankle_bracelet) {
            jewelTypes.push('ANKLE_BRACELET')
            var temp = { name: 'ANKLE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('ANKLE_BRACELET')) {
            jewelTypes.splice(jewelTypes.indexOf('ANKLE_BRACELET'))
            var temp = { name: 'ANKLE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'ANKLE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.cufflink) {
            jewelTypes.push('CUFFLINK')
            var temp = { name: 'CUFFLINK', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('CUFFLINK')) {
            jewelTypes.splice(jewelTypes.indexOf('CUFFLINK'))
            var temp = { name: 'CUFFLINK', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'CUFFLINK', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.ring) {
            jewelTypes.push('RING')
            var temp = { name: 'RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('RING')) {
            jewelTypes.splice(jewelTypes.indexOf('RING'))
            var temp = { name: 'RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.combination_ring) {
            jewelTypes.push('COMBINATION_RING')
            var temp = { name: 'COMBINATION_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('COMBINATION_RING')) {
            jewelTypes.splice(jewelTypes.indexOf('COMBINATION_RING'))
            var temp = { name: 'COMBINATION_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'COMBINATION_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.ring_with_gem) {
            jewelTypes.push('RING_WITH_GEM')
            var temp = { name: 'RING_WITH_GEM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('RING_WITH_GEM')) {
            jewelTypes.splice(jewelTypes.indexOf('RING_WITH_GEM'))
            var temp = { name: 'RING_WITH_GEM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'RING_WITH_GEM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.set) {
            jewelTypes.push('SET')
            var temp = { name: 'SET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('SET')) {
            jewelTypes.splice(jewelTypes.indexOf('SET'))
            var temp = { name: 'SET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'SET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.bracelet) {
            jewelTypes.push('BRACELET')
            var temp = { name: 'BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('BRACELET')) {
            jewelTypes.splice(jewelTypes.indexOf('BRACELET'))
            var temp = { name: 'BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.tennis_bracelet) {
            jewelTypes.push('TENNIS_BRACELET')
            var temp = { name: 'TENNIS_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('TENNIS_BRACELET')) {
            jewelTypes.splice(jewelTypes.indexOf('TENNIS_BRACELET'))
            var temp = { name: 'TENNIS_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'TENNIS_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.slave_bracelet) {
            jewelTypes.push('SLAVE_BRACELET')
            var temp = { name: 'SLAVE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('SLAVE_BRACELET')) {
            jewelTypes.splice(jewelTypes.indexOf('SLAVE_BRACELET'))
            var temp = { name: 'SLAVE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'SLAVE_BRACELET', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.seal_ring) {
            jewelTypes.push('SEAL_RING')
            var temp = { name: 'SEAL_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('SEAL_RING')) {
            jewelTypes.splice(jewelTypes.indexOf('SEAL_RING'))
            var temp = { name: 'SEAL_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'SEAL_RING', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.CHARM) {
            jewelTypes.push('CHARM')
            var temp = { name: 'CHARM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('CHARM')) {
            jewelTypes.splice(jewelTypes.indexOf('CHARM'))
            var temp = { name: 'CHARM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'CHARM', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.jewelType.pendant_earrings) {
            jewelTypes.push('PENDANT_EARRINGS')
            var temp = { name: 'PENDANT_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          } else if (jewelTypes.includes('PENDANT_EARRINGS')) {
            jewelTypes.splice(jewelTypes.indexOf('PENDANT_EARRINGS'))
            var temp = { name: 'PENDANT_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var temp = { name: 'PENDANT_EARRINGS', filter: 'jewel type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if (jewelTypes.length == 0) jewelTypes = []
          filter['jewel.type'] = { $in: jewelTypes }
          if (status && status == 'localStorage') filter.status = status
          $scope.$emit('jewel_model_filter_directive', { filter: filter })
        } else {
          filter['jewel.type'] = { $in: jewelTypes }
          if (status && status == 'localStorage') filter.status = status
          $scope.$emit('jewel_model_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
