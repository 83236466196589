prismanoteApp.directive('productFilterByStrapMaterial', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-material.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.REAL_LEATHER = false
      $scope.UPPER_LEATHER = false
      $scope.PU_LEATHER = false
      $scope.STAINLESS_STEEL = false
      $scope.TITANIUM = false
      $scope.ALUMINIUM = false
      $scope.METAL = false
      $scope.PLASTIC_SILICONE = false
      $scope.CANVAS = false
      $scope.CERAMICS = false

      shopFiltersAvailbeOrNot = function () {
        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('REAL_LEATHER') != -1) $scope.REAL_LEATHER = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('UPPER_LEATHER') != -1) $scope.UPPER_LEATHER = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('PU_LEATHER') != -1)$scope.PU_LEATHER = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('STAINLESS_STEEL') != -1) $scope.STAINLESS_STEEL = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('TITANIUM') != -1) $scope.TITANIUM = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('ALUMINIUM') != -1) $scope.ALUMINIUM = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('METAL') != -1)$scope.METAL = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('CANVAS') != -1) $scope.CANVAS = true

        if ($rootScope.shopFilters.watchStrapMaterial.indexOf('CERAMICS') != -1) $scope.CERAMICS = true
      }
      shopFiltersAvailbeOrNot()
      $scope.setStrapMaterial = function () {
        var filter = { 'watch.strap.material': {} }
        var strapMaterials = []

        if ($scope.strapMaterial) {
          if ($scope.strapMaterial.leather) {
            strapMaterials.push('REAL_LEATHER')
          } else if (strapMaterials.includes('REAL_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('REAL_LEATHER'))
          }

          if ($scope.strapMaterial.upperLeather) {
            strapMaterials.push('UPPER_LEATHER')
          } else if (strapMaterials.includes('UPPER_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('UPPER_LEATHER'))
          }

          if ($scope.strapMaterial.puLeather) {
            strapMaterials.push('PU_LEATHER')
          } else if (strapMaterials.includes('PU_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('PU_LEATHER'))
          }

          if ($scope.strapMaterial.stainlessSteel) {
            strapMaterials.push('STAINLESS_STEEL')
          } else if (strapMaterials.includes('STAINLESS_STEEL')) {
            strapMaterials.splice(strapMaterials.indexOf('STAINLESS_STEEL'))
          }

          if ($scope.strapMaterial.titanium) {
            strapMaterials.push('TITANIUM')
          } else if (strapMaterials.includes('TITANIUM')) {
            strapMaterials.splice(strapMaterials.indexOf('TITANIUM'))
          }

          if ($scope.strapMaterial.aluminium) {
            strapMaterials.push('ALUMINIUM')
          } else if (strapMaterials.includes('ALUMINIUM')) {
            strapMaterials.splice(strapMaterials.indexOf('ALUMINIUM'))
          }

          if ($scope.strapMaterial.metal) {
            strapMaterials.push('METAL')
          } else if (strapMaterials.includes('METAL')) {
            strapMaterials.splice(strapMaterials.indexOf('METAL'))
          }

          if ($scope.strapMaterial.plasticSilicon) {
            strapMaterials.push('PLASTIC_SILICONE')
          } else if (strapMaterials.includes('PLASTIC_SILICONE')) {
            strapMaterials.splice(strapMaterials.indexOf('PLASTIC_SILICONE'))
          }

          if ($scope.strapMaterial.canvas) {
            strapMaterials.push('CANVAS')
          } else if (strapMaterials.includes('CANVAS')) {
            strapMaterials.splice(strapMaterials.indexOf('CANVAS'))
          }

          if ($scope.strapMaterial.ceramics) {
            strapMaterials.push('CERAMICS')
          } else if (strapMaterials.includes('CERAMICS')) {
            strapMaterials.splice(strapMaterials.indexOf('CERAMICS'))
          }

          if (strapMaterials.length == 0) {
            // strapMaterials = ['TITANIUM', 'STAINLESS_STEEL', 'ALUMINIUM', 'METAL', 'PU_LEATHER', 'real leather', 'UPPER_LEATHER', 'PLASTIC_SILICONE', 'CANVAS', 'CERAMICS'];
          }

          filter['watch.strap.material'] = { $in: strapMaterials }

          $scope.$emit('strap_material_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
