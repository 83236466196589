prismanoteApp.directive('customProductSearch', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-product-search.html',
        scope: {
            'shopData': '=',
            'onSearchProduct': '&'
        },
        controller: ['$scope', '$rootScope', '$language', function ($scope, $rootScope, $language) {
            $scope.searchValue1 = '';
            $scope.showSuggestions = false;
            $scope.isSelectSuggestion = false;

            $scope.categoryArray = {
                'WATCH' : $language.translate('WATCH'),
                'WATCHSTRAP' : $language.translate('WATCHSTRAP'),
                'JEWELRY' : $language.translate('JEWELRY')
            };

            $scope.genderArray = {
                'men' : $language.translate('MEN'),
                'ladies' : $language.translate('LADIES'),
                'boys': $language.translate('BOYS'),
                'girls': $language.translate('GIRLS')
            };

            $scope.jewelTypeArray = {
                'BROOCH': $language.translate('BROOCH'),
                'CHOKER': $language.translate('CHOKER'),
                'NECKLACE': $language.translate('NECKLACE'),
                'TENNIS_NECKLACE': $language.translate('TENNIS_NECKLACE'),
                'STUD_EARRINGS': $language.translate('STUD_EARRINGS'),
                'HOOP_EARRINGS': $language.translate('HOOP_EARRINGS'),
                'CREOLE_EARRINGS': $language.translate('CREOLE_EARRINGS'),
                'RING': $language.translate('RING'),
                'COMBINATION_RING': $language.translate('COMBINATION_RING'),
                'RING_WITH_GEM': $language.translate('RING_WITH_GEM'),
                'SET': $language.translate('SET'),
                'BRACELET': $language.translate('BRACELET'),
                'TENNIS_BRACELET': $language.translate('TENNIS_BRACELET'),
                'SLAVE_BRACELET': $language.translate('SLAVE_BRACELET'),
                'SEAL_RING': 'Seal Ring',
                'CHARM': 'Charm',
                'PENDANT_EARRINGS': 'Pendant Earrings',
                'ANKLE_BRACELET': $language.translate('ANKLE_BRACELET'),
                'CUFFLINK': $language.translate('CUFFLINK')
            };

            $scope.jewelMaterialArray = {
                'GOLD': $language.translate('GOLD'),
                'SILVER': $language.translate('SILVER'),
                'TITANIUM': $language.translate('TITANIUM'),
                'STAINLESS_STEEL': $language.translate('STAINLESS_STEEL'),
                'ALUMINIUM': $language.translate('ALUMINIUM'),
                'METAL': $language.translate('METAL'),
                'WOOD': $language.translate('WOOD'),
                'CERAMICS': $language.translate('CERAMICS'),
                'PU_LEATHER': $language.translate('PU_LEATHER'),
                'REAL_LEATHER': $language.translate('REAL_LEATHER'),
                'UPPER_LEATHER': $language.translate('UPPER_LEATHER'),
                'CANVAS': $language.translate('CANVAS')
            }

            $scope.watchCaseMaterialArray = {
                'STAINLESS_STEEL': $language.translate('STAINLESS_STEEL'),
                'TITANIUM': $language.translate('TITANIUM'),
                'ALUMINIUM': $language.translate('ALUMINIUM'),
                'METAL': $language.translate('METAL'),
                'PLASTIC_SILICONE': $language.translate('PLASTIC_SILICONE'),
                'CERAMICS': $language.translate('CERAMICS'),
                'WOOD': $language.translate('WOOD')
            }

            $scope.watchStrapMaterialArray = {
                'REAL_LEATHER': $language.translate('REAL_LEATHER'),
                'UPPER_LEATHER': $language.translate('UPPER_LEATHER'),
                'PU_LEATHER': $language.translate('PU_LEATHER'),
                'STAINLESS_STEEL': $language.translate('STAINLESS_STEEL'),
                'TITANIUM': $language.translate('TITANIUM'),
                'ALUMINIUM': $language.translate('ALUMINIUM'),
                'METAL': $language.translate('METAL'),
                'PLASTIC_SILICONE': $language.translate('PLASTIC_SILICONE'),
                'CANVAS': $language.translate('CANVAS'),
                'CERAMICS': $language.translate('CERAMICS'),
                'GOLD': $language.translate('GOLD'),
                'PALLADIUM': $language.translate('PALLADIUM'),
                'WOOD': $language.translate('WOOD'),
                'BRONZE': $language.translate('BRONZE')
            }

            $scope.watchTypeArray = {
                'WRIST': $language.translate('WRIST'),
                'POCKET': $language.translate('POCKET'),
                'NURSE': $language.translate('NURSE'),
                'SMARTWATCH': $language.translate('SMARTWATCH'),
            }
            $scope.watchParticularsArray = {
                'hasSwissMovement': $language.translate('SWISS_MOVEMENT'),
                'hasDateFunction': $language.translate('DATE_DISPLAY'),
                'isAntiAllergy': $language.translate('ANTI_ALLERGIC'),
                'isNickelFree': $language.translate('NICKEL_FREE'),
                'hasLightFunction': $language.translate('LIGHT_FUNCTION'),
            }

            $scope.watchIndexArray = {
                'ARABIC': $language.translate('ARABIC'),
                'ROMAN': $language.translate('ROMAN'),
                'STRIPES': $language.translate('STRIPES'),
                'DOTS': $language.translate('DOTS'),
                'REAL_DIAMONDS': $language.translate('REAL_DIAMONDS'),
                'NONE': $language.translate('NONE'),
            }

            $scope.watchIndicationArray = {
                'CHRONOGRAPH': $language.translate('CHRONOGRAPH'),
                'ANALOG': $language.translate('ANALOG'),
                'DIGITAL': $language.translate('DIGITAL'),
                'ANALOG_DIGITAL': $language.translate('ANALOG_DIGITAL'),
            }

            $scope.initializeData = function(){
                $scope.searchSuggestionsList = [];
                $scope.showSizeCheck = false;
                $scope.showNumberCheck = false;
                $scope.isNumberCheck = false;
                $scope.isSizeCheck = false;
                $scope.searchByGender = {};
                $scope.suggestedGender = '';
                $scope.number = 0;
            }
            $scope.initializeData();

            $scope.searchProduct = function(value){
                if(value.length > 2){
                    $scope.showSuggestions = true;
                    $scope.initializeData();
                    filterSuggestions(value);
                } else {
                    $scope.initializeData();
                }
            }
            
            $scope.searchBySuggestion = function(suggestion){
                var customData = {isDefaultSearch: false, params:{}, searchValue: $scope.searchValue1}
                customData.params['property'] = suggestion.property;
                if(suggestion.property == 'BRAND'){
                    customData.params['_id'] = suggestion._id._id;
                    $scope.searchValue1 = suggestion.name;
                } else {
                    customData.params['key'] = suggestion.name;
                    customData.params['value'] = suggestion.value;
                    $scope.searchValue1 = suggestion.name;
                }
                if(suggestion.property == 'BRAND_WITH_GENDER'){
                    customData.params['_id'] = suggestion._id._id;
                    customData.params['property'] = 'BRAND';
                    $scope.searchValue1 = suggestion.name + ' ' + $language.translate($scope.suggestedGender);
                    if(Object.keys($scope.searchByGender).length > 0){
                        customData.params['gender'] = $scope.searchByGender;
                    }
                }
                if(suggestion.property == 'BRAND_WITH_CATEGORY'){
                    customData.params['_id'] = suggestion._id;
                    customData.params['property'] = 'BRAND';
                    $scope.searchValue1 = suggestion.name + ' ' + $language.translate(suggestion.category);
                    customData.params['category'] = suggestion.category;
                }
                
                if($scope.isNumberCheck || $scope.isSizeCheck){
                    var index = $scope.isNumberCheck ? 'allNumber' : 'size'
                    customData.params[index] = $scope.number;
                }
                    
                $scope.showSuggestions = false;
                $scope.initializeData();
                $scope.onSearchProduct()(customData);
            }

            $scope.checkEvent = function(keyEvent){
                if (keyEvent.which === 13) {
                    $scope.defaultSearch();
                }
            }

            $scope.defaultSearch = function(){
                $scope.showSuggestions = false;
                $scope.initializeData();
                $scope.onSearchProduct()({isDefaultSearch: true, params:{}, searchValue: $scope.searchValue1});
            }

            function filterSuggestions(value){
                var temp = value.replace(/\'/g, '').split(/(\d+)/).filter(Boolean);
                var text1 = temp.filter(function(index){ return isNaN(index)});
                text = text1.length > 0 ? text1[0].split(" ").filter(Boolean) : '';
                var number = temp.filter(function(index){ return !isNaN(index)});
                
                for(var i = 0; i < text.length; i++){
                    Object.keys($scope.genderArray).filter(function (g) {
                        if($scope.genderArray[g].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            $scope.suggestedGender = g.toUpperCase();
                            if($scope.suggestedGender == 'MEN'){
                                $scope.searchByGender = {
                                    "product.male": true,
                                    "product.kids": false
                                };
                            } else if($scope.suggestedGender == 'LADIES'){
                                $scope.searchByGender = {
                                    "product.female": true,
                                    "product.kids": false
                                };
                            } else if($scope.suggestedGender == 'BOYS'){
                                $scope.searchByGender = {
                                    "product.male": true,
                                    "product.kids": true
                                };
                            } else if($scope.suggestedGender == 'GIRLS'){
                                $scope.searchByGender = {
                                    "product.female": true,
                                    "product.kids": true
                                };
                            }
                        }
                    })
                }
                for(var i = 0; i < text.length; i++){
                    $scope.shopData.brands.filter(function (b) {
                        if(b.name.toString().toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            b['property'] = 'BRAND';
                            $scope.searchSuggestionsList.push(b);
                            if($scope.suggestedGender != ''){
                                c = JSON.parse(JSON.stringify(b));
                                c['property'] = 'BRAND_WITH_GENDER';
                                $scope.searchSuggestionsList.push(c);
                            }
                            
                            Object.keys($scope.categoryArray).filter(function (j) {
                                for(var cIndex = 0; cIndex < text.length; cIndex++){
                                    if($scope.categoryArray[j].toLowerCase().indexOf(text[cIndex].trim().toString().toLowerCase()) >= 0){
                                        $scope.searchSuggestionsList.push({
                                            'property': 'BRAND_WITH_CATEGORY',
                                            'name' : b.name,
                                            '_id' : b._id,
                                            'category': j.toUpperCase()
                                        });
                                    }
                                }
                            })
                            return b;
                        } else if(b.nameSlug.toString().toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            b['property'] = 'BRAND';
                            $scope.searchSuggestionsList.push(b);
                            if($scope.suggestedGender != ''){
                                c = JSON.parse(JSON.stringify(b));
                                c['property'] = 'BRAND_WITH_GENDER';
                                $scope.searchSuggestionsList.push(c);
                            }
                            
                            Object.keys($scope.categoryArray).filter(function (j) {
                                for(var cIndex = 0; cIndex < text.length; cIndex++){
                                    if($scope.categoryArray[j].toLowerCase().indexOf(text[cIndex].trim().toString().toLowerCase()) >= 0){
                                        $scope.searchSuggestionsList.push({
                                            'property': 'BRAND_WITH_CATEGORY',
                                            'name' : b.name,
                                            '_id' : b._id,
                                            'category': j.toUpperCase()
                                        });
                                    }
                                }
                            })
                            return b;
                        }
                    });

                    Object.keys($scope.jewelTypeArray).filter(function (j) {
                        if($scope.jewelTypeArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'JEWELLERY_TYPE',
                                'name' : $scope.jewelTypeArray[j],
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    // Object.keys($scope.categoryArray).filter(function (j) {
                    //     if($scope.categoryArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                    //         var c = {
                    //             'property': 'CATEGORY',
                    //             'name' : $scope.categoryArray[j],
                    //             'value' : j.toUpperCase()
                    //         };
                    //         $scope.searchSuggestionsList.push(c);
                    //     }
                    // })

                    Object.keys($scope.jewelMaterialArray).filter(function (j) {
                        if($scope.jewelMaterialArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'JEWEL_MATERIAL',
                                'name' : $scope.jewelMaterialArray[j] + ' ' + $language.translate('JEWELRY'),
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })
                    Object.keys($scope.watchCaseMaterialArray).filter(function (j) {
                        if($scope.watchCaseMaterialArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'WATCH_CASE',
                                'name' : $scope.watchCaseMaterialArray[j] + ' ' + $language.translate('WATCH'),
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    Object.keys($scope.watchStrapMaterialArray).filter(function (j) {
                        if($scope.watchStrapMaterialArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'WATCHSTRAP_MATERIAL',
                                'name' : $scope.watchStrapMaterialArray[j] + ' ' + $language.translate('WATCHSTRAP'),
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    Object.keys($scope.watchTypeArray).filter(function (j) {
                        if($scope.watchTypeArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'WATCH_TYPE',
                                'name' : $scope.watchTypeArray[j] ,
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    Object.keys($scope.watchParticularsArray).filter(function (j) {
                        if($scope.watchParticularsArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'PARTICULARS',
                                'name' : $scope.watchParticularsArray[j] ,
                                'value' : j
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    Object.keys($scope.watchIndexArray).filter(function (j) {
                        if($scope.watchIndexArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'INDEX',
                                'name' : $scope.watchIndexArray[j] ,
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })

                    Object.keys($scope.watchIndicationArray).filter(function (j) {
                        if($scope.watchIndicationArray[j].toLowerCase().indexOf(text[i].trim().toString().toLowerCase()) >= 0){
                            var c = {
                                'property': 'TIME_INDICATION',
                                'name' : $scope.watchIndicationArray[j] ,
                                'value' : j.toUpperCase()
                            };
                            $scope.searchSuggestionsList.push(c);
                        }
                    })
                }

                if(number && number.length > 0){
                    $scope.number = parseInt(number[0]);
                    $scope.showSizeCheck = number[0].length <= 2;
                    $scope.showNumberCheck = number[0].length > 2;
                } else {
                    $scope.showSizeCheck = false;
                    $scope.showNumberCheck = false;
                }
            }
        }]
    }
  })