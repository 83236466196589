prismanoteApp.directive('productFilterByGlassMaterial', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-glass-material.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setGlassMaterial = function () {
        var filter = { 'watch.glassMaterial': {} }
        var glassMaterials = []

        if ($scope.glassMaterial) {
          if ($scope.glassMaterial.sapphire) {
            glassMaterials.push('SAPPHIRE')
          } else if (glassMaterials.indexOf('SAPPHIRE') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SAPPHIRE'))
          }

          if ($scope.glassMaterial.sapphireCoated) {
            glassMaterials.push('SAPPHIRE_COATED')
          } else if (glassMaterials.indexOf('SAPPHIRE_COATED') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SAPPHIRE_COATED'))
          }

          if ($scope.glassMaterial.crystal) {
            glassMaterials.push('CRYSTAL')
          } else if (glassMaterials.indexOf('CRYSTAL') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('CRYSTAL'))
          }

          if ($scope.glassMaterial.mineral) {
            glassMaterials.push('MINERAL')
          } else if (glassMaterials.indexOf('MINERAL') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('MINERAL'))
          }

          if ($scope.glassMaterial.syntheticPlastic) {
            glassMaterials.push('SYNTHETIC_PLASTIC')
          } else if (glassMaterials.indexOf('SYNTHETIC_PLASTIC') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SYNTHETIC_PLASTIC'))
          }
        }

        var filter

        if (glassMaterials.length == 0) {
          glassMaterials = ['SAPPHIRE', 'SAPPHIRE_COATED', 'CRYSTAL', 'MINERAL', 'SYNTHETIC_PLASTIC']
        }

        filter['watch.glassMaterial'] = { $in: glassMaterials }

        $scope.getProducts({ filter: filter }, { reset: true })

          .catch(function (reason) {
            console.error(reason)
          })
      }
    }]
  }
})
