prismanoteApp.directive('productFilterByBrandNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-filter-by-brand-new.html',
    controller: ['$rootScope', '$scope', '$state', '$api', '$shop', '$filter', '$company', '$retailerFilters',
      function ($rootScope, $scope, $state, $api, $shop, $filter, $company, $retailerFilters) {
        $scope.customBrandFilter = []
        $scope.filterBrands = []
        $scope.selectedBrands = []

        // console.log('Call brand new directive')

        function getCurrentSelectedBrand () {
          $retailerFilters.getBrandFilter()
            .then(function (brandFilter) {
              brandFilter = JSON.parse(brandFilter)
              if (brandFilter && brandFilter['brand._id'] && brandFilter['brand._id'].$in && brandFilter['brand._id'].$in.length) {
                $scope.status = 'localStorage'
                $scope.filterBrands.map(function (element) {
                  if (element._id && element._id._id) {
                    for (var i = 1; i < brandFilter['brand._id'].$in.length; i++) {
                      if (element._id._id == brandFilter['brand._id'].$in[i]) {
                        element.status = true
                        $scope.selectedBrands.push(element._id)
                      }
                    }
                  }
                })
                $scope.setBrandNew(brandFilter['brand._id'].$in[0])
              } else {
                $scope.status = 'localStorage'
                $scope.setBrandNew(null)
              }
            })
            .catch(function (error) {
              console.error(error)
            })
        }

        $scope.$on('brand_filter_remove', function (event, sort) {
          console.log('$Brand =======>', sort)
          var remove = sort.removeFilter
          var index = _.findIndex($scope.filterBrands, { name: remove.name })
          $scope.filterBrands[index].status = false
          $scope.setBrandNew($scope.filterBrands[index]._id)
        })

        $scope.getMyBrandClass = function (brandId) {
          // return $.inArray(brandId,$scope.filterChecker.brandsInProds)?'' : 'bg-danger';
          return ''
        }
        var filter = { 'brand._id': { $in: [] } }

        $scope.brandExistInProducts = function () {
          return 'bg-success'
        }

        /* Reload products if the brand selection has changed. */
        $scope.setBrandNew = function (selectBrandId) {
          // console.log('Selected Brand Id', selectBrandId)
          $scope.selectedBrands = $scope.filterBrands.filter(function (filterBrand) {
            if (filterBrand._id) {
              if ($scope.status == 'localStorage') {
                if ((filterBrand._id._id) == selectBrandId) {
                  filterBrand.status = true
                  return filterBrand._id
                }
              } else {
                if ((filterBrand._id._id).toString() == selectBrandId && filterBrand.status == true) {
                  return filterBrand._id
                }
              }
            }
          })

          /* Update the brand selection based on the selected checkboxes. */
          $scope.selectedBrands = $scope.filterBrands.filter(function (filterBrand) { return filterBrand.status })

          /* Add selected brands to filter. If no brands are selected, add all brands to the filter, but leave selected brands empty for next time this function is called (when a brand is selected again). */
          if ($scope.selectedBrands.length == 0) {
            filter['brand._id'].$in = []
          } else {
            filter['brand._id'].$in = _.compact(_.map($scope.selectedBrands, function (o) {
              if (o._id) return o._id._id
            }))
          }

          var tempLength = $rootScope.selectdFilter.length
          for (var i = 0; i < tempLength; i++) {
            if ($rootScope.selectdFilter[i].filter == 'Brand') {
              $rootScope.selectdFilter.splice(i, 1)
              tempLength--
              i--
            }
          }

          for (var i = 0; i < filter['brand._id'].$in.length; i++) {
            var indexInBrands = _.findIndex($scope.selectedBrands, function (o) {
              if (o._id && o._id._id === filter['brand._id'].$in[i]) return o
            })

            if (indexInBrands > -1) {
              var index = _.findIndex($rootScope.selectdFilter, { name: $scope.selectedBrands[indexInBrands].name, filter: 'Brand' })
              if (index < 0) $rootScope.selectdFilter.push({ name: $scope.selectedBrands[indexInBrands].name, filter: 'Brand' })
            }
          }

          $retailerFilters.setBrandFilter(filter)

          if ($scope.status && $scope.status == 'localStorage') {
            filter.status = 'localStorage'
            delete $scope.status
          } else {
            delete filter.status
          }
          $scope.$emit('brand_filter_directive', { filter: filter })
        }

        $scope.setFilterBrands = function (shop) {
          // console.log('shop Here', shop)
          /* Default params for the filter brands that will be used: */
          /* Ignore brands without products; only return the most elementary details instead of the full object; don't limit; sort alphabetically. */
          var brandParams = {
            hasProducts: true,
            short: true,
            limit: undefined,
            sort: {
              name: 'asc'
            }
          }

          if (shop != null) {
            /* Get shop brands. */
            var brands = shop.brands || []
            /* Use only filterBrands that this shop actually has. */
            brandParams.filter = {
              _id: {
                $in: brands.map(function (brand) { return brand.nameSlug })
              }
            }
          }

          if ($rootScope && $rootScope.currentShop) {
            /* Get the matching selectable filter brands from the db (only include brands with actual products). */
            // $api.get("brands/getshopbrands/" + $rootScope.currentShop.nameSlug)
            $shop.getShopsBrandsAssortment($rootScope.currentShop.nameSlug)
              .then(function (response) {
                $scope.filterBrands = response.brands
                // filter["brand._id"].$in = $scope.filterBrands.map(function (brand) {
                // 	if(brand && brand._id && brand._id._id) return brand._id._id;
                // });
                getCurrentSelectedBrand()
              })
              .catch(function (reason) {
                console.error(reason)
              })
          } else if ($rootScope && $rootScope.currentCompany && $rootScope.currentCompany._id) {
            $company.getCompanyBrandsForFilter($rootScope.currentCompany.nameSlug)
              .then(function (brands) {
                console.log('Brands of the company ', brands)
                $scope.filterBrands = brands.brands
                getCurrentSelectedBrand()
              })
              .catch(function (error) {
                console.error('Error', error)
              })
          } else {
            /* Get the matching selectable filter brands from the db (only include brands with actual products). */
            $api.get('brands?getAll=true')
              .then(function (response) {
                $scope.filterBrands = $filter('orderBy')(response.data.brands, 'name')
                filter['brand._id'].$in = $scope.filterBrands.map(function (brand) { return brand._id })
              })
              .catch(function (reason) {
                console.error(reason)
              })
          }
        }

        /* Find out which brands to include in the filter (in the retailer portal). */
        if ($state.includes('retailer')) {
          // $retailer.getShop()
          // 	.then(function (shop) {
          $scope.setFilterBrands()
          // 	})
          // 	.catch(function (reason) {
          // 		console.error(reason);
          // 	});

          /* Find out which brands to include in the filter (in the shop/proshop). */
        } else if ($state.includes('brand')) {
          $scope.setFilterBrands()
        }
        // else if ($state.includes('shop') || $state.includes('proshop')) {
        // 	//get the shop brands to filter brands which are not on the shop
        // 	var nameSlug = $stateParams.nameSlug;
        // 	$api.get('shops/' + nameSlug)
        // 		.then(function (response) {
        // 			$scope.setFilterBrands(response.data.shop);
        // 		})
        // 		.catch(function (reason) {
        // 			// $scope.setFilterBrands();
        // 		})
        // 	/* Find out which brands to include in the filter (in the PrismaNote general products overview or other places where this filter is used). */
        // }
			 else {
          $scope.setFilterBrands()
        }
      }]
  }
})
