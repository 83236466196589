prismanoteApp.directive('customFilterByWatchMaterial', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-filter-by-watch-material.html',
        scope: {
            filter: '=filter',
        },
        controller: ['$scope', function ($scope) {
            console.log('customFilterByWatchMaterial called:@@@@!!!!! ', $scope.filter);
        }]
    }
})