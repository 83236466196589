prismanoteApp.directive('productFilterByVerification', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-filter-by-verification.html',
    controller: ['$scope', '$state', '$api', '$retailer', '$stateParams', function ($scope, $state, $api, $retailer, $stateParams) {
      var filter = { 'brand.nameSlug': { $in: [] } }

      $scope.filterBrands = []
      $scope.selectedBrands = []

      $scope.toggleIsVerified = function () {
        // console.log("Is Verified");
      }
      $scope.toggleIsNotVerified = function () {
        // console.log("Is Not Verified");
      }

      /* Reload products if the brand selection has changed. */
      $scope.setVerification = function () {
        /* Update the brand selection based on the selected checkboxes. */
        $scope.selectedBrands = $scope.filterBrands.filter(function (filterBrand) { return filterBrand.selected })

        /* Add selected brands to filter. If no brands are selected, add all brands to the filter, but leave selected brands empty for next time this function is called (when a brand is selected again). */
        if ($scope.selectedBrands.length == 0) {
          filter['brand.nameSlug'].$in = $scope.filterBrands.map(function (brand) { return brand.nameSlug })
        } else {
          filter['brand.nameSlug'].$in = $scope.selectedBrands.map(function (brand) { return brand.nameSlug })
        }

        /* Update products with the new brand selection. */
        $scope.getProducts({ filter: filter }, { reset: true })

          .catch(function (reason) {
            console.error(reason)
          })
      }

      $scope.setFilterBrands = function (shop) {
        /* Default params for the filter brands that will be used: */
        /* Ignore brands without products; only return the most elementary details instead of the full object; don't limit; sort alphabetically. */
        var brandParams = {
          hasProducts: true,
          short: true,
          limit: undefined,
          sort: {
            name: 'asc'
          }
        }

        if (shop != null) {
          /* Get shop brands. */
          var brands = shop.brands || []

          /* Use only filterBrands that this shop actually has. */
          brandParams.filter = {
            nameSlug: {
              $in: brands.map(function (brand) { return brand.nameSlug })
            }
          }
        }

        /* Get the matching selectable filter brands from the db (only include brands with actual products). */
        $api.get('brands', brandParams)

          .then(function (response) {
            $scope.filterBrands = response.data.brands
            filter['brand.nameSlug'].$in = $scope.filterBrands.map(function (brand) { return brand.nameSlug })
          })

          .catch(function (reason) {
            console.error(reason)
          })
      }

      /* Find out which brands to include in the filter (in the retailer portal). */
      if ($state.includes('retailer')) {
        $retailer.getShop()

          .then(function (shop) {
            $scope.setFilterBrands(shop)
          })

          .catch(function (reason) {
            console.error(reason)
          })

        /* Find out which brands to include in the filter (in the shop/proshop). */
      } else if ($state.includes('shop') || $state.includes('proshop')) {
        // get the shop brands to filter brands which are not on the shop
        var nameSlug = $stateParams.nameSlug
        $api.get('shops/' + nameSlug)
          .then(function (response) {
            $scope.setFilterBrands(response.data.shop)
          })
          .catch(function (reason) {
            $scope.setFilterBrands()
          })

        $scope.setFilterBrands()

        /* Find out which brands to include in the filter (in the PrismaNote general products overview or other places where this filter is used). */
      } else {
        $scope.setFilterBrands()
      }
    }]
  }
})
