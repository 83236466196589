prismanoteApp.directive('productClientGroupCollection', function () {
  var filter = {}

  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-client-group-collection.html',
    scope: {
      product: '=product'
    },
    controller: ['$scope', '$rootScope', '$q', '$collection', '$productConfig', function ($scope, $rootScope, $q, $collection, $productConfig) {
      console.log('I AM client group COLLECTION New DIRECTIVE')

      $scope.user = $rootScope.user
      $scope.language = $rootScope.language
      console.log('$rootScope.CurrentCompany', $rootScope)
      $scope.currentCompanyId = $rootScope.currentCompany._id
      $scope.tempName = ''

      console.log('$scope.product', $scope.product)
      $scope.getClientGroupCollections = function (comapany_id) {
        console.log('For the Getting client Group', comapany_id)
        $collection.getOwnClientGroupsCollections(comapany_id)
          .then(function (results) {
            console.log('client group collections', results)
            console.log(results)
            $scope.allClientGroupCollections = results.data
            console.log('$scope.allClientGroupCollections', $scope.allClientGroupCollections)
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }

      $scope.searchInClientGroupCollections = function (str) {
        console.log('-------Str For matches -------', str)
        return searchItems(str)
      }

      $scope.clientGroupCollectionSelected = function (selected) {
        console.log('selected client group collection', selected)
        if (selected.originalObject && selected.originalObject._id && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
          $scope.product.clientGroupCollections = []
          $scope.product.clientGroupCollections.push(selected.originalObject)
        } else {
          assignNameToBlank(selected.originalObject, selected.title)
        }
      }

      $scope.getTheCollectionName = function (collection) {
        var collectionName = ''
        if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
        return collectionName
      }

      $scope.deleteClientGroupCollection = function (index) {
        $scope.product.clientGroupCollections = []
      }

      var searchItems = function (str) {
        var matches = []

        $scope.tempName = str
        var addNewCollectionItem = $productConfig.addNewCollection()

        var collections = $scope.allClientGroupCollections
        var collcetionLength = collections.length

        if (collections != undefined && collcetionLength > 0) {
          collections.forEach(function (item) {
            if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
              matches.push(item)
            }
          })
        }

        if (matches && matches.length < 1) matches.push(addNewCollectionItem)

        return matches
      }

      $scope.addCollection = function (collection) {
        console.log('Collection New', collection)
        console.log('++++++++++', $rootScope)
        collection.companyId = $rootScope.currentCompany._id;
        $collection.checkClientGroupCollectionName(collection)
          .then(function (status) {
            if (status && status.status) {
              $scope.errorMessage = false
              $scope.errorLog = status.data

              $collection.addNewCollection(collection, null, $scope.currentCompanyId)
                .then(function (success) {
                  console.log('product handling guy Please check this added new collection', success.collection)
                  if ($scope.product && $scope.product.clientGroupCollections && $scope.product.clientGroupCollections.length && $scope.product.clientGroupCollections[0]) $scope.product.oldCollection = $scope.product.clientGroupCollections[0]

                  $scope.product.clientGroupCollections = []
                  $scope.product.clientGroupCollections.push(success.collection)
                  $scope.collection = {}
                  $scope.addNewCollectionForm = false
                })
                .catch(function (error) {
                  console.error('Error In adding New Collection in database', error)
                })
            } else {
              $scope.errorMessage = true
              $scope.errorLog = status.error
            }
          })
          .catch(function (error) {
            console.log('Error', error)
          })
      }

      $scope.checkCollectionName = function (name, language) {
        console.log('Please Check enterd Name', name)
        name = name.trim()
        name = name.toLowerCase()

        if (name == 'services') {
          if (!$scope.errorLog) $scope.errorLog = {}
          $scope.errorLog[language] = true
          $scope.errorMessage = true
        } else {
          if (!$scope.errorLog) $scope.errorLog = {}
          $scope.errorLog[language] = false
          $scope.errorMessage = false
        }
      }

      $scope.changeCollectionName = function (tempName) {
        $scope.collection.en.name = tempName
        $scope.collection.de.name = tempName
        $scope.collection.nl.name = tempName
        $scope.collection.fr.name = tempName
        $scope.collection.es.name = tempName
      }

      function assignNameToBlank (collection, value) {
        $scope.addNewCollectionForm = true
        $scope.collection = collection

        $scope.collection.en.name = $scope.tempName
        $scope.collection.de.name = $scope.tempName
        $scope.collection.nl.name = $scope.tempName
        $scope.collection.fr.name = $scope.tempName
        $scope.collection.es.name = $scope.tempName

        $scope.collection.isVerified = false
        $scope.collection.usedByBrand = false
        $scope.collection.privateLabel = false
        $scope.collection.variantsCollection = false
        $scope.collection.isClientGroup = true
        $scope.collection.variantBase = []
        console.log('product handling guy Please Check here collection', $scope.collection)
      }

      $scope.addToVariantBaseArray = function (item) {
        if (!$scope.collection.variantBase) $scope.collection.variantBase = []

        if ($scope.collection.variantBase.length < 2) {
          // check if value already in the array
          if (!checkIfValueAlreadyInArray($scope.collection.variantBase, item)) { // if not in array
            // push to the variantBase array
            $scope.collection.variantBase.push(item)
          } else { // else
            for (var i = 0; i < $scope.collection.variantBase.length; i++) {
              if ($scope.collection.variantBase[i] == item) {
                $scope.collection.variantBase.splice(i, 1)
              };
            }
          }
        } else if ($scope.collection.variantBase.length == 2) {
          // remove the first element i.e remove 0th element
          $scope.collection.variantBase.splice(0, 1)
          // call this function again
          $scope.addToVariantBaseArray(item)
        }
      }

      function checkIfValueAlreadyInArray (array, item) {
        for (var i = 0; i < array.length; i++) {
          if (array[i] == item) {
            return true
          };
        }
        return false
      }
    }]
  }
})
