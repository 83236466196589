prismanoteApp.directive('productFilterByCategoryNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-category-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      function getStoredFilter () {
        $retailerFilters.getCategoryFilter()
          .then(function (categoryFilter) {
            categoryFilter = JSON.parse(categoryFilter)
            $scope.category = {}
            if (categoryFilter && categoryFilter.category && categoryFilter.category.$in && categoryFilter.category.$in.length) {
              for (var i = 0; i < categoryFilter.category.$in.length; i++) {
                if (categoryFilter.category.$in[i] == 'OTHER') {
                  $scope.category.other = true
                  $scope.setCategory('localStorage')
                } else if (categoryFilter.category.$in[i] == 'WATCH') {
                  $scope.category.watch = true
                  $scope.setCategory('localStorage')
                } else if (categoryFilter.category.$in[i] == 'STRAP') {
                  $scope.category.strap = true
                  $scope.setCategory('localStorage')
                } else if (categoryFilter.category.$in[i] == 'JEWEL') {
                  $scope.category.jewel = true
                  $scope.setCategory('localStorage')
                }
              }
            } else {
              $scope.setCategory('localStorage')
            }
          })
          .catch(function (error) {
            console.error('Error :')
            console.error(error)
          })
      }

      getStoredFilter()

      $scope.$on('category_filter_remove', function (event, sort) {
        var remove = sort.removeFilter

        if (remove.name === 'WATCH') {
          $scope.category.watch = false
          $scope.setCategory()
        } else if (remove.name === 'OTHER') {
          $scope.category.other = false
          $scope.setCategory()
        } else if (remove.name === 'STRAP') {
          $scope.category.strap = false
          $scope.setCategory()
        } else if (remove.name === 'JEWEL') {
          $scope.category.jewel = false
          $scope.setCategory()
        }
      })

      $scope.setCategory = function (status) {
        var filter = { category: {} }
        var categories = []

        if ($scope.category) {
          if ($scope.category.watch) categories.push('WATCH')
          else if (categories.includes('WATCH')) categories.splice(categories.indexOf('WATCH'))

          if ($scope.category.strap) categories.push('STRAP')
          else if (categories.includes('STRAP')) categories.splice(categories.indexOf('STRAP'))

          if ($scope.category.jewel) categories.push('JEWEL')
          else if (categories.includes('JEWEL')) categories.splice(categories.indexOf('JEWEL'))

          if ($scope.category.other) categories.push('OTHER')
          else if (categories.includes('OTHER')) categories.splice(categories.indexOf('OTHER'))

          if (categories.length == 0) categories = []

          filter.category.$in = categories

          var tempLength = $rootScope.selectdFilter.length

          for (var i = 0; i < tempLength; i++) {
            if ($rootScope.selectdFilter[i].filter == 'category') {
              $rootScope.selectdFilter.splice(i, 1)
              tempLength--
              i--
            }
          }

          for (var i = 0; i < filter.category.$in.length; i++) {
            var temp = { name: filter.category.$in[i], filter: 'category' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) $rootScope.selectdFilter.push(temp)
          }

          $retailerFilters.setCategoryFilter(filter)

          if (status && status == 'localStorage') filter.status = status

          $scope.$emit('category_filter_directive', { filter: filter })
        } else {
          var filter = { category: { $in: [] } }

          if (status && status == 'localStorage') filter.status = status

          $scope.$emit('category_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
