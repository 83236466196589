prismanoteApp.directive('retailerChat', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/retailer-chat.html',
    scope: {
      chat: '=chat'
    },
    controller: ['$scope', '$rootScope',
      function ($scope, $rootScope) {
        // console.log('chat response in directive: ', $rootScope.awsUrl, $scope.chat);
        $scope.data = {
          remark: '',
          notifications: 0,
          isInitialized: false, // we are not scrolling when new message arrived, just when chat initialize
          awsUrl: $rootScope.awsUrl
        }
      }]
  }
})
