prismanoteApp.directive('productFilterByGlassMaterialNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-glass-material-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.setGlassMaterial = function () {
        var filter = { 'watch.glassMaterial': {} }
        var glassMaterials = []

        if ($scope.glassMaterial) {
          if ($scope.glassMaterial.sapphire) {
            glassMaterials.push('SAPPHIRE')
            if (_.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE', filter: 'Glass Type' }) < 0) $rootScope.selectdFilter.push({ name: 'SAPPHIRE', filter: 'Glass Type' })
          } else if (glassMaterials.indexOf('SAPPHIRE') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SAPPHIRE'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE', filter: 'Glass Type' })
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.glassMaterial.sapphireCoated) {
            glassMaterials.push('SAPPHIRE_COATED')
            if (_.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE_COATED', filter: 'Glass Type' }) < 0) $rootScope.selectdFilter.push({ name: 'SAPPHIRE_COATED', filter: 'Glass Type' })
          } else if (glassMaterials.indexOf('SAPPHIRE_COATED') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SAPPHIRE_COATED'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE_COATED', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SAPPHIRE_COATED', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.glassMaterial.crystal) {
            glassMaterials.push('CRYSTAL')
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CRYSTAL', filter: 'Glass Type' })
            if (index < 0) $rootScope.selectdFilter.push({ name: 'CRYSTAL', filter: 'Glass Type' })
          } else if (glassMaterials.indexOf('CRYSTAL') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('CRYSTAL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CRYSTAL', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CRYSTAL', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.glassMaterial.mineral) {
            glassMaterials.push('MINERAL')
            var index = _.findIndex($rootScope.selectdFilter, { name: 'MINERAL', filter: 'Glass Type' })
            if (index < 0) $rootScope.selectdFilter.push({ name: 'MINERAL', filter: 'Glass Type' })
          } else if (glassMaterials.indexOf('MINERAL') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('MINERAL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'MINERAL', filter: 'Glass Type' })
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'MINERAL', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.glassMaterial.syntheticPlastic) {
            glassMaterials.push('SYNTHETIC_PLASTIC')
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SYNTHETIC_PLASTIC', filter: 'Glass Type' })
            if (index < 0) $rootScope.selectdFilter.push({ name: 'SYNTHETIC_PLASTIC', filter: 'Glass Type' })
          } else if (glassMaterials.indexOf('SYNTHETIC_PLASTIC') != -1) {
            glassMaterials.splice(glassMaterials.indexOf('SYNTHETIC_PLASTIC'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SYNTHETIC_PLASTIC', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'SYNTHETIC_PLASTIC', filter: 'Glass Type' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }
        }

        var filter

        if (glassMaterials.length == 0) {
          // glassMaterials = ['SAPPHIRE', 'SAPPHIRE_COATED', 'CRYSTAL', 'MINERAL', 'SYNTHETIC_PLASTIC'];
        }

        filter['watch.glassMaterial'] = { $in: glassMaterials }

        console.log('Hey product handling guy How are you', filter)
        $scope.$emit('glass_material_filter_directive', { filter: filter })
      }
    }]
  }
})
