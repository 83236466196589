prismanoteApp.directive('productSort', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-sort.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      function loadDefaults () {
        $scope.limit = 32
        // $scope.defaults = [
        // 		{suggestedRetailPrice: 1 },
        // 		{suggestedRetailPrice:-1 },
        // 		{uploadDate: -1 },
        // 		{productNumber: -1},
        // ]
      }

      loadDefaults()

      // TODO fix price asc/desc in retailer portal
      $scope.sortProducts = function (index) {
        console.log('sortByProduct', index)
        if (index != null && index != undefined) $scope.$emit('sort_filter_directive', { sort: index })
      }

      $scope.setLimit = function (limit) {
        $scope.$emit('limit_filter_directive', { limit: limit })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        delete $scope.sortBy
        $scope.sortBy = $scope.defaults[3]
        $scope.limit = 32
      })

      $scope.$on('clear-the-sort-filter', function (event, params) {
        delete $scope.sortBy
        $scope.sortBy = $scope.defaults[3]
        $scope.limit = 32
      })
    }]
  }
})
