prismanoteApp.directive('productFilterByDialNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-dial-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      var filter = {}
      var index = []

      $scope.setDial = function () {
        if ($scope.dial.arabic) {
          index.push('ARABIC')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ARABIC', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'ARABIC', filter: 'Dial Index' })
        } else if (index.includes('ARABIC')) {
          index.splice(index.indexOf('ARABIC'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ARABIC', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ARABIC', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        }

        if ($scope.dial.roman) {
          index.push('ROMAN')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ROMAN', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'ROMAN', filter: 'Dial Index' })
        } else if (index.includes('ROMAN')) {
          index.splice(index.indexOf('ROMAN'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ROMAN', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ROMAN', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        }

        if ($scope.dial.dots) {
          index.push('DOTS')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'DOTS', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'DOTS', filter: 'Dial Index' })
        } else if (index.includes('DOTS')) {
          index.splice(index.indexOf('DOTS'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'DOTS', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'DOTS', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        }

        if ($scope.dial.stripes) {
          index.push('STRIPES')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STRIPES', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'STRIPES', filter: 'Dial Index' })
        } else if (index.includes('STRIPES')) {
          index.splice(index.indexOf('STRIPES'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STRIPES', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STRIPES', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        }

        if ($scope.dial.diamonds) {
          index.push('REAL_DIAMONDS')
          index.push('ORNAMENTAL_DIAMONDS')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'REAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'REAL_DIAMONDS', filter: 'Dial Index' })

          var searchIndex2 = _.findIndex($rootScope.selectdFilter, { name: 'ORNAMENTAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex2 < 0) $rootScope.selectdFilter.push({ name: 'ORNAMENTAL_DIAMONDS', filter: 'Dial Index' })
        } else if (index.includes('REAL_DIAMONDS') && index.includes('ORNAMENTAL_DIAMONDS')) {
          index.splice(index.indexOf('REAL_DIAMONDS'))
          index.splice(index.indexOf('ORNAMENTAL_DIAMONDS'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'REAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          var searchIndex2 = _.findIndex($rootScope.selectdFilter, { name: 'ORNAMENTAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex2 > -1) $rootScope.selectdFilter.splice(searchIndex2, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'REAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          var searchIndex2 = _.findIndex($rootScope.selectdFilter, { name: 'ORNAMENTAL_DIAMONDS', filter: 'Dial Index' })
          if (searchIndex2 > -1) $rootScope.selectdFilter.splice(searchIndex2, 1)
        }

        if ($scope.dial.none) {
          index.push('NONE')
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'NONE', filter: 'Dial Index' })
          if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'NONE', filter: 'Dial Index' })
        } else if (index.includes('NONE')) {
          index.splice(index.indexOf('NONE'))
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'NONE', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        } else {
          var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'NONE', filter: 'Dial Index' })
          if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
        }

        if (index.length == 0) {
          index = ['DOTS', 'STRIPES', 'REAL_DIAMONDS', 'ORNAMENTAL_DIAMONDS', 'ARABIC', 'ROMAN', 'NONE']
        }

        filter['watch.dial.index'] = { $in: index }

        $scope.$emit('dial_index_filter_directive', { filter: filter })
      }
    }]
  }
})
