prismanoteApp.directive('productBlockRetailerHomePortal', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-block-retailer-home-portal.html',
    scope: false
    // controller: ['$scope',
    // 	function ($scope) {
    // 		$sco
    // 	}]
  }
})
