prismanoteApp.directive('productFilterByColor', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-color.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      var filter = { 'watch.dial.color': { $in: [] }, 'watch.strap.color': { $in: [] } }
      $scope.dialColors = [
        {
          tag: 'SILVER',
          hex: '#CACFD2'
        },
        {
          key: 'GREY',
          hex: '#4D5656'
        },
        {
          key: 'BLUE',
          hex: '#2980B9'
        },
        {
          key: 'WHITE',
          hex: '#FFF8DC'
        },
        {
          key: 'RED',
          hex: '#922B21'
        },
        {
          key: 'BLACK',
          hex: '#000000'
        },
        {
          key: 'ROSE_GOLD',
          hex: '#FFA07A'
        },
        {
          key: 'GOLD',
          hex: '#B8860B'
        },
        {
          key: 'MOTHER_OF_PEARL',
          hex: '#BC8F8F'
        },
        {
          key: 'BROWN',
          hex: '#8B4513'
        },
        {
          key: 'BEIGE',
          hex: '#CD853F'
        },
        {
          key: 'MOTHER_OF_PEARL_COLOURED',
          hex: '#BC8F8F'
        },
        {
          key: 'GOLDEN',
          hex: '#B8860B'
        },
        {
          key: 'GREEN',
          hex: '#228B22'
        },
        {
          key: 'PINK',
          hex: '#FF69B4'
        },
        {
          key: 'PURPLE',
          hex: '#663399'
        },
        {
          key: 'ORANGE',
          hex: '#FF5733'
        },
        {
          key: 'YELLOW',
          hex: '#FFD700'
        },
        {
          key: 'CREME',
          hex: '#F5DEB3'
        },
        {
          key: 'TAUPE',
          hex: '#D2B48C'
        },
        {
          key: 'BRASS',
          hex: '#CD853F'
        }
      ]

      $scope.strapColors = [
        {
          key: 'SILVER',
          hex: '#CACFD2'
        },
        {
          key: 'GREY',
          hex: '#4D5656'
        },
        {
          key: 'BLUE',
          hex: '#2980B9'
        },
        {
          key: 'WHITE',
          hex: '#FFF8DC'
        },
        {
          key: 'RED',
          hex: '#922B21'
        },
        {
          key: 'BLACK',
          hex: '#000000'
        },
        {
          key: 'ROSE_GOLD',
          hex: '#FFA07A'
        },
        {
          key: 'GOLD',
          hex: '#B8860B'
        },
        {
          key: 'BROWN',
          hex: '#8B4513'
        },
        {
          key: 'BEIGE',
          hex: '#CD853F'
        },
        {
          key: 'GOLDEN',
          hex: '#B8860B'
        },
        {
          key: 'GREEN',
          hex: '#228B22'
        },
        {
          key: 'PINK',
          hex: '#FF69B4'
        },
        {
          key: 'PURPLE',
          hex: '#663399'
        },
        {
          key: 'ORANGE',
          hex: '#FF5733'
        },
        {
          key: 'YELLOW',
          hex: '#FFD700'
        },
        {
          key: 'CREME',
          hex: '#F5DEB3'
        },
        {
          key: 'TAUPE',
          hex: '#D2B48C'
        },
        {
          key: 'BRASS',
          hex: '#CD853F'
        }
      ]

      Initialization()

      function Initialization () {
        // alert('init');
        for (var i = 0; i < $scope.dialColors.length; i++) {
          filter['watch.dial.color'].$in.push($scope.dialColors[i].key)
        }

        for (var j = 0; j < $scope.strapColors.length; j++) {
          filter['watch.strap.color'].$in.push($scope.strapColors[j].key)
        }
        for (var i = 0; i < $scope.dialColors.length; i++) {
          $scope.dialColors[i].selected = false
        }
        for (var j = 0; j < $scope.strapColors.length; j++) {
          $scope.strapColors[j].selected = false
        }
      }

      $scope.setDialColor = function () {
        filter['watch.dial.color'].$in = []

        for (var i = 0; i < $scope.dialColors.length; i++) {
          if ($scope.dialColors[i].selected) {
            filter['watch.dial.color'].$in.push($scope.dialColors[i].key)
          }
        }

        if (filter['watch.dial.color'].$in.length == 0) {
          for (var j = 0; j < $scope.dialColors.length; j++) {
            filter['watch.dial.color'].$in.push($scope.dialColors[j].key)
          }
        }

        $scope.$emit('color_filter_directive', { filter: filter })
      }

      $scope.setStrapColor = function () {
        filter['watch.strap.color'].$in = []

        for (var i = 0; i < $scope.strapColors.length; i++) {
          if ($scope.strapColors[i].selected) {
            filter['watch.strap.color'].$in.push($scope.strapColors[i].key)
          }
        }

        if (filter['watch.strap.color'].$in.length == 0) {
          for (var j = 0; j < $scope.strapColors.length; j++) {
            filter['watch.strap.color'].$in.push($scope.strapColors[j].key)
          }
        }

        $scope.$emit('color_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        Initialization()
      })

      $scope.$on('clear-the-color-filter', function (event, params) {
        Initialization()
      })
    }]
  }
})
