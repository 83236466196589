prismanoteApp.directive('brandShopSettings', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/brand-shop-settings.html',
        scope: {
            'shop': '=abc',
        },
        controller: ['$scope', '$rootScope', '$brandShopSettings', '$uibModal', '$retailer', 'prompt',
            function ($scope, $rootScope, $brandShopSettings, $uibModal, $retailer, prompt) {
                $scope.searchBrandURL = window.location.origin + "/api/brandsSearch/" + $rootScope.currentShop._id + "/";
                $scope.titleField = $rootScope.language + '.name';
                // Below code if for whenever shop changed then also we need to changed to shop.brand
                $scope.$on('changeSelectedShop', function () {
                    if ($scope.shop && $scope.shop.nameSlug && $rootScope.currentShop && $rootScope.currentShop.nameSlug &&
                        $scope.shop.nameSlug !== $rootScope.currentShop.nameSlug) {
                        $scope.shop = {
                            _id: $rootScope.currentShop._id,
                            nameSlug: $rootScope.currentShop.nameSlug
                        }
                        $scope.getShopBrandsByShopId();
                    }
                });

                $scope.data = {
                    language: $rootScope.language
                }

                // Getting All the Brands which is verified and the Brand which is created by current user(i.e. Private brand)
                $scope.getBrands = function () {
                    /// enabling this code will break the brand settings page
                    // $brandShopSettings.getBrands().then(function (brandRes) {
                    //     $scope.brands = brandRes.data.brands;
                    // }).catch(function (brandErr) {
                    //     console.error("Error while getting brands", brandErr);
                    // })
                    return;
                };

                $scope.responseFormatter = function(response){
                    if(response.brands && response.brands.length == 0){
                        var addNewCollectionItem = [{
                            en: { 
                                name: " ➕ Add new brand"
                            },
                            nl: {
                                name: " ➕ Nieuw merk toevoegen"
                            },
                            es: {
                                name: " ➕ Agregar nueva marca"
                            },
                            fr: {
                                name: " ➕ Agregar nueva marca"
                            },
                            de: {
                                name: " ➕ Neue Marke hinzufügen"
                            }
                        }];
                        response.brands = addNewCollectionItem;
                    }
                    return response;
                }

                function searchShopBrands(str) {
                    if (str && str.length > 2) {
                        $scope.shopBrands = $brandShopSettings.searchBrands($scope.originalShopBrands, str);
                    } else {
                        $scope.shopBrands = JSON.parse(JSON.stringify($scope.originalShopBrands));
                    }
                }

                // Searching the brand to add in the shop
                $scope.searchBrands = function (str) {
                    searchShopBrands(str);
                    return $brandShopSettings.searchBrands($scope.brands, str);
                };

                // Open Modal to create new brand, in modal if user selected add brand into the shop then we will add it here
                $scope.openModalToCreateNewBrand = function (shopSlug) {
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/add-brands-modal.html',
                        controller: 'addBrandsModalController',
                        size: 'lg',
                        resolve: {
                            data: function () {
                                return shopSlug;
                            }
                        }
                    });

                    modalInstance.result.then(function (result) {
                        if (result && result.status == 'addBrandToShop') {
                            var brand = {
                                originalObject: result.brand
                            }
                            $scope.brandForAdd(brand);
                        }
                    }, function (err) {
                        console.error('openModalToCreateNewBrand error: in directive', err);
                    })
                };

                // Get Current Shop All the brand
                $scope.getShopBrandsByShopId = function () {
                    $brandShopSettings.getShopBrandsByShopId({ shopId: $scope.shop._id }).then(function (shopBrandsRes) {
                        $scope.shopBrands = [];
                        $scope.originalShopBrands = [];
                        if (shopBrandsRes && shopBrandsRes.data && shopBrandsRes.data.data && shopBrandsRes.data.data.length) {
                            var shopBrandsTemp = _.orderBy(shopBrandsRes.data.data, ['en.name'], ['asc']);
                            $scope.shopBrands = shopBrandsTemp;
                            $scope.originalShopBrands = JSON.parse(JSON.stringify($scope.shopBrands));
                        }
                    }).catch(function (shopBrandsErr) {
                        console.error('getShopBrandsByShopId Error', shopBrandsErr);
                    });
                }

                // Opening Shop Brand Modal
                $scope.openShopBrandModal = function (brand, isEditingBrand) {
                    var shopModalData = {
                        brandId: brand._id,
                        brandSlug: brand.en && brand.en.nameSlug ? brand.en.nameSlug : brand.nameSlug,
                        shopId: $rootScope.currentShop._id,
                        isEditingBrnad: isEditingBrand,
                        isBrandExistInShop: isEditingBrand, // If brand exist in shop then we need shop.brand detail as well, (Use for API)
                    }
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/retailer-add-company-as-per-brand-modal.html',
                        controller: 'retailerAddCompanyAsPerBrandModalController',
                        size: 'lg',
                        resolve: {
                            shopModalData: function () {
                                return shopModalData;
                            }
                        }
                    });

                    modalInstance.result.then(function (response) {
                        if ($rootScope.isRegistrationProcess) {
                            $scope.getShopBrandsByShopId();
                        } else if (response === 'brandupdated' || response === 'brandadded') {
                            $rootScope.$emit('$translateChangeSuccess');
                        }
                    }).catch(function (reason) {
                        console.error('reason brand modal: ', reason);
                        // reject(reason);
                    });
                }

                $scope.deleteBrand = function (index, brand) {
                    $scope.brandLoader = true;
                    var modalInstance = $uibModal.open({
                        templateUrl: '../views/modal/remove-products-modal.html',
                        controller: 'removeProductsModalController',
                        resolve: {
                            brand: function () {
                                return brand._id;
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        var brandBody = {
                            brand: {
                                _id: brand._id
                            },
                            shopId: $rootScope.currentShop._id,
                            language: $rootScope.language
                        }
                        $brandShopSettings.deleteBrandInShop(brandBody).then(function (success) {
                            $scope.brandLoader = false;
                            $scope.shopBrands.splice(index, 1);
                            $retailer.getShop().then(function (shop) {
                                $rootScope.currentShop = shop;
                            }).catch(function (error) {
                                $scope.brandLoader = false;
                                console.error('error: ', error);
                            });
                        }).catch(function (error) {
                            $scope.brandLoader = false;
                            console.error('error: ', error);
                        });
                    }).catch(function (reason) {
                        $scope.brandLoader = false;
                    });
                };

                $scope.brandForAdd = function (selected) {
                    var isBrandAlreadyExist = false;
                    var BreakForEach = {};
                    try {
                        angular.forEach($scope.shopBrands, function (val) {
                            if (val._id == selected.originalObject._id) {
                                isBrandAlreadyExist = true;
                                throw BreakForEach;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakForEach) {
                            console.error(e);
                        }
                    }


                    if (isBrandAlreadyExist) {
                        prompt({
                            title: 'Brand already exist',
                            message: 'Brand already added in the shop'
                        }).then(function (success) {
                            // do nothing its just a notification
                            $scope.brandLoader = false;
                        }).catch(function (err) {
                            console.error('Something went wrong', err);
                        })
                    } else {
                        if(selected.title === " ➕ Add new brand" || selected.title === " ➕ Nieuw merk toevoegen" || selected.title === " ➕ Agregar nueva marca" || selected.title === " ➕ Agregar nueva marca" || selected.title === " ➕ Neue Marke hinzufügen"){
                            $scope.openModalToCreateNewBrand($scope.shop.nameSlug);
                        } else {
                            var brand = {
                                _id: selected.originalObject._id,
                                name: selected.title || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].name ? selected.originalObject[$rootScope.language].name : selected.originalObject.name),
                                nameSlug: selected.originalObject.nameSlug || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].nameSlug ? selected.originalObject[$rootScope.language].nameSlug : selected.originalObject.nameSlug),
                                description: selected.originalObject.description || (selected.originalObject[$rootScope.language] && selected.originalObject[$rootScope.language].description ? selected.originalObject[$rootScope.language].description : ''),
                                images: selected.originalObject.images,
                                restricted: selected.originalObject.restricted,
                                privateLabel: selected.originalObject.privateLabel,
                                isPoolArticleBrand: selected.originalObject.isPoolArticleBrand
                            }

                            $scope.openShopBrandModal(brand, false);
                        }
                    }
                }
            }]
    }
});
