prismanoteApp.directive('productFilterByStrapTypeNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-type-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {
      $scope.STANDARD_MODEL = false
      $scope.MILANESE_MESH = false
      $scope.NATO = false
      $scope.EXPANDABLE_STRETCH_STRAP = false
      $scope.SELECT_BAND = false
      $scope.CHAIN_OF_A_POCKET_WATCH = false
      $scope.SELECT_BAND_FOR_POCKET_WATCH = false
      $scope.PLASTIC_SILICONE = false

      function getCurrentStrapType () {
        $retailerFilters.getStrapTypeFilterFilter()
          .then(function (starpFilter) {
            // console.log('Strap Filter', starpFilter)
            starpFilter = JSON.parse(starpFilter)
            $scope.strapType = {}
            if (starpFilter && starpFilter['watch.strap.model'] && starpFilter['watch.strap.model'].$in && starpFilter['watch.strap.model'].$in.length) {
              for (var i = 0; i < starpFilter['watch.strap.model'].$in.length; i++) {
                if (starpFilter['watch.strap.model'].$in[i] == 'STANDARD_MODEL') $scope.strapType.standardModel = true
                if (starpFilter['watch.strap.model'].$in[i] == 'MILANESE_MESH') $scope.strapType.milaneseMesh = true
                if (starpFilter['watch.strap.model'].$in[i] == 'NATO') $scope.strapType.nato = true
                if (starpFilter['watch.strap.model'].$in[i] == 'EXPANDABLE_STRETCH_STRAP') $scope.strapType.expandableStretch = true
                if (starpFilter['watch.strap.model'].$in[i] == 'CHAIN_OF_A_POCKET_WATCH') $scope.strapType.chainOfPocketWatch = true
                if (starpFilter['watch.strap.model'].$in[i] == 'SELECT_BAND') $scope.strapType.select = true

                $scope.setStrapType('localStorage')
              }
            } else {
              $scope.setStrapType('localStorage')
            }
          })
          .catch(function (errror) {
            console.log('Error', error)
          })
      }

      getCurrentStrapType()

      $scope.$on('strap_type_filter_remove', function (event, sort) {
        console.log('$sort =======>', sort)
        var remove = sort.removeFilter

        if (remove.name === 'STANDARD MODEL') {
          $scope.strapType.standardModel = false
          $scope.setStrapType()
        } else if (remove.name == 'MILANESE MESH') {
          $scope.strapType.milaneseMesh = false
          $scope.setStrapType()
        } else if (remove.name === 'NATO') {
          $scope.strapType.nato = false
          $scope.setStrapType()
        } else if (remove.name === 'EXPANDABLE STRETCH STRAP') {
          $scope.strapType.expandableStretch = false
          $scope.setStrapType()
        } else if (remove.name === 'CHAIN OF A POCKET WATCH') {
          $scope.strapType.chainOfPocketWatch = false
          $scope.setStrapType()
        } else if (remove.name === 'SELECT BAND') {
          $scope.strapType.select = false
          $scope.setStrapType()
        }
      })

      $scope.setStrapType = function (status) {
        var filter = { 'watch.strap.model': {} }
        var strapTypes = []

        if ($scope.strapType) {
          if ($scope.strapType.standardModel) {
            strapTypes.push('STANDARD_MODEL')
            var temp = { name: 'STANDARD MODEL', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('STANDARD_MODEL')) {
            strapTypes.splice(strapTypes.indexOf('STANDARD_MODEL'))
            var temp = { name: 'STANDARD MODEL', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'STANDARD MODEL', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.strapType.milaneseMesh) {
            strapTypes.push('MILANESE_MESH')
            var temp = { name: 'MILANESE MESH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('MILANESE_MESH')) {
            strapTypes.splice(strapTypes.indexOf('MILANESE_MESH'))
            var temp = { name: 'MILANESE MESH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'MILANESE MESH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.strapType.nato) {
            strapTypes.push('NATO')
            var temp = { name: 'NATO', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('NATO')) {
            strapTypes.splice(strapTypes.indexOf('NATO'))
            var temp = { name: 'NATO', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'NATO', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.strapType.expandableStretch) {
            strapTypes.push('EXPANDABLE_STRETCH_STRAP')
            var temp = { name: 'EXPANDABLE STRETCH STRAP', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('EXPANDABLE_STRETCH_STRAP')) {
            strapTypes.splice(strapTypes.indexOf('EXPANDABLE_STRETCH_STRAP'))
            var temp = { name: 'EXPANDABLE STRETCH STRAP', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'EXPANDABLE STRETCH STRAP', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.strapType.chainOfPocketWatch) {
            strapTypes.push('CHAIN_OF_A_POCKET_WATCH')
            var temp = { name: 'CHAIN OF A POCKET WATCH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('CHAIN_OF_A_POCKET_WATCH')) {
            strapTypes.splice(strapTypes.indexOf('CHAIN_OF_A_POCKET_WATCH'))
            var temp = { name: 'CHAIN OF A POCKET WATCH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'CHAIN OF A POCKET WATCH', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if ($scope.strapType.select) {
            strapTypes.push('SELECT_BAND')
            strapTypes.push('SELECT_BAND_FOR_POCKET_WATCH')
            var temp = { name: 'SELECT BAND', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index < 0) {
              $rootScope.selectdFilter.push(temp)
            }
          } else if (strapTypes.includes('SELECT_BAND')) {
            strapTypes.splice(strapTypes.indexOf('SELECT_BAND'))
            strapTypes.splice(strapTypes.indexOf('SELECT_BAND_FOR_POCKET_WATCH'))
            var temp = { name: 'SELECT BAND', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          } else {
            var temp = { name: 'SELECT BAND', filter: 'strap type' }
            var index = _.findIndex($rootScope.selectdFilter, temp)
            if (index > -1) {
              $rootScope.selectdFilter.splice(index, 1)
            }
          }

          if (strapTypes.length == 0) {
            strapTypes = []
            // strapTypes = ['EXPANDABLE_STRETCH_STRAP', 'NATO', 'MILANESE_MESH', 'STANDARD_MODEL', 'SELECT_BAND', 'SELECT_BAND_FOR_POCKET_WATCH', 'CHAIN_OF_A_POCKET_WATCH'];
          }

          filter['watch.strap.model'] = { $in: strapTypes }

          $retailerFilters.setStrapTypeFilter(filter)

          if (status && status == 'localStorage') filter.status = status

          $scope.$emit('strap_model_filter_directive', { filter: filter })
        } else {
          filter['watch.strap.model'] = { $in: strapTypes }

          $retailerFilters.setStrapTypeFilter(filter)

          if (status && status == 'localStorage') filter.status = status

          $scope.$emit('strap_model_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
