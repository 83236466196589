prismanoteApp.directive('productFilterByCaseMaterialNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-material-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.setCaseMaterial = function () {
        var filter = { 'watch.case.material': {} }
        var caseMaterials = []

        if ($scope.caseMaterial) {
          if ($scope.caseMaterial.wood) {
            caseMaterials.push('WOOD')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'WOOD', filter: 'Case Material' })
          } else if (caseMaterials.includes('WOOD')) {
            caseMaterials.splice(caseMaterials.indexOf('WOOD'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'WOOD', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.stainlessSteel) {
            caseMaterials.push('STAINLESS_STEEL')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'STAINLESS_STEEL', filter: 'Case Material' })
          } else if (caseMaterials.includes('STAINLESS_STEEL')) {
            caseMaterials.splice(caseMaterials.indexOf('STAINLESS_STEEL'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.titanium) {
            caseMaterials.push('TITANIUM')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'TITANIUM', filter: 'Case Material' })
          } else if (caseMaterials.includes('TITANIUM')) {
            caseMaterials.splice(caseMaterials.indexOf('TITANIUM'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.aluminium) {
            caseMaterials.push('ALUMINIUM')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'ALUMINIUM', filter: 'Case Material' })
          } else if (caseMaterials.includes('ALUMINIUM')) {
            caseMaterials.splice(caseMaterials.indexOf('ALUMINIUM'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.metal) {
            caseMaterials.push('METAL')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'METAL', filter: 'Case Material' })
          } else if (caseMaterials.includes('METAL')) {
            caseMaterials.splice(caseMaterials.indexOf('METAL'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.plasticSilicon) {
            caseMaterials.push('PLASTIC_SILICONE')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'PLASTIC_SILICONE', filter: 'Case Material' })
          } else if (caseMaterials.includes('PLASTIC_SILICONE')) {
            caseMaterials.splice(caseMaterials.indexOf('PLASTIC_SILICONE'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.ceramics) {
            caseMaterials.push('CERAMICS')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'CERAMICS', filter: 'Case Material' })
          } else if (caseMaterials.includes('CERAMICS')) {
            caseMaterials.splice(caseMaterials.indexOf('CERAMICS'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.gold) {
            caseMaterials.push('GOLD')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'GOLD', filter: 'Case Material' })
          } else if (caseMaterials.includes('GOLD')) {
            caseMaterials.splice(caseMaterials.indexOf('GOLD'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if ($scope.caseMaterial.palladium) {
            caseMaterials.push('PALLADIUM')
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Case Material' })
            if (searchIndex < 0) $rootScope.selectdFilter.push({ name: 'PALLADIUM', filter: 'Case Material' })
          } else if (caseMaterials.includes('PALLADIUM')) {
            caseMaterials.splice(caseMaterials.indexOf('PALLADIUM'))
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          } else {
            var searchIndex = _.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Case Material' })
            if (searchIndex > -1) $rootScope.selectdFilter.splice(searchIndex, 1)
          }

          if (caseMaterials.length == 0) caseMaterials = []

          filter['watch.case.material'] = { $in: caseMaterials }

          $scope.$emit('case_material_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
