prismanoteApp.directive('productFilterByBrand', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-filter-by-brand.html',
    controller: ['$rootScope', '$scope', '$state', '$api', '$retailer', '$stateParams', '$filter', function ($rootScope, $scope, $state, $api, $retailer, $stateParams, $filter) {
      $scope.customBrandFilter = []
      $scope.filterBrands = []
      $scope.selectedBrands = []

      $scope.getMyBrandClass = function (brandId) {
        // return $.inArray(brandId,$scope.filterChecker.brandsInProds)?'' : 'bg-danger';
        return ''
      }
      var filter = { 'brand.nameSlug': { $in: [] } }

      $scope.brandExistInProducts = function () {
        return 'bg-success'
      }
      /* Reload products if the brand selection has changed. */

      $scope.setBrand = function (selectBrandId) {
        console.log('selectBrandId: ', selectBrandId, $scope.filterBrands)
        $scope.selectedBrands = []

        $scope.filterBrands.map(function (element) {
          if ((element._id) && element.status) {
            console.log(element._id, element.status)
            // element.selected = true;
            $scope.selectedBrands.push(element._id)
          } else {

          }
        })

        console.log('SELECTED BRANDS: ', $scope.selectedBrands)
        /* Add selected brands to filter. If no brands are selected, add all brands to the filter, but leave selected brands empty for next time this function is called (when a brand is selected again). */

        if ($scope.selectedBrands.length == 0) {
          filter['brand.nameSlug'].$in = []
        } else {
          filter['brand.nameSlug'].$in = $scope.selectedBrands
        }

        $scope.$emit('brand_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        for (var i = 0; i < $scope.filterBrands.length; i++) {
          $scope.filterBrands[i].selected = false
        }
      })

      $scope.$on('clear-the-brand-filter', function (event, params) {
        for (var i = 0; i < $scope.filterBrands.length; i++) {
          $scope.filterBrands[i].selected = false
        }
      })

      $scope.setFilterBrands = function (shop) {
        /* Default params for the filter brands that will be used: */
        /* Ignore brands without products; only return the most elementary details instead of the full object; don't limit; sort alphabetically. */
        var brandParams = {
          hasProducts: true,
          short: true,
          limit: undefined,
          sort: {
            name: 'asc'
          }
        }

        if (shop != null) {
          /* Get shop brands. */
          var brands = shop.brands || []

          /* Use only filterBrands that this shop actually has. */
          brandParams.filter = {
            nameSlug: {
              $in: brands.map(function (brand) { return brand.nameSlug })
            }
          }
        }

        /* Get the matching selectable filter brands from the db (only include brands with actual products). */
        $api.get('brands?getAll=true')
          .then(function (response) {
            $scope.filterBrands = $filter('orderBy')(response.data.brands, 'name')
            // $scope.filterBrandsLength = $scope.filterBrands.length;
            // for(var i = 0; i < $scope.filterBrandsLength; i++){
            // 	$scope.filterBrands[i]['status'] = false;
            // }

            // filter["brand.nameSlug"].$in = $scope.filterBrands.map(function (brand) { return brand.nameSlug; });
          })

          .catch(function (reason) {
            console.error(reason)
          })
      }

      /* Find out which brands to include in the filter (in the retailer portal). */
      if ($state.includes('retailer')) {
        $retailer.getShop()

          .then(function (shop) {
            $scope.setFilterBrands(shop)
          })

          .catch(function (reason) {
            console.error(reason)
          })

        /* Find out which brands to include in the filter (in the shop/proshop). */
      } else if ($state.includes('shop') || $state.includes('proshop')) {
        // get the shop brands to filter brands which are not on the shop
        var nameSlug = $stateParams.nameSlug
        $api.get('shops/' + nameSlug)
          .then(function (response) {
            $scope.setFilterBrands(response.data.shop)
          })
          .catch(function (reason) {
            $scope.setFilterBrands()
          })

        $scope.setFilterBrands()

        /* Find out which brands to include in the filter (in the PrismaNote general products overview or other places where this filter is used). */
      } else {
        $scope.setFilterBrands()
      }
    }]
  }
})
