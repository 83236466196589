prismanoteApp.directive('productPrice', ['$parse', function ($parse) {
  return {
    restrict: 'A',
    require: '^ngModel',
    scope: {
      modelValue: '=ngModel'
    },
    link: function (scope, element, attrs, ngModelCtrl) {
      var newValue = scope.modelValue * 1.21

      ngModelCtrl.$viewValue = newValue
      ngModelCtrl.$render()
      ngModelCtrl.$modelValue = newValue
      scope.modelValue = newValue
    }
  }
}])
