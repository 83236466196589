prismanoteApp.directive('productFilterByCategory', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-category.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setCategory = function () {
        var filter = { category: {} }
        var categories = []

        if ($scope.category) {
          if ($scope.category.watch) {
            categories.push('WATCH')
          } else if (categories.includes('WATCH')) {
            categories.splice(categories.indexOf('WATCH'))
          }

          if ($scope.category.strap) {
            categories.push('STRAP')
          } else if (categories.includes('STRAP')) {
            categories.splice(categories.indexOf('STRAP'))
          }

          if ($scope.category.jewel) {
            categories.push('JEWEL')
          } else if (categories.includes('JEWEL')) {
            categories.splice(categories.indexOf('JEWEL'))
          }

          if ($scope.category.other) {
            categories.push('OTHER')
          } else if (categories.includes('OTHER')) {
            categories.splice(categories.indexOf('OTHER'))
          }

          if (categories.length == 0) {
            categories = ['WATCH', 'JEWEL', 'STRAP', 'OTHER']
          }

          // filter['category']['$in'] = categories;
          // console.log('FILTER: ', filter);

          $scope.$emit('category_filter_directive', { categories: categories })
          // $scope.getProducts({filter: filter}, {reset: true})
          // .catch(function(reason) {
          // 	console.error(reason);
          // });
        }
      }
    }]
  }
})
