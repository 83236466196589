prismanoteApp.directive('productFilterBySupplier', function () {
  return {
    restrict: 'E',
    replace: 'true',
    scope: false,
    templateUrl: '../views/directives/product-filter-by-supplier.html',
    controller: ['$rootScope', '$scope', '$state', '$api', '$retailer', '$stateParams', function ($rootScope, $scope, $state, $api, $retailer, $stateParams) {
      $scope.customSupplierFilter = []
      $scope.filterSuppliers = []
      $scope.selectedSuppliers = []

      $scope.getMySupplierClass = function (brandId) {
        // return $.inArray(brandId,$scope.filterChecker.brandsInProds)?'' : 'bg-danger';
        return ''
      }
      var filter = { supplier: { $in: [] } }

      $scope.brandExistInProducts = function () {
        return 'bg-success'
      }
      /* Reload products if the brand selection has changed. */
      $scope.setSupplier = function (selectedSuppplerId) {
        /* Update the brand selection based on the selected checkboxes. */
        // $scope.selectedSuppliers = $scope.filterSuppliers.filter(function (filtersupplier) { return filtersupplier.selected; });

        $scope.filterSuppliers.map(function (element) {
          console.log(element._id, element.selected)
          if ((element._id).toString() == (selectedSuppplerId).toString()) {
            element.selected = true
            $scope.selectedSuppliers.push(element)
          }
        })

        /* Add selected brands to filter. If no brands are selected, add all brands to the filter, but leave selected brands empty for next time this function is called (when a brand is selected again). */
        if ($scope.selectedSuppliers.length == 0) {
          filter.supplier.$in = []
        } else {
          filter.supplier.$in = $scope.selectedSuppliers.map(function (supplier) { return supplier._id })
        }
        console.log('Supplier filter directives', filter)
        $scope.$emit('supplier_filter_directive', { filter: filter })
      }

      $scope.$on('clear-the-filter', function (event, params) {
        for (var i = 0; i < $scope.filterSuppliers.length; i++) {
          $scope.filterSuppliers[i].selected = false
        }
      })

      $scope.$on('clear-the-supplier-filter', function (event, params) {
        for (var i = 0; i < $scope.filterSuppliers.length; i++) {
          $scope.filterSuppliers[i].selected = false
        }
      })

      $scope.setFilterSuppliers = function (shop) {
        if ($rootScope && $rootScope.currentShop) {
          /* Get the matching selectable filter brands from the db (only include brands with actual products). */
          $api.get('get-all-shop-companies/' + $rootScope.currentShop.nameSlug)
            .then(function (response) {
              $scope.filterSuppliers = response.data.companies
              console.log('filtersuppliers', $scope.filterSuppliers)
              // filter["supplier"] = $scope.filterSuppliers.map(function (supplier) { return supplier.nameSlug; });
              // console.log('filter["supplier"]' , filter["supplier"]);
            })

            .catch(function (reason) {
              console.error(reason)
            })
        }

        /* Default params for the filter brands that will be used: */
        /* Ignore brands without products; only return the most elementary details instead of the full object; don't limit; sort alphabetically. */
        // var brandParams = {
        // 	hasProducts: true,
        // 	short: true,
        // 	limit: undefined,
        // 	sort: {
        // 		name: 'asc'
        // 	}
        // };

        // if (shop != null) {

        // 	/* Get shop brands. */
        // 	var brands = shop.companies || [];

        // 	/* Use only filterBrands that this shop actually has. */
        // 	companiesParams.filter = {
        // 		nameSlug: {
        // 			$in: brands.map(function (brand) { return brand.nameSlug; })
        // 		}
        // 	};
        // }

        // if ($rootScope && $rootScope.currentShop) {
        // 	/* Get the matching selectable filter brands from the db (only include brands with actual products). */
        // 	$api.get("brands/getshopbrands/"+$rootScope.currentShop.nameSlug)
        // 	.then(function (response) {
        // 		$scope.filterBrands = response.data.brands;
        // 		filter["brand.nameSlug"].$in = $scope.filterBrands.map(function (brand) { return brand.nameSlug; });
        // 	})

        // 	.catch(function (reason) {
        // 		console.error(reason);
        // 	});
        // } else {
        // 	/* Get the matching selectable filter brands from the db (only include brands with actual products). */
        // 	$api.get("brands?getAll=true")
        // 	.then(function (response) {
        // 		$scope.filterBrands = response.data.brands;
        // 		filter["brand.nameSlug"].$in = $scope.filterBrands.map(function (brand) { return brand.nameSlug; });
        // 	})

        // 	.catch(function (reason) {
        // 		console.error(reason);
        // 	});
        // }
      }

      /* Find out which brands to include in the filter (in the retailer portal). */
      console.log('product handling guy')

      if ($state.includes('retailer')) {
        $retailer.getShop()

          .then(function (shop) {
            $scope.setFilterSuppliers(shop)
          })

          .catch(function (reason) {
            console.error(reason)
          })

        /* Find out which brands to include in the filter (in the shop/proshop). */
      } else if ($state.includes('shop') || $state.includes('proshop')) {
        // get the shop brands to filter brands which are not on the shop
        var nameSlug = $stateParams.nameSlug
        $api.get('shops/' + nameSlug)
          .then(function (response) {
            $scope.setFilterSuppliers(response.data.shop)
          })
          .catch(function (reason) {
            $scope.setFilterSuppliers()
          })

        $scope.setFilterSuppliers()

        /* Find out which brands to include in the filter (in the PrismaNote general products overview or other places where this filter is used). */
      } else {
        $scope.setFilterSuppliers()
      }
    }]
  }
})
