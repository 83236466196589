prismanoteApp.directive('productFilterByType', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-type.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setType = function () {
        var filter = { type: {} }
        var types = []

        if ($scope.type) {
          if ($scope.type.wrist) {
            types.push('WRIST')
          } else if (types.includes('WRIST')) {
            types.splice(types.indexOf('WRIST'))
          }

          if ($scope.type.nurse) {
            types.push('NURSE')
          } else if (types.includes('NURSE')) {
            types.splice(types.indexOf('NURSE'))
          }

          if ($scope.type.pocket) {
            types.push('POCKET')
          } else if (types.includes('POCKET')) {
            types.splice(types.indexOf('POCKET'))
          }

          if ($scope.type.smartwatch) {
            filter.isSmartwatch = true
          } else {
            filter.isSmartwatch = false
          }

          if (types.length == 0) {
            types = ['WRIST', 'POCKET', 'NURSE']
          }

          filter['watch.type'] = { $in: types }

          $scope.getProducts({ filter: filter }, { reset: true })

            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }]
  }
})
