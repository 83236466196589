prismanoteApp.directive('customCompanySearch', function () {
    return {
      restrict: 'E',
      replace: 'true',
      templateUrl: '../views/directives/custom-company-search.html',
      scope: {
        placeholderText: '@',
        companyType: '@',
        selectedObject: '=',
        closeModalInstance: '='
      },
      controller: [
        '$scope', '$rootScope', '$shop', '$state', '$q', '$language', '$api', '$uibModal',
        function ($scope, $rootScope, $shop, $state, $q, $language, $api, $uibModal) {
        // console.log($scope);
          $scope.wholesaler = {}
          $scope.searchValue = ''
          $scope.searchMode = 'contains'
          // console.log('MODAL OPEN ADD COMPANY');
          // console.log('searcvlaue: ', searchValue);
          $scope.findCompanies = function (value, searchMode) {
            //console.log('findCOmanies: ', value, searchMode);
            $scope.hits = [];
            $scope.userCountryCompanies = [];
            $scope.otherCountriesCompanies = [];
            if (value) {
              if (value.length < 3) return
              if ($rootScope.currentShop) {
                //console.log('Hello findCompanies: ', value, searchMode);
                var params = {
                  searchText: value,
                  searchMode: searchMode,
                  shopId: $rootScope.currentShop._id
                }
                if($scope.companyType){
                    params['type'] = $scope.companyType;
                }
                $api.get('webshop-companies-search-by-suggestion', params)
                  .then(function (response) {
                    if (response && response.data && response.data.data) {
                      sortByCountry(response.data.data);
                    }
                    // console.log('ebshop-companies-search-by-suggestio: ', response.data.data);
                    // $scope.hits = response.data.data
                  })
                  .catch(function (reason) {
                    console.error(reason)
                  })
              }
            }
          }


          /**
           * Here, we are, The country of the user language should be automatically sorted on top of the search results!
           *  Suppliers in other countries should only be shown after clicking on the 'Load more search results' button
           */
          function sortByCountry(companies) {
            var companiesLength = companies.length;
            var currentLanguage = ($rootScope.language ? $rootScope.language.toUpperCase() : undefined) || 'NL';
            setCountryName(currentLanguage);
            for (var i = 0; i < companiesLength; i++) {
              if (companies[i] && companies[i].address && companies[i].address.country === currentLanguage) {
                $scope.userCountryCompanies.push(companies[i]);
              } else {
                $scope.otherCountriesCompanies.push(companies[i]);
              }
            }
            //console.log('sortByCountry: ', $scope.userCountryCompanies, $scope.otherCountriesCompanies);
          }

          function setCountryName(lang) {
            if (lang === 'NL') {
              $scope.countryName = 'Netherlands';
            } else if (lang === 'ES') {
              $scope.countryName = 'Spain';
            }  else if (lang === 'FR') {
              $scope.countryName = 'France';
            }  else if (lang === 'DE') {
              $scope.countryName = 'Germany';
            }  else {
              $scope.countryName = 'English';
            }
          }

          $scope.clearSearch = function (){
            $scope.searchValue = '';
            $scope.hits = [];
            $scope.userCountryCompanies = [];
            $scope.otherCountriesCompanies = [];
          }

          $scope.addCompanyToShop = function (company) {
            $scope.selectedObject(company);
            $scope.searchValue = '';
            $scope.hits = [];
            $scope.userCountryCompanies = [];
            $scope.otherCountriesCompanies = [];
          }

          $scope.cancel = function (message) {
            var value = 'cancel';
            if (message) value = message;
            $scope.closeModalInstance(value);
          }
          // When so companies found then user can create new company with that
          $scope.addNewCompany = function () {
            //console.log('addnew Company called')
            $scope.cancel()
            $rootScope.companyNameFromAddCompModal = $scope.searchValue
            $scope.searchValue = ''
            $state.go('retailer.add-company')
          }
        }
      ]
    }
  }
)
