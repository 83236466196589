prismanoteApp.directive('productFilterByDial', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-dial.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      var filter = {}
      var index = []

      $scope.setDial = function () {
        if ($scope.dial.arabic) {
          index.push('ARABIC')
        } else if (index.includes('ARABIC')) {
          index.splice(index.indexOf('ARABIC'))
        }

        if ($scope.dial.roman) {
          index.push('ROMAN')
        } else if (index.includes('ROMAN')) {
          index.splice(index.indexOf('ROMAN'))
        }

        if ($scope.dial.dots) {
          index.push('DOTS')
        } else if (index.includes('DOTS')) {
          index.splice(index.indexOf('DOTS'))
        }

        if ($scope.dial.stripes) {
          index.push('STRIPES')
        } else if (index.includes('STRIPES')) {
          index.splice(index.indexOf('STRIPES'))
        }

        if ($scope.dial.diamonds) {
          index.push('REAL_DIAMONDS')
          index.push('ORNAMENTAL_DIAMONDS')
        } else if (index.includes('REAL_DIAMONDS') && index.includes('ORNAMENTAL_DIAMONDS')) {
          index.splice(index.indexOf('REAL_DIAMONDS'))
          index.splice(index.indexOf('ORNAMENTAL_DIAMONDS'))
        }

        if ($scope.dial.none) {
          index.push('NONE')
        } else if (index.includes('NONE')) {
          index.splice(index.indexOf('NONE'))
        }

        if (index.length == 0) {
          index = ['DOTS', 'STRIPES', 'REAL_DIAMONDS', 'ORNAMENTAL_DIAMONDS', 'ARABIC', 'ROMAN', 'NONE']
        }

        filter['watch.dial.index'] = { $in: index }

        $scope.getProducts({ filter: filter }, { reset: true })

          .catch(function (reason) {
            console.error(reason)
          })
      }
    }]
  }
})
