prismanoteApp.directive('productFilterByCaseSize', function () {
	return {
		restrict: 'E',
		replace: 'true',
		templateUrl: '../views/directives/product-filter-by-case-size.html',
		scope: true,
		controller: ['$scope', function ($scope) {
			var filter = {};
			InitOfCaseSizeFilter();

			$scope.$on('clear-the-filter', function (event, params) {
				InitOfCaseSizeFilter();
			});

			$scope.$on('clear-the-case-size-filter', function (event, params) {
				InitOfCaseSizeFilter();
			});

			function InitOfCaseSizeFilter() {
				delete caseSize;
				$scope.caseSize = {
					min: 10,
					max: 60,
					options: {
						floor: 10,
						ceil: 60,
						step: 1,
						translate: function (value, sliderId, label) {
							switch (label) {
								case 'ceil':
									return value + " mm +";
								case 'model':
									return value + " mm";
								case 'high':
									if (value == 60) {
										return value + " mm +";
									} else {
										return value + " mm";
									}
								default:
									return value + " mm";
							}
						},
						onEnd: function () {
							if ($scope.caseSize.min >= 10) {
								if (filter['watch.case.size'] == undefined) {
									filter['watch.case.size'] = {};										
								}

								filter['watch.case.size']['$gte'] =  $scope.caseSize.min ;

								if ($scope.caseSize.max < 60) {
								
									filter['watch.case.size']['$lte'] = $scope.caseSize.max ;	
								}
							} else if ($scope.caseSize.max < 60) {
								filter['watch.case.size']['$lte'] =  $scope.caseSize.max ;
							}

							$scope.$emit('case_size_filter_directive', { filter: filter });

						}
					}
				};
			}

		}]
	};
});
