prismanoteApp.directive('customFilterByBrand', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-filter-by-brand.html',
        scope: {
            filter: '=filter',
        },
        controller: ['$scope', function ($scope) {

        }]
    }
})