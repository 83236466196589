prismanoteApp.directive('productFilterByStrapWidth', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-width.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      $scope.strapWidth = {
        min: 0,
        max: 30,
        options: {
          floor: 0,
          ceil: 30,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'ceil':
                return value + ' mm +'
              case 'model':
                return value + ' mm'
              case 'high':
                if (value == 30) {
                  return value + ' mm +'
                } else {
                  return value + ' mm'
                }
              default:
                return value + ' mm'
            }
          },
          onEnd: function () {
            if ($scope.strapWidth.min != 0) {
              filter['watch.strap.width'] = { $gte: $scope.strapWidth.min }
            }

            if ($scope.strapWidth.max < 30) {
              filter['watch.strap.width'] = { $lte: $scope.strapWidth.max }
            }

            if ($scope.strapWidth.min == 0 && $scope.strapWidth.max == 30) {
              filter['watch.strap.width'] = undefined
            } else if ($scope.strapWidth.min == 0) {
              filter['watch.strap.width'].$gte = undefined
            } else if ($scope.strapWidth.max == 30) {
              filter['watch.strap.width'].$lte = undefined
            }

            $scope.$emit('strap_width_filter_directive', { filter: filter })
          }
        }
      }
    }]
  }
})
