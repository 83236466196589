prismanoteApp.directive('productFilterByStrapType', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-type.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.STANDARD_MODEL = false
      $scope.MILANESE_MESH = false
      $scope.NATO = false
      $scope.EXPANDABLE_STRETCH_STRAP = false
      $scope.SELECT_BAND = false
      $scope.CHAIN_OF_A_POCKET_WATCH = false
      $scope.SELECT_BAND_FOR_POCKET_WATCH = false
      $scope.PLASTIC_SILICONE = false

      shopFiltersAvailbeOrNot = function () {
        if ($rootScope.shopFilters.watchStrapModel.indexOf('STANDARD_MODEL') != -1) $scope.STANDARD_MODEL = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('MILANESE_MESH') != -1) $scope.MILANESE_MESH = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('NATO') != -1) $scope.NATO = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('EXPANDABLE_STRETCH_STRAP') != -1) $scope.EXPANDABLE_STRETCH_STRAP = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('SELECT_BAND') != -1) $scope.SELECT_BAND = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('CHAIN_OF_A_POCKET_WATCH') != -1) $scope.CHAIN_OF_A_POCKET_WATCH = true

        if ($rootScope.shopFilters.watchStrapModel.indexOf('SELECT_BAND_FOR_POCKET_WATCH') != -1) $scope.SELECT_BAND_FOR_POCKET_WATCH = true
      }

      shopFiltersAvailbeOrNot()

      $scope.setStrapType = function () {
        var filter = { 'watch.strap.model': {} }
        var strapTypes = []

        if ($scope.strapType) {
          if ($scope.strapType.standardModel) {
            strapTypes.push('STANDARD_MODEL')
          } else if (strapTypes.includes('STANDARD_MODEL')) {
            strapTypes.splice(strapTypes.indexOf('STANDARD_MODEL'))
          }

          if ($scope.strapType.milaneseMesh) {
            strapTypes.push('MILANESE_MESH')
          } else if (strapTypes.includes('MILANESE_MESH')) {
            strapTypes.splice(strapTypes.indexOf('MILANESE_MESH'))
          }

          if ($scope.strapType.nato) {
            strapTypes.push('NATO')
          } else if (strapTypes.includes('NATO')) {
            strapTypes.splice(strapTypes.indexOf('NATO'))
          }

          if ($scope.strapType.expandableStretch) {
            strapTypes.push('EXPANDABLE_STRETCH_STRAP')
          } else if (strapTypes.includes('EXPANDABLE_STRETCH_STRAP')) {
            strapTypes.splice(strapTypes.indexOf('EXPANDABLE_STRETCH_STRAP'))
          }

          if ($scope.strapType.select) {
            strapTypes.push('SELECT_BAND')
            strapTypes.push('SELECT_BAND_FOR_POCKET_WATCH')
          } else if (strapTypes.includes('SELECT_BAND')) {
            strapTypes.splice(strapTypes.indexOf('SELECT_BAND'))
            strapTypes.splice(strapTypes.indexOf('SELECT_BAND_FOR_POCKET_WATCH'))
          }

          if (strapTypes.length == 0) {
            strapTypes = ['EXPANDABLE_STRETCH_STRAP', 'NATO', 'MILANESE_MESH', 'STANDARD_MODEL', 'SELECT_BAND', 'SELECT_BAND_FOR_POCKET_WATCH', 'CHAIN_OF_A_POCKET_WATCH']
          }

          filter['watch.strap.model'] = { $in: strapTypes }

          $scope.$emit('strap_model_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
