prismanoteApp.directive('productFilterByCaseMaterial', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-material.html',
    scope: false,
    controller: ['$scope', function ($scope) {
      $scope.setCaseMaterial = function () {
        var filter = { 'watch.case.material': {} }
        var caseMaterials = []

        if ($scope.caseMaterial) {
          if ($scope.caseMaterial.wood) {
            caseMaterials.push('WOOD')
          } else if (caseMaterials.includes('WOOD')) {
            caseMaterials.splice(caseMaterials.indexOf('WOOD'))
          }

          if ($scope.caseMaterial.stainlessSteel) {
            caseMaterials.push('STAINLESS_STEEL')
          } else if (caseMaterials.includes('STAINLESS_STEEL')) {
            caseMaterials.splice(caseMaterials.indexOf('STAINLESS_STEEL'))
          }

          if ($scope.caseMaterial.titanium) {
            caseMaterials.push('TITANIUM')
          } else if (caseMaterials.includes('TITANIUM')) {
            caseMaterials.splice(caseMaterials.indexOf('TITANIUM'))
          }

          if ($scope.caseMaterial.aluminium) {
            caseMaterials.push('ALUMINIUM')
          } else if (caseMaterials.includes('ALUMINIUM')) {
            caseMaterials.splice(caseMaterials.indexOf('ALUMINIUM'))
          }

          if ($scope.caseMaterial.metal) {
            caseMaterials.push('METAL')
          } else if (caseMaterials.includes('METAL')) {
            caseMaterials.splice(caseMaterials.indexOf('METAL'))
          }

          if ($scope.caseMaterial.plasticSilicon) {
            caseMaterials.push('PLASTIC_SILICONE')
          } else if (caseMaterials.includes('PLASTIC_SILICONE')) {
            caseMaterials.splice(caseMaterials.indexOf('PLASTIC_SILICONE'))
          }

          if ($scope.caseMaterial.ceramics) {
            caseMaterials.push('CERAMICS')
          } else if (caseMaterials.includes('CERAMICS')) {
            caseMaterials.splice(caseMaterials.indexOf('CERAMICS'))
          }

          if (caseMaterials.length == 0) {
            caseMaterials = ['TITANIUM', 'STAINLESS_STEEL', 'ALUMINIUM', 'METAL', 'PLASTIC_SILICONE', 'WOOD', 'CERAMICS']
          }

          filter['watch.case.material'] = { $in: caseMaterials }

          $scope.getProducts({ filter: filter }, { reset: true })

            .catch(function (reason) {
              console.error(reason)
            })
        }
      }
    }]
  }
})
