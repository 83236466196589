prismanoteApp.directive('productFilterByShopProperties', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-shop-properties.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $rootScope.$on('setTheStock', function (event, params) {
        if (params == true) {
          if ($scope.shopProperties != undefined) {
            $scope.shopProperties.inStock = true
            $scope.setShopProperties()
          } else {
            $scope.shopProperties = { inStock: true }
            $scope.setShopProperties()
          }
        } else if (params == false) {
          if ($scope.shopProperties == undefined) {
            $scope.shopProperties = {}
          }

          $scope.shopProperties.inStock = undefined
          $scope.setShopProperties()
        }
      })

      $scope.setShopProperties = function () {
        var filter = {}
        if ($scope.shopProperties) {
          if ($scope.shopProperties.discount) {
            filter.discount = true
          } else {
            filter.discount = undefined
          }
          if ($scope.shopProperties.inStock) {
            filter.inStock = true
          } else {
            filter.inStock = undefined
          }

          if ($scope.shopProperties.isBestseller) {
            filter.isBestseller = true
          } else {
            filter.isBestseller = false
          }

          if ($scope.shopProperties.show) {
            filter.show = true
          } else {
            filter.show = false
          }

          // $scope.$emit('product_filter_directive', { filter: filter });
        }
      }
    }]
  }
})
