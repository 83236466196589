prismanoteApp.directive('productAddToAssortment', ['$rootScope', function ($rootScope) {
  return {
    restrict: 'E',
    replace: 'true',
    scope: {
      product1: '=product'
    },
    templateUrl: '../views/directives/product-add-to-assortment.html',
    controller: ['$scope', '$api', '$state', '$rootScope', '$uibModal', '$purchaseOrder', '$uibModalStack', function ($scope, $api, $state, $rootScope, $uibModal, $purchaseOrder, $uibModalStack) {
      console.log('rootScope ======>>>> aws', $scope.product1);
      // When coming from the purchase order and changing the brand and then opening the MERGE_MODAL then this IF condition will play the role
      if ($scope.product1 && $scope.product1.isComingFromPurchaseOrder && $scope.product1.isComingFromPurchaseOrder.productNumber) {
        $scope.searchValue = $scope.product1.isComingFromPurchaseOrder.productNumber
      } else {
        $scope.searchValue = $scope.product1.variants[0].productNumber
      }
    
      var searchedProduct = $scope.product1.searchedProduct;

      function removeDuplicateProduct(hits, data) {
        // console.log('removeDuplicateProduct: ', hits.length, data.length);
        if (hits && hits.length && data && data.length) {
          for (var j = 0; j < data.length; j++) {
            // console.log('i: ', i, hits[i]._id);
            for (var i = 0; i < hits.length; i++) {
              if (hits[i]._id && data[j]._id && hits[i]._id == data[j]._id) {
                // console.log(i, j, 'Yes not duplicated');
                hits[i].isDuplicated = true;
              }
            }
          }
        }
        var temphits = hits.filter(function (el) {
          return !el.isDuplicated;
        });
        // console.log('After removing the duplicated prodcuts: ', temphits.length);
        return temphits;
      }

      function fetchGetProductsByShopBrands(body) {
        try {
          if ($scope.hits.length) return;
          var data = JSON.parse(JSON.stringify(body));
          $scope.productsByShopBrandsLoader = true;
          data.isOnlyByBrand = true;
          $api.post('v2/shop/product-search-merge', data) // + $rootScope.currentShop.nameSlug + "/" + searchMode + "/" + value, {}, { reset: true })
            .then(function (response) {
              console.log('fetchGetProductsByShopBrands response: ', response.data.data.products)
              $scope.productsByShopBrandsLoader = false;
              $scope.hits = response.data.data.products;
              // console.log('fetchGetProductsByShopBrands hits: ', $scope.hits.length);
              $scope.setLimit(50)
            })
            .catch(function (reason) {
              $scope.productsByShopBrandsLoader = false;
              console.error('fetchGetProductsByShopBrands: ', reason);
            })
        } catch (e) {
          console.log('fetchGetProductsByShopBrands error: ', e);
        }
      }

      $scope.findProducts = function (value, searchMode) {
        console.log('Find Products Based ON Ean And Brand +++++++>>>', value, searchMode)
        $scope.searchValueLength = false
        $scope.search = true
        if (value) {
          if (value.length < 3) return;
          $scope.hits = [];
          var language = $rootScope.language || 'nl';
          $scope.searchValueLength = true
          var data = {
            nameSlug: $rootScope.currentShop.nameSlug,
            searchMode: searchMode,
            searchText: value,
            shopId: $rootScope.currentShop._id,
            useFor: 'merge',
            currentProduct: $scope.product1.variants[0].productNumber,
            language: language,
            isOnlyByBrand: false
          }

          fetchGetProductsByShopBrands(data);
          console.log('For Testing')
          $api.post('v2/shop/product-search-merge', data) // + $rootScope.currentShop.nameSlug + "/" + searchMode + "/" + value, {}, { reset: true })
            .then(function (response) {
              console.log('response: ', response)
              $scope.search = false;
              var data = response.data.data.products;

              if (data.length < 10 || $scope.hits.length < 10) $scope.hits = removeDuplicateProduct($scope.hits, data);
              var concatedArray = $scope.hits.concat(data);
              $scope.hits = concatedArray;

              $scope.setLimit(50)
            })
            .catch(function (reason) {
              $scope.search = false
              console.error('-------------------------reason: ', reason);
            })
        }
      }

      $scope.currentLanguage = $rootScope.language

      $scope.awsUrl = $rootScope.awsUrl
      $scope.allSelectedProductsToMerge = []
      $(document).ready(function () {
        var typingTimer
        var doneTypingInterval = 2000

        $(document).on('keyup', '#add-to-assortment', function () {
          clearTimeout(typingTimer)
          if ($('#add-to-assortment').val()) {
            typingTimer = setTimeout(doneTyping, doneTypingInterval)
          }
        })
        function doneTyping () {
          alert('Done Typing')
        }
      })

      $scope.searchMode = 'exact'

      if ($scope.searchValue) {
        // console.log('Call for search function', $scope.searchValue)
        $scope.findProducts($scope.searchValue, $scope.searchMode)
      }

      $scope.suggested = function (product) {
        console.log('suggested: ', product, $scope.product1);
        if ($scope.product1 && $scope.product1.isComingFromPurchaseOrder && $scope.product1.isComingFromPurchaseOrder && (!$scope.product1.isComingFromPurchaseOrder.isShopProduct || ($scope.product1.isComingFromPurchaseOrder.isNeedsToCreate))) {
          // Here, we don't need to merge the product but we will change the purchaseOrder.items productId
          replaceThePurchaseOrderProductId(product._id);
          
        } else {
          $rootScope.cancelModal();
          console.log('IN THE ELSE');
          // Before It was opening the COMPARE PAGE but now we will open the MODAL
          // $state.go('retailer.compare-product', { nameSlug: $scope.product1._id, mergeProductnameSlug: product._id })
          $scope.openPairingAssortmentModal(product);
        }
      }

      function replaceThePurchaseOrderProductId(productId) {
        var body = {
          // const { purchaseOrderNumber, itemId, productId } = body;
          purchaseOrderNumber: $scope.product1.isComingFromPurchaseOrder.purchaseOrderNumber,
          itemId: $scope.product1.isComingFromPurchaseOrder.itemId,
          productId: productId,
          index: $scope.product1.isComingFromPurchaseOrder.index,
          shopId:  $rootScope.currentShop._id
        }
        console.log('Glad you are here!', $scope.product1);
        $purchaseOrder.changeProductIdOfPurchaseOrderItem(body).then(function (res) {
          $rootScope.cancelModal();
          $uibModalStack.dismissAll();
          // $uibModalInstance.close();
          var data = {
            productId: productId,
            index: body.index,
            isNeedsToCreate: $scope.product1.isComingFromPurchaseOrder.isNeedsToCreate
          }
          // $rootScope.$emit('mergepurchaseorder', data);
        }).catch(function (err) {
          console.log('err: ', err);
        });
      }

      $scope.openPairingAssortmentModal = function (product) {
        console.log('---------------------------------  pairing item!!');
        searchedProduct.product = product;
        // console.log(product);
        var modalInstance = $uibModal.open({
          templateUrl: '../views/modal/retailer-pairing-assortment.html',
          controller: 'retailerPairingAssortmentModal',
          size: 'lg',
          resolve: {
            item: function () {
              return { nameSlug: $scope.product1._id, mergeProductnameSlug: product._id, searchedProduct: searchedProduct, isComingFromPurchaseOrder: $scope.product1.isComingFromPurchaseOrder }
            }
          },
          windowClass: 'sample-pug'
        });

        modalInstance.result.then(function (result) {
          // console.log('openPairingAssortmentModal called: 789', result);
          // $uibModalInstance.close();
        }, function (error) {
          console.log('openPairingAssortmentModal called: ', error);
        });
      }

      $scope.setLimit = function (limit) {
        if (limit != null) {
          $scope.limit = limit
        } else {
          $scope.limit = 24
        }
      }

      $scope.reloadPage = function (mode) {
        var location = window.location
        if (location.search) {
          window.location.reload(true)
        } else {
          var url = location.href + '?mode=' + mode
          window.location = url
        }
      }
    }]
  }
}])
