prismanoteApp.directive('customProductNewBrand', function () {
	return {
	  restrict: 'E',
	  replace: 'true',
	  scope: false,
	  templateUrl: '../views/directives/custom-product-new-brand.html',
	  controller: ['$rootScope', '$scope', '$brandShopSettings',
      function ($rootScope, $scope, $brandShopSettings) {
        $scope.customBrandFilter = []
        $scope.filterBrands = []
        $scope.selectedBrands = []
		$scope.shop = {
			_id: $rootScope.currentShop._id,
			nameSlug: $rootScope.currentShop.nameSlug
		}
		$scope.data = {
			language: $rootScope.language
		}
		$scope.getShopBrandsByShopId = function () {
			$brandShopSettings.getShopBrandsByShopId({ shopId: $scope.shop._id }).then(function (shopBrandsRes) {
				$scope.shopBrands = [];
				$scope.originalShopBrands = [];
				if (shopBrandsRes && shopBrandsRes.data && shopBrandsRes.data.data && shopBrandsRes.data.data.length) {
					var shopBrandsTemp = _.orderBy(shopBrandsRes.data.data, ['en.name'], ['asc']);
					$scope.shopBrands = shopBrandsTemp;
					$scope.originalShopBrands = JSON.parse(JSON.stringify($scope.shopBrands));
				}
			}).catch(function (shopBrandsErr) {
				console.log('getShopBrandsByShopId Error', shopBrandsErr);
			});
		}

		$scope.setBrandNew = function() {
			console.log($scope.item._id);
		}

		$scope.getMyBrandClass = function (brandId) {
			// return $.inArray(brandId,$scope.filterChecker.brandsInProds)?'' : 'bg-danger';
			return ''
		}

		$scope.selectBrand = function(brand) {
			$scope.customBrandFilter = brand;
		}
	}]}
})
  