prismanoteApp.directive('customFilterByGender', function () {
    var filter = {};
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/custom-filter-by-gender.html',
        scope: {
            filter: '=filter',
        },
        controller: ['$scope', function ($scope) {
            console.log('Custom filter by Gender called: ', $scope.filter);
        }]
    }
})
