prismanoteApp.directive('recommendationFilterByCategory', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-category.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', '$rootScope', '$retailerFilters', function ($scope, $rootScope, $retailerFilters) {

            $scope.selectCategory = function (category) {
                console.log('selectCategory called: ', $scope.filter.category);
                $scope.filter.canShowStrap = false;
                if ($scope.filter.category && $scope.filter.category.length) {
                    for (var i = 0; i < $scope.filter.category.length; i++) {
                        if ($scope.filter.category[i].category == 'STRAP') $scope.filter.isStrapCategory = true;
                        if (($scope.filter.category[i].category == 'WATCH' || $scope.filter.category[i].category == 'STRAP') && $scope.filter.category[i].isSelected) {
                            $scope.filter.canShowStrap = true;
                            break;
                        }
                    }
                }

                // $scope.filter.selectedFilters[0]['CATEGORIES'].push(category);
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})