prismanoteApp.directive('giftCardTransaction', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/gift-card-transaction.html',
    scope: {
      customer: '=customer',
      updateCustomerFromDirective: '&updateCustomerFromDirective'
    },
    controller: [
      '$scope', '$rootScope', '$shop', '$transaction', '$state', '$q', '$customer', 'prompt', '$language',
      function ($scope, $rootScope, $shop, $transaction, $state, $q, $customer, prompt, $language) {
        $scope.taxes = []
        $scope.checkoutWithoutCustomer = false
        $scope.currentItem = {
          giftCardQuantity: 1,
          giftCardNumber: Date.now()
        }
        $scope.loading = true
        $scope.alert = {}
        $scope.editItem = false
        $scope.noMoreOrdersCanAdd = false
        $scope.editItemIndex
        $scope.giftCardTransaction = {
          details: [],
          language: $rootScope.language
        }

        // If customer change or seleted then we need to set into giftcard transaction details. And updated transaction we will get into $watch
        $scope.$watch('customer', function () {
          if ($scope.customer && $scope.customer._id) {
            $scope.customer = $scope.customer
            $scope.giftCardTransaction.customer = $scope.customer._id
          }
        })

        $scope.findRate = function (rate) {
          var index = _.findIndex($scope.taxes, { rate: rate })
          if (index >= 0) {
            return index
          } else {
            return 0
          }
        }

        // Fetch all posible tax rates which is attached to this shop
        $scope.getTaxes = function () {
          if ($scope.taxes && $scope.taxes.length) {
            // No need to call the api again
            $scope.currentItem.giftCardPriceVat = $scope.taxes[$scope.findRate(0)].rate
            return
          }

          $shop.getTaxForShop($rootScope.currentShop._id)
            .then(function (res) {
              $scope.taxes = res.taxes.rates
              $scope.currentItem.giftCardPriceVat = $scope.taxes[$scope.findRate(0)].rate
              $scope.loading = false
              $scope.alert = {}
            })
            .catch(function (reason) {
              console.error('Error while getting taxes for this shop', reason)
              $scope.alert = {
                type: 'danger',
                message: 'Something went wrong. Please try agaign'
              }
              $scope.loading = false
            })
        }

        // Delete giftcard from list
        $scope.deleteGiftCardItem = function (index) {
          $scope.loading = true
          $scope.giftCardTransaction.details.splice(index, 1)
          $scope.currentItem = {
            giftCardQuantity: 1,
            giftCardNumber: Date.now()
          }
          $scope.editItem = false
          $scope.editItemIndex = null
          $scope.noMoreOrdersCanAdd = false
          $scope.loading = false
        }

        // Add/Edit giftcard in list
        $scope.saveGiftCardItem = function () {
          $scope.loading = true

          var newGiftCardDetails = {
            giftcardNumber: $scope.currentItem.giftCardNumber,
            quantity: $scope.currentItem.giftCardQuantity,
            priceVat: $scope.currentItem.giftCardPriceVat,
            price: $scope.currentItem.giftCardPriceVat === 0 ? $scope.currentItem.giftCardPrice : ($scope.currentItem.giftCardPrice / (1 + ($scope.currentItem.giftCardPriceVat / 100))),
            purchasePrice: 0,
            productId: null,
            total: $scope.currentItem.giftCardPrice * $scope.currentItem.giftCardQuantity
          }

          if ($scope.editItem) {
            $scope.giftCardTransaction.details[$scope.editItemIndex] = newGiftCardDetails
          } else {
            $scope.giftCardTransaction.details.push(newGiftCardDetails)
          }

          console.log('$trans', $scope.giftCardTransaction)

          $scope.noMoreOrdersCanAdd = true
          $scope.loading = false
          $scope.editItem = false
          $scope.currentItem = {}
        }

        // Reset giftcard details form
        $scope.cancelGiftCardItem = function () {
          $scope.loading = true
          $scope.alert = null
          $scope.currentItem = {
            giftCardQuantity: 1,
            giftCardNumber: Date.now()
          }
          $scope.editItem = false
          $scope.editItemIndex = null
          $scope.loading = false

          if ($scope.giftCardTransaction.details.length) {
            $scope.noMoreOrdersCanAdd = true
          }
        }

        $scope.makeObjectForShopPurchaseTransaction = function (giftCardTransaction) {
          return $q(function (resolve, reject) {
            var existingDetails = giftCardTransaction.details
            var newDetailsForShopPurchaseTransaction = []

            for (var i = 0; i < existingDetails.length; i++) {
              newDetailsForShopPurchaseTransaction.push({
                name: $language.translate('PAYMENT_FOR_GIFTCARD'),
                quantity: existingDetails[i].quantity,
                price: existingDetails[i].price,
                isNewProduct: false,
                isRestictForEdit: true,
                priceVat: existingDetails[i].priceVat,
                productId: null,
                transaction: giftCardTransaction._id,
                purchasePrice: existingDetails[i].purchasePrice,
                parentTransactionDetailId: giftCardTransaction._id,
                total: existingDetails[i].total
              })
            }

            return resolve({
              details: newDetailsForShopPurchaseTransaction,
              parentTransactionId: giftCardTransaction._id,
              shopId: giftCardTransaction.shopId,
              type: 'shop-purchase',
              customer: giftCardTransaction.customer ? giftCardTransaction.customer : null
            })
          })
        }

        $scope.beforeCreateTransactionForCustomerChecking = function () {
          var searchTranslation = $language.translate('SEARCH_CUSTOMER')
          var withoutTranslation = $language.translate('CONTINUE_WITHOUT')

          prompt({
            title: $language.translate('ALERT'),
            message: $language.translate('ARE_YOU_SURE_TO_CONTINUE_WITHOUT_CUSTOMER'),
            buttons: [
              {
                label: searchTranslation,
                primary: true
              },
              {
                label: withoutTranslation,
                cancel: true,
                primary: false
              }
            ]
          })
            .then(function (result) {
              if (result.label === searchTranslation) {
                $scope.openSearchCustomerModal()
              } else if (result.label === withoutTranslation) {
                $scope.checkoutWithoutCustomer = true
                $scope.createGiftCardTransaction()
              }
              $scope.loading = false
            })
            .catch(function (error) {
              $scope.loading = false
            })
        }

        // Create new giftcard transaction
        $scope.createGiftCardTransaction = function () {
          $scope.loading = true
          $scope.giftCardTransaction.shopId = $rootScope.currentShop._id

          // Check customer is attached or not. If not then we need to open model to warn retailer
          if (!$scope.customer && !$scope.checkoutWithoutCustomer) {
            return $scope.beforeCreateTransactionForCustomerChecking()
          }

          // Create transaction
          $transaction.createGiftCardTransaction($scope.giftCardTransaction)
            .then(function (newCreateTransaction) {
              return $scope.makeObjectForShopPurchaseTransaction(newCreateTransaction.data.result.transaction)
            })
            .then(function (shopPurchaseTransactionObj) {
              $state.go('retailer.till')
            })
            .catch(function (error) {
              $scope.loading = false
              $scope.alert = {
                type: 'danger',
                msg: error.data.message || 'Something went wrong'
              }
            })
        }

        // Open giftcard item for edit
        $scope.openGiftCardItem = function (item, index) {
          $scope.loading = true
          $scope.editItem = true
          $scope.editItemIndex = index
          $scope.currentItem = {
            giftCardNumber: item.giftcardNumber,
            giftCardQuantity: item.quantity,
            giftCardPriceVat: item.priceVat,
            giftCardPrice: item.price * (1 + (item.priceVat / 100))
          }
          $scope.loading = false
          $scope.noMoreOrdersCanAdd = false
        }

        // Open customer selection pop-up
        $scope.openSearchCustomerModal = function () {
          $rootScope.checkModule('PACKAGE_CUSTOMER_MANAGEMENT', true, true)
            .then(function () {
              $customer.openSearchCustomerModal()
                .then(function (result) {
                  $scope.customer = result
                  $scope.updateCustomerFromDirective({ customer: result })
                  $scope.checkoutWithoutCustomer = false
                })
                .catch(function (reason) {
                  console.error('REASON', reason)
                })
            })
        }
      }]
  }
})
