prismanoteApp.directive('productBrandCollection', function () {
  var filter = {}

  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-brand-collection.html',
    scope: {
      product: '=product'
    },
    controller: ['$scope', '$rootScope', '$collection', '$productConfig', '$q', '$uibModal', '$api', '$brand',
      function ($scope, $rootScope, $collection, $productConfig, $q, $uibModal, $api, $brand) {
        console.log('I AM BRAND COLLECTION New DIRECTIVE', $scope.product)
        $scope.user = $rootScope.user
        $scope.language = $rootScope.language
        $scope.brandCollections = []
        $scope.tempName = ''
        $scope.products = []
        
        $scope.$watch('product.brand', function () {
          if ($scope.product.brand && $scope.product.brand.name && ($scope.product.brand.name == 'Pool articles Jewellers')) {
            $scope.disabled = true
          }
        })

        $scope.openProductModal = function (products) {
          // console.log("Product", product)
          console.log("Hey Product Model CHeck this",products);
          return $q(function (resolve, reject) {
            var data = {
              'products': products,
              'modal': 'brand'
            };
            console.log('data::',data);
            var modalInstance = $uibModal.open({
              templateUrl: '../views/modal/retailer-shop-product-sorting-modal.html',
              controller: 'retailerShopProductSortingController',
              size: 'lg',
              resolve: {
                product: function () {
                  return data;
                }
              }
            });

            modalInstance.result
              .then(function (response) {
                console.log("Response After Close Modal", response);
                if (response) {
                  console.log('Model response');
                  /* $api.post('collection-products', data)
                    .then(function (response) {
                      $scope.products = response.data.products
                    }) */
                }
              })
              .catch(function (reason) {
                console.log("Here", reason);
                reject(reason);
              });
          });
        }

        $scope.openCollectionModalView = function () {
          $scope.openCollectionModal($scope.product.brandCollections)
        }
        $scope.openCollectionModal = function (collections) {
          // console.log("Product", product)
          console.log("Hey collections Model CHeck this",collections);
          return $q(function (resolve, reject) {
            var modalInstance = $uibModal.open({
              templateUrl: '../views/modal/retailer-shop-product-collections-modal.html',
              controller: 'retailerShopProductCollectionsController',
              size: 'lg',
              resolve: {
                product: function () {
                  return collections;
                }
              }
            });

            modalInstance.result
              .then(function (response) {
                console.log("Response After Close Modal", response);
                if (response) {
                  console.log('Model response');
                  /* $api.post('collection-products', data)
                    .then(function (response) {
                      $scope.products = response.data.products
                    }) */
                }
              })
              .catch(function (reason) {
                console.log("Here", reason);
                reject(reason);
              });
          });
        }

        function getAllBrands () {
          $brand.getAllBrands()
            .then(function (success) {
              $scope.brands = success.brands
            }, function (err) {
              console.error(err)
            })
        }

        $scope.getBrandCollections = function () {
          // Evrry Privale Lable Collcetions are shops specific collcetion
          $collection.getBrandCollections()
            .then(function (results) {
              $scope.allBrandCollections = results.data
            })
            .catch(function (reason) {
              console.error(reason)
            })
        }

        // Searching for the all the auto complete

        $scope.searchInBrandCollections = function (str) {
          return searchItems(str)
        }

        $scope.brandCollectionSelected = function (selected) {
          console.log('selected', selected.originalObject.type)
          if (selected.originalObject.usedByBrand != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
            addCollectionInProduct(selected.originalObject, 'open')
          } else {
            console.log('selected', selected.originalObject)
            assignNameToBlank(selected.originalObject, selected.title)
          }
        }

        $scope.changeCollectionName = function (tempName) {
          $scope.collection.en.name = tempName
          $scope.collection.de.name = tempName
          $scope.collection.nl.name = tempName
          $scope.collection.fr.name = tempName
          $scope.collection.es.name = tempName
        }

        function assignNameToBlank (collection, value) {
          $scope.addNewCollectionForm = true
          $scope.collection = collection
          getAllBrands()
          $scope.collection.en.name = $scope.tempName
          $scope.collection.de.name = $scope.tempName
          $scope.collection.nl.name = $scope.tempName
          $scope.collection.fr.name = $scope.tempName
          $scope.collection.es.name = $scope.tempName
          $scope.collection.isVerified = false
          $scope.collection.usedByBrand = true
          $scope.collection.privateLabel = false
          $scope.collection.variantsCollection = false
          $scope.collection.isClientGroup = false
          $scope.collection.variantBase = []
          console.log('product handling guy Please Check here collection', $scope.collection)
        }

        function addCollectionInProduct (selectedCollection, selection) {
          console.log('selectedCollection::',selectedCollection);
          console.log('$scope.product.brandVariantsCollections::',$scope.product.brandVariantsCollections);
          if ($scope.product.brandVariantsCollections == undefined) {
            $scope.product.brandCollections = []
            console.log('inside if');
            if (selection == 'open') {
              var data = {
                collectionId: selectedCollection._id,
                shopId: $rootScope.currentShop._id
              }
              $api.post('collection-products', data)
                .then(function (response) {
                 console.log('response:::',response);
                 $scope.products = response.data.products
                 $scope.openProductModal($scope.products)
                })
                .catch(function (reason) {
                  $log.error('Error while fetching collection data', reason)
                  $scope.loading = false
                })
            }
            $scope.product.brandCollections.push(selectedCollection)
            console.log('$scope.product.brandCollections:::::::',$scope.product.brandCollections);
          } else {
            console.log('inside else');

            var data = {
              collectionId: selectedCollection._id,
              shopId: $rootScope.currentShop._id
            }
            $api.post('collection-products', data)
              .then(function (response) {
               console.log('response:::',response);
               $scope.products = response.data.products
               $scope.openProductModal($scope.products)
              })
              .catch(function (reason) {
                $log.error('Error while fetching collection data', reason)
                $scope.loading = false
              })
            $scope.product.brandCollections.push(selectedCollection)
          }
          /* if ($scope.product.brandCollections && $scope.product.brandCollections.length > 0) {
            console.log('inside loop');
            for (var k = 0; k < $scope.product.brandCollections.length; k++) {
              var collectionIds = [];
              collectionIds.push($scope.product.brandCollections[k]._id)
              console.log('collectionIds:::',collectionIds);
              if (collectionIds.length == $scope.product.brandCollections.length) {
                var data = {
                  collectionIds: collectionIds
                }
                console.log('data:::',data);
                $api.post('collection-products', data)
              .then(function (response) {
               console.log('response:::',response);
               $scope.products = response.data.products
               $scope.openProductModal($scope.products)
              })
              .catch(function (reason) {
                $log.error('Error while fetching collection data', reason)
                $scope.loading = false
              })
              }
            }
            
          } */
          console.log('$scope.product.brandCollections::::',$scope.product.brandCollections);
        }

        $scope.getTheCollectionName = function (collection) {
          var collectionName = ''
          if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
          return collectionName
        }

        $scope.deleteBrandCollection = function (index) {
          console.log('index', index)
          // $scope.product.brandCollections = []
          $scope.products = []
          // $scope.product.brandCollections.splice(index, 0);
        }

        $scope.searchBrands = function (str) {
          var matches = []
          $scope.brands.forEach(function (brand) {
            if ((brand[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) ||
						(brand[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0)) {
              matches.push(brand)
            }
          })
          return matches
        }

        $scope.brandSelected = function (selected) {
          var brand = {
            _id: selected.originalObject._id,
            name: selected.title,
            nameSlug: selected.originalObject.nameSlug,
            description: selected.originalObject.description,
            images: selected.originalObject.images
          }

          $scope.collection.brand = brand
        }

        $scope.addCollection = function (collection) {
          console.log('Collection New', collection)
          console.log('$rootScope.currentShop::',$rootScope.currentShop);
          console.log(' $rootScope.currentCompany::', $rootScope.currentCompany);
          if ($rootScope.currentShop && $rootScope.currentShop._id) {
            console.log('inside if');
            $collection.addNewCollection(collection, $rootScope.currentShop._id, null)
              .then(function (success) {
                console.log('product handling guy Please check this added new collection', success.collection)
                addCollectionInProduct(success.collection, 'close')
                $scope.collection = {}
                $scope.addNewCollectionForm = false
              })
              .catch(function (error) {
                console.error('Error In adding New Collection in database', error)
              })
          } else if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
            console.log('inside else if');

            $collection.addNewCollection(collection, null, $rootScope.currentCompany._id)
              .then(function (success) {
                console.log('product handling guy Please check this added new collection', success.collection)
                addCollectionInProduct(success.collection, 'close')
                $scope.collection = {}
                $scope.addNewCollectionForm = false
              })
              .catch(function (error) {
                console.error('Error In adding New Collection in database', error)
              })
          } else {
            console.log('inside else');

            $collection.addNewCollection(collection, null, null)
              .then(function (success) {
                console.log('product handling guy Please check this added new collection', success.collection)
                addCollectionInProduct(success.collection, 'close')
                $scope.collection = {}
                $scope.addNewCollectionForm = false
              })
              .catch(function (error) {
                console.error('Error In adding New Collection in database', error)
              })
          }
        }

        var searchItems = function (str) {
          var matches = []
          $scope.tempName = str
          var addNewCollectionItem = $productConfig.addNewCollection()

          matches.push(addNewCollectionItem)

          var collections = $scope.allBrandCollections
          var collcetionLength = collections.length

          if (collections != undefined && collcetionLength > 0) {
            collections.forEach(function (item) {
              if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
                matches.push(item)
              }
            })
          }
          return matches
        }
      }]
  }
})
