prismanoteApp.directive('productFilterByIndicationNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-indication-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$retailerFilters',
      function ($scope, $rootScope, $retailerFilters) {
        $scope.CHRONO_MULTI = false
        $scope.ANALOG = false
        $scope.DIGITAL = false
        $scope.ANALOG_DIGITAL = false

        $scope.indication = {}
        function getIndicationFilter () {
          $retailerFilters.getIndicationFilter()
            .then(function (indicationFilter) {
              indicationFilter = JSON.parse(indicationFilter)
              if (indicationFilter && indicationFilter['watch.indication'] && indicationFilter['watch.indication'].$in && indicationFilter['watch.indication'].$in.length) {
                for (var i = 0; i < indicationFilter['watch.indication'].$in.length; i++) {
                  if (indicationFilter['watch.indication'].$in[i] == 'ANALOG') $scope.indication.analog = true
                  if (indicationFilter['watch.indication'].$in[i] == 'CHRONO_MULTI') $scope.indication.chronoMulti = true
                  if (indicationFilter['watch.indication'].$in[i] == 'DIGITAL') $scope.indication.digital = true
                  if (indicationFilter['watch.indication'].$in[i] == 'ANALOG_DIGITAL') $scope.indication.analogDigital = true
                }

                $scope.setIndication('localStorage')
              } else {
                $scope.setIndication('localStorage')
              }
            })
            .catch(function (error) {
              console.log('Error', error)
            })
        }

        getIndicationFilter()

        $scope.$on('indication_filter_remove', function (event, sort) {
          var remove = sort.removeFilter

          if (remove.name === 'CHRONO MULTI') {
            $scope.indication.chronoMulti = false
            $scope.setIndication()
          } else if (remove.name == 'ANALOG') {
            $scope.indication.analog = false
            $scope.setIndication()
          } else if (remove.name === 'DIGITAL') {
            $scope.indication.digital = false
            $scope.setIndication()
          } else if (remove.name === 'ANALOG DIGITAL') {
            $scope.indication.analogDigital = false
            $scope.setIndication()
          }
        })

        shopFiltersAvailbeOrNot = function () {
          if ($rootScope.shopFilters.indication.indexOf('CHRONO_MULTI') != -1) $scope.CHRONO_MULTI = true
          if ($rootScope.shopFilters.indication.indexOf('ANALOG') != -1) $scope.ANALOG = true
          if ($rootScope.shopFilters.indication.indexOf('DIGITAL') != -1)$scope.DIGITAL = true
          if ($rootScope.shopFilters.indication.indexOf('ANALOG_DIGITAL') != -1) $scope.ANALOG_DIGITAL = true
        }

        $scope.setIndication = function (status) {
          var filter = { 'watch.indication': {} }
          var indications = []

          if ($scope.indication) {
            if ($scope.indication.chronoMulti) {
              indications.push('CHRONO_MULTI')
              var temp = { name: 'CHRONO MULTI', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index < 0) {
                $rootScope.selectdFilter.push(temp)
              }
            } else if (indications.indexOf('CHRONO_MULTI') != -1) {
              indications.splice(indications.indexOf('CHRONO_MULTI'))
              var temp = { name: 'CHRONO MULTI', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            } else {
              var temp = { name: 'CHRONO MULTI', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            }

            if ($scope.indication.analog) {
              indications.push('ANALOG')
              var temp = { name: 'ANALOG', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index < 0) {
                $rootScope.selectdFilter.push(temp)
              }
            } else if (indications.indexOf('ANALOG') != -1) {
              indications.splice(indications.indexOf('ANALOG'))
              var temp = { name: 'ANALOG', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            } else {
              var temp = { name: 'ANALOG', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            }

            if ($scope.indication.digital) {
              indications.push('DIGITAL')
              var temp = { name: 'DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index < 0) {
                $rootScope.selectdFilter.push(temp)
              }
            } else if (indications.indexOf('DIGITAL') != -1) {
              indications.splice(indications.indexOf('DIGITAL'))
              var temp = { name: 'DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            } else {
              var temp = { name: 'DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            }

            if ($scope.indication.analogDigital) {
              indications.push('ANALOG_DIGITAL')
              var temp = { name: 'ANALOG DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index < 0) {
                $rootScope.selectdFilter.push(temp)
              }
            } else if (indications.indexOf('ANALOG_DIGITAL') != -1) {
              indications.splice(indications.indexOf('ANALOG_DIGITAL'))
              var temp = { name: 'ANALOG DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            } else {
              var temp = { name: 'ANALOG DIGITAL', filter: 'indication' }
              var index = _.findIndex($rootScope.selectdFilter, temp)
              if (index > -1) {
                $rootScope.selectdFilter.splice(index, 1)
              }
            }
          }

          var filter

          if (indications.length == 0) indications = []

          filter['watch.indication'] = { $in: indications }
          $retailerFilters.setIndicationFilter(filter)
          if (status && status == 'localStorage') filter.status = status
          $scope.$emit('indication_filter_directive', { filter: filter })
        }
      }]
  }
})
