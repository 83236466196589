prismanoteApp.directive('productFilterByStrapWidthNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-width-new.html',
    scope: true,
    controller: ['$scope', '$retailerFilters', function ($scope, $retailerFilters) {
      var filter = {}

      function getCurrentStrapWidth () {
        $retailerFilters.getWidthOfStrapFilter()
          .then(function (strapWidthFilter) {
            // console.log('Strap Width Filter', strapWidthFilter)
            strapWidthFilter = JSON.parse(strapWidthFilter)
          })
          .catch(function (error) {
            console.error(error)
          })
      }

      getCurrentStrapWidth()

      $scope.strapWidth = {
        min: 0,
        max: 150,
        options: {
          floor: 0,
          ceil: 150,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'ceil':
                return value + ' mm +'
              case 'model':
                return value + ' mm'
              case 'high':
                if (value == 150) {
                  return value + ' mm +'
                } else {
                  return value + ' mm'
                }
              default:
                return value + ' mm'
            }
          },
          onEnd: function () {
            if ($scope.strapWidth.min > -1 && $scope.strapWidth.max < 150) {
              filter['watch.strap.width'] = { $gte: $scope.strapWidth.min, $lte: $scope.strapWidth.max }
            } else if ($scope.strapWidth.min == 0 && $scope.strapWidth.max > 150) {
              filter['watch.strap.width'] = { $gte: $scope.strapWidth.min, $lte: $scope.strapWidth.max }
            } else if ($scope.strapWidth.min > 0 && $scope.strapWidth.max == 150) {
              filter['watch.strap.width'] = { $gte: $scope.strapWidth.min, $lte: $scope.strapWidth.max }
            } else if ($scope.strapWidth.min == 0 && $scope.strapWidth.max == 150) {
              filter['watch.strap.width'] = { $gte: $scope.strapWidth.min, $lte: $scope.strapWidth.max }
            } else {
              filter['watch.strap.width'] = undefined
            }

            $retailerFilters.setWidthOfStrapFilter(filter)
            $scope.$emit('strap_width_filter_directive', { filter: filter })
          }
        }
      }
    }]
  }
})
