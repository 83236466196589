prismanoteApp.directive('recommendationFilterByArticleGroup', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/recommendation-filter-by-article-group.html',
        scope: {
            filter: '=xyz',
        },
        controller: ['$scope', '$rootScope', function ($scope, $rootScope) {

            // console.log('DIRECTIVE: privateLabelCollections called: ', $scope.filter.privateLabelCollections);

            $scope.selectPrivateLableCollection = function () {
                // console.log('selectPrivateLableCollection called: ', $scope.filter.privateLabelCollections);
                var selectedPrivateLableCollections = $scope.filter.privateLabelCollections.filter(function (el) {
                    return el.isSelected;
                }).map(function (ele) {
                    return ele._id;
                });
                $scope.filter.selectedPrivateLabelCollections = selectedPrivateLableCollections;
                $scope.$emit('recommendation_filter_directive', $scope.filter);
            }
        }]
    }
})