prismanoteApp.directive('productFilterByCollectionNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-collection-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$shop', '$retailerFilters',
      function ($scope, $rootScope, $shop, $retailerFilters) {
        $scope.status

        function getCurrertCollectionFilters (privateLableCollections) {
          $retailerFilters.getCollectionFilter()
            .then(function (collectionFilter) {
              collectionFilter = JSON.parse(collectionFilter)
              $scope.status = 'localStorage'
              if (collectionFilter && collectionFilter.collection && collectionFilter.collection.$in && collectionFilter.collection.$in.length) {
                var index = _.findIndex(privateLableCollections, function (el) {
                  return el._id.toString() === collectionFilter.collection.$in[0]
                })

                if (index > -1) $scope.setCollectionNew(collectionFilter.collection.$in[0], true, $scope.privateLableCollections[index].en.name)

                if (index == -1) {
                  if (collectionFilter.collection.$in[0] == $scope.serviceCollection._id.toString()) $scope.setCollectionNew(collectionFilter.collection.$in[0], true, $scope.serviceCollection.en.name)
                }
              } else {
                $scope.setCollectionNew(null, null, null)
              }
            })
            .catch(function (error) {
              console.log('Error', error)
            })
        }

        $scope.$on('collection_filter_remove', function (event, sort) {
          var remove = sort.removeFilter
          var index = _.findIndex($scope.privateLableCollections, function (collection) {
            if (collection.en && collection.en.name == remove.name) return collection
          })

          if (index > -1) {
            $scope.privateLableCollections[index].status = false
            $scope.setCollectionNew($scope.privateLableCollections[index]._id, false, $scope.privateLableCollections[index].en.name)
          }
        })

        $scope.inShopPrivateLabelCollections = function () {
          // console.log('Get Shop Collections TO Take Data')
          // $shop.getShopsCollections($rootScope.currentShop.nameSlug)
          $shop.getShopsCollectionsAssortment($rootScope.currentShop.nameSlug)
            .then(function (collections) {
              $scope.privateLableCollections = collections.collections
              $scope.serviceCollection = collections.servicesCollection

              getCurrertCollectionFilters($scope.privateLableCollections)
            })
            .catch(function (error) {
              console.error('Error In Geting Collections', error)
            })
        }

        $scope.setCollectionNew = function (selectCollectionId, status, collectionName) {
          var filter = { collection: { $in: [] } }
          for (var i = 0; i < $scope.privateLableCollections.length; i++) {
            if (($scope.privateLableCollections[i]._id === selectCollectionId) && (status == true)) $scope.privateLableCollections[i].status = true
            else $scope.privateLableCollections[i].status = false

            if (collectionName != 'Services' && status) $scope.serviceCollection.status = false
          }

          var tempLength = $rootScope.selectdFilter.length

          for (var i = 0; i < tempLength; i++) {
            if ($rootScope.selectdFilter[i].filter == 'Collection') {
              $rootScope.selectdFilter.splice(i, 1)
              tempLength--
              i--
            }
          }

          if (status) {
            var index = _.findIndex($rootScope.selectdFilter, { name: collectionName, filter: 'Collection' })
            if (index < 0) $rootScope.selectdFilter.push({ name: collectionName, filter: 'Collection' })
          }

          if (status) filter.collection.$in[0] = selectCollectionId
          else filter.collection.$in = []

          $retailerFilters.setCollectionFilter(filter)

          if ($scope.status && $scope.status == 'localStorage') filter.status = 'localStorage'
          $scope.$emit('collection_filter_directive', { filter: filter })
        }
      }]
  }
})
