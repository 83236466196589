prismanoteApp.directive('productPrivateLabelCollection', function () {
  var filter = {}
  // console.log('I AM Private Label COLLECTION New DIRECTIVE');
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-private-label-collection.html',
    scope: {
      itemdata: '=itemdata',
      shopCollections: '=abcd',
      product: '=product',
      hideAddBtn: '=hideaddbtn',
      itemindex: '=itemindex'
    },
    controller: ['$scope', '$uibModal', '$rootScope', '$q', '$shop', '$collection', '$productConfig', '$purchaseOrder', '$state', '$retailer', '$stateParams',
    function ($scope, $uibModal, $rootScope, $q, $shop, $collection, $productConfig, $purchaseOrder, $state, $retailer, $stateParams) {
      console.log('I AM Private Label COLLECTION New DIRECTIVE: ', $scope.product);
      // console.log('$scope.itemdata: ', $scope.itemdata);
      // console.log('itemdata1, ', itemdata);
      // return;


      // console.log("$state", JSON.stringify($state.current.name, null, 2));

      $scope.language = $rootScope.language;
      console.log('language: ', $scope.language);
      $scope.user = $rootScope.user;
      $scope.privateLabelCollections = []
      $scope.tempName = ''

      $scope.$watch('product.brand', function () {
        if ($scope.product.brand && $scope.product.brand.name && ($scope.product.brand.name == 'Pool articles Jewellers')) {
          $scope.disabled = true
        }
      })

      $scope.getCollections = function () {
        console.log('------- getCollections!!');
        if (!$scope.shopCollections) {
          console.info(1);
          if ($rootScope.user.role === 'retailer') {
            console.info(2);
            if (!$rootScope.currentShop) {
              console.info(3);
              $retailer.getShop()
                .then(function (shop) {
                  $rootScope.currentShop = shop
                  $scope.getOwnShopsPrivateLableCollections($rootScope.currentShop._id)
                })
            } else {
              console.info(4);
              $scope.getOwnShopsPrivateLableCollections($rootScope.currentShop._id)
            }
          } else {
            console.info(5);
            console.log('User is the admin', $rootScope.user.role)
          }
        } else {
          if ((!$scope.allPrivateLableCollections || !$scope.allPrivateLableCollections.length) && $scope.shopCollections && $scope.shopCollections.length) {
            console.log('That come inside');
            $scope.allPrivateLableCollections = $scope.shopCollections
          }
          console.info(6, $scope.allPrivateLableCollections);
        }
      }

      $scope.getOwnShopsPrivateLableCollections = function (currentShopId) {
        console.log('--------- getOwnShopsPrivateLableCollections!');
        // Evrry Privale Lable Collcetions are shops specific collcetion
        var shopId = currentShopId;
        if ($scope.product && $scope.product.multiShopId) shopId = $scope.product.multiShopId;
        $shop.getOwnShopsPrivateLableCollections(shopId)
          .then(function (results) {
            console.log('--------- getOwnShopsPrivateLableCollections!: ', results);
            $scope.allPrivateLableCollections = results
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }

      // Searching for the all the auto complete

      $scope.searchPrivateLableCollcetion = function (str) {
        console.log('searchPrivateLableCollcetion called: ', str);
        return searchItems(str, 'privateLable')
      }

      $scope.privateLableCollectionSelected = function (selected) {
        // console.log($state.current);
        // console.log('COLLECTION SELECTED: ', selected, $scope.product)
        // If Directive called from the Purchase-order then it will come inside the "if condition"

        if ($state.current.name === 'retailer.assortment') {
          if (selected.originalObject.privateLabel != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
            // console.log("Hey product handling guy Check this have to work some")
            // $scope.$emit('edit-new-collection', {
            //   _id: selected.originalObject._id
            // })
            var selectedPrivateLableCollection = selected.originalObject
            $scope.product.privateLabelCollections = []
            $scope.product.privateLabelCollections.push(selectedPrivateLableCollection)
          } else {
            assignNameToBlank(selected.originalObject, selected.originalObject[$rootScope.language].name)
          }
        } else {
          // console.log($scope.product);
          if ($scope.product && $scope.product.isFromPurchaseOrder) {
            if ($scope.noCollection) {
              if ($scope.product && $scope.product._id) {
                console.log('no collection but prodcutid', $scope.product);
                $scope.openModal($scope.product);
                // $state.go('retailer.edit-product', {
                //   orderNumber: $scope.product.purchaseOrderNumber,
                //   _id: $scope.product._id,
                //   status: 'view'
                // })
              } else {
                $state.go('retailer.add-product', {
                  orderNumber: $scope.product.purchaseOrderNumber,
                  itemId: $scope.itemdata._id
                })
              }
            } else {
              var body = {
                productId: $scope.product._id,
                shopId: $scope.product.shopId,
                shopNameSlug: $rootScope.currentShop.nameSlug,
                collection: selected.originalObject,
                purchaseOrder: {
                  purchaseOrderId: $scope.product.purchaseOrderId,
                  company: $scope.product.company,
                  purchaseOrderItem: $scope.itemdata,
                  number: $stateParams.orderNo,
                }
              }
              console.log('add collecton from purchase order body: ', body)
              $purchaseOrder.addCollectionFromThePurchaseOrder(body).then(function (response) {
                console.log('response: ', response.status);
                // $scope.GetExistingPurchaseOrder();
                $scope.$emit('change_collection_purchaseorder', { itemIndex: $scope.itemindex, isAdded: response.status });
              }).catch(function (error) {
                console.log('error: ', error)
              })
            }
          } else {
            var selectedPrivateLableCollection = selected.originalObject
            // If we have give then to access for the delete
            if (selected.originalObject.privateLabel != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
              console.log(' selectedPrivateLableCollection ', selectedPrivateLableCollection);
              console.log(' $scope.product ', $scope.product);
              if(!$scope.product){
                $scope.collections = [];
                $scope.collections.push(selectedPrivateLableCollection);
                $scope.$emit('privateLabelCollectionsForBulkPage', { collection: $scope.collections })
              }else{
                $scope.product.privateLabelCollections = []
                $scope.product.privateLabelCollections.push(selectedPrivateLableCollection)
              }
            } else {
              console.log('selected', selected.originalObject, selected.originalObject[$rootScope.language])
              assignNameToBlank(selected.originalObject, selected.originalObject[$rootScope.language].name)
            }
          }
        }

        console.log('-----------------!!');
      }

      $scope.changeCollectionName = function (tempName) {
        console.log('changeCollectionName called: ', tempName);
        if (tempName.toLowerCase() == 'services') {
          $scope.errorMessage = true
          $scope.errorLog = {
            en: true,
            nl: true,
            de: true,
            fr: true,
            es: true
          }
        } else {
          $scope.errorMessage = false
          $scope.errorLog = {
            en: false,
            nl: false,
            de: false,
            fr: false,
            es: false
          }
        }

        $scope.collection.en.name = tempName
        $scope.collection.de.name = tempName
        $scope.collection.nl.name = tempName
        $scope.collection.fr.name = tempName
        $scope.collection.es.name = tempName
      }

      function assignNameToBlank(collection, value) {
        $scope.addNewCollectionForm = true
        $scope.collection = collection

        var name = $scope.tempName.trim()
        name = name.toLowerCase()

        if (name == 'services') {
          $scope.collection.en.name = $scope.tempName
          $scope.collection.de.name = $scope.tempName
          $scope.collection.nl.name = $scope.tempName
          $scope.collection.fr.name = $scope.tempName
          $scope.collection.es.name = $scope.tempName

          $scope.collection.isVerified = false
          $scope.collection.usedByBrand = false
          $scope.collection.privateLabel = true
          $scope.collection.variantsCollection = false
          $scope.collection.isClientGroup = false
          $scope.collection.variantBase = []

          $scope.errorMessage = true
          $scope.errorLog = {
            en: true,
            nl: true,
            de: true,
            fr: true,
            es: true
          }
        } else {
          $scope.collection.en.name = $scope.tempName
          $scope.collection.de.name = $scope.tempName
          $scope.collection.nl.name = $scope.tempName
          $scope.collection.fr.name = $scope.tempName
          $scope.collection.es.name = $scope.tempName

          $scope.collection.isVerified = false
          $scope.collection.usedByBrand = false
          $scope.collection.privateLabel = true
          $scope.collection.variantsCollection = false
          $scope.collection.isClientGroup = false

          $scope.collection.variantBase = []

          $scope.errorMessage = false
          $scope.errorLog = {
            en: false,
            nl: false,
            de: false,
            fr: false,
            es: false
          }
        }

        // console.log("product handling guy Please Check here collection", $scope.collection);
      }

      $scope.getTheCollectionName = function (collection) {
        var collectionName = ''
        if (collection.hasOwnProperty($rootScope.language)) {
          collectionName = collection[$rootScope.language].name
        } else if (collection.hasOwnProperty('en.name')) {
          collectionName = collection.en.name
        } else if (collection.hasOwnProperty('nl.name')) {
          collectionName = collection.nl.name
        } else if (collection.hasOwnProperty('de.name')) {
          collectionName = collection.de.name
        } else if (collection.hasOwnProperty('fr.name')) {
          collectionName = collection.fr.name
        } else if (collection.hasOwnProperty('name') && collection.name != '') {
          collectionName = collection.name
        } else {
          collectionName = 'NO NAME'
        }
        return collectionName
      }

      $scope.deletePVLCollection = function (index) {
        // $scope.product.privateLabelCollections.splice(index, 0);
        if(!$scope.product){
          $scope.collections = [];
          $scope.$emit('privateLabelCollectionsForBulkPage', { collection: $scope.collections })
        }else{
          $scope.product.privateLabelCollections = []
        }
      }

      var searchItems = function (str) {
        console.log('searchItems called: ', str);
        $scope.noCollection = false
        $scope.tempName = str

        var matches = []

        var addNewCollectionItem = $productConfig.addNewCollection()
        var addNewCollectionString = $productConfig.addNewCollectionString()

        var collections = $scope.allPrivateLableCollections

        var collcetionLength = collections.length

        if (collections != undefined && collcetionLength > 0) {
          collections.forEach(function (item) {
            if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
              matches.push(item)
            }
          })
        }

        if (str && !matches.length && $scope.product && $scope.product.isFromPurchaseOrder && !$scope.hideAddBtn) {
          matches.push(addNewCollectionString)
          $scope.noCollection = true
        } else if (!matches.length && !$scope.hideAddBtn && ($scope.product && !$scope.product.multiShopId)) {
          matches.push(addNewCollectionItem)
        }

        return matches
      }

      $scope.checkCollectionName = function (name, language) {
        console.log('Please Check enterd Name', name)
        name = name.trim()
        name = name.toLowerCase()

        if (name == 'services') {
          $scope.errorLog[language] = true
          $scope.errorMessage = true
        } else {
          $scope.errorLog[language] = false
          $scope.errorMessage = false
        }
      }

      $scope.addCollection = function (collection) {
        console.log('Collection New', collection)

        $collection.addNewCollection(collection, $rootScope.currentShop._id, null)
          .then(function (success) {
            console.log('product handling guy Please check this added new collection', success.collection)
            // addCollectionInProduct(success.collection);
            $scope.product.privateLabelCollections = []
            $scope.product.privateLabelCollections.push(success.collection)
            $scope.allPrivateLableCollections.push(success.collection)
            $scope.collection = {}
            $scope.addNewCollectionForm = false
            $scope.errorMessage = false

            if ($state.current.name === 'retailer.assortment') {
              // Have to add collection shop.collection
              console.log("Have to add collection in shop.collections");
              $shop.addNewCollectionInShop($rootScope.currentShop._id, success.collection._id)
                .then(function (success) {
                  console.log("product handling guy Check this")
                  $scope.$emit('add-new-collection', {
                    status: true
                  })
                })
                .catch(function (error) {
                  console.log("Error", error)
                  $scope.$emit('add-new-collection', {
                    status: false
                  })
                })

              // success.collection._id and $rootScope.currentShop._id

            }
          })
          .catch(function (error) {
            if (error) {
              console.log('error.data', error)
              $scope.errorLog = error
              $scope.errorMessage = true
            }
            console.error('Error In adding New Collection in database', error)
          })
      }

      $scope.openModal = function (product) {
        // console.log('called..');
        console.log('---------------------------------  printing item!!');
        console.log(product);
        console.log($scope._id);
        console.log($scope.itemId);
        console.log($scope.orderNumber);
        var modalInstance = $uibModal.open({
            templateUrl: '../views/retailer1/product.html',
            controller: 'retailerProduct1Controller',
            size: 'lg',
            resolve: {
                item: function () {
                    // product.shopProduct.shippingCosts = $rootScope.currentShop.shippingCosts
                    // product.shopProduct = product;
                    product = product;
                    product.extraData = {};
                    // product.extraData._id = $scope._id
                    // product.extraData.itemId = $scope.itemId
                    if ($scope.orderNumber) {
                        product.extraData.orderNumber = $scope.orderNumber;
                        $rootScope.orderNo = $scope.orderNumber;
                    }
                    return product
                }
            },
            windowClass: 'sample-pug'
        });
        $scope.cancelItem();
      }

    }]
  }
})