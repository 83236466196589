prismanoteApp.directive('brandChatWindow', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/brand-chat-window.html',
    scope: {
      chat: '=chat'
    },
    controller: ['$scope', '$rootScope', '$state', '$purchaseOrder', '$chat', '$brand', '$transaction',
      function ($scope, $rootScope, $state, $purchaseOrder, $chat, $brand, $transaction) {
        console.log('chat response in directive: ', $rootScope.awsUrl, $scope.chat.details);

        $scope.addShopInCompany = function () {
          if ($scope.chat && $scope.chat.details && $scope.chat.details.companyDetail && $scope.chat.details.companyDetail._id &&
            $scope.chat.details.shopDetail && $scope.chat.details.shopDetail._id) {
            var body = {
              shop: $scope.chat.details.shopDetail,
              company: $scope.chat.details.companyDetail,
              companyId: $scope.chat.details.companyDetail._id,
            }
            $brand.addShopInCompany(body).then(function (response) {
              console.log('response: ', response);
            }).catch(function (error) {
              console.log('error: ', error);
            });
          }
        }

        $scope.data = {
          remark: '',
          notifications: 0,
          isInitialized: false,
          awsUrl: $rootScope.awsUrl
        }

        // if ($scope.chat.details.eType === 'Info') {
        //   InitializeGeneralChatSocket($scope.chat)
        // } else if ($scope.chat.details.eType === 'purchaseorder') {
        //   InitializePurchaseOrderSocket($scope.chat)
        // } else if ($scope.chat.details.eType === 'transaction') {
        //   InitializeTransactionSocket($scope.chat)
        // }

        $scope.addComment = function () {
          // console.log('remark: ', $scope.data.remark, $scope.chat.details.eType);
          if ($scope.data.remark) {
            var eType = $scope.chat.details.eType
            if (eType === 'Info') {
              addCommentOfGeneralChat()
            } else if (eType === 'purchaseorder') {
              addCommentOfPurchaseOrder()
            } else if (eType === 'transaction') {
              addCommentOfTransaction()
            }
          }
        }

        // Toggle of chat window
        $scope.collapaseChat = function (remarkId) {
          $('#messagecontainer' + remarkId).toggle('slow')
          $('#messagefooter' + remarkId).toggle('slow')
        }

        $scope.titleTooltip = function (chat) {
          return (chat.details.eType).toUpperCase() + ' ' + (chat.details.number || '') + ' ' + ' with the retailer ' + chat.details.shopDetail.name
        }

        $scope.closeChatWindow = function () {
          $chat.closeChatWindow($scope.chat._id)
        }

        $scope.redirect = function (chat) {
          if (chat.details.eType === 'transaction') {
            $chat.closeChatWindow(chat._id)
            $state.go('brand.service-item', { id: chat.transactionId })
          } else if (chat.details.eType === 'purchaseorder') {
            $chat.closeChatWindow(chat._id)
            $state.go('brand.purchase-order', { orderNo: chat.details.number })
          } else {
            $chat.closeChatWindow(chat._id)
            $state.go('brand.shop-detail', { nameSlug: chat.details.shopDetail.nameSlug })
          }
        }

        // Whenever adding comment to the then we have add new line when pressed (SHIFT + ENTER) and if only enter then add Comment
        $scope.addCommentKeyUp = function (event, remark) {
          if (event.keyCode === 13) {
            if (event.shiftKey) return false
            else $scope.addComment(remark)
          }
        }

        $scope.autoScrollcomment = function () {
          if (!$scope.data.isInitialized) {
            setTimeout(function () {
              $('#messagecontainer' + $scope.chat._id).animate({
                scrollTop: $('#messagecontainer' + $scope.chat._id)[0].scrollHeight
              }, 0)
            }, 200)
          }
          $scope.data.isInitialized = true

          removeNotification()
        }

        // Initialize general socket connection
        // function InitializeGeneralChatSocket(data) {
        //   try {
        //     SocketService.on('company:' + data.details.shopDetail.nameSlug + data.details.companyDetail.nameSlug + ':shop:added:comment:detailpage', function (data) {
        //       // console.log('company comment and getting data in chat window directive[Info]', data);
        //       if ($scope.chat && $scope.chat.remarks && $scope.chat.remarks.length && $scope.chat.remarks[0]) {
        //         $scope.chat.remarks.push(data)
        //       } else {
        //         $scope.chat.remarks = []
        //         $scope.chat.remarks.push(data)
        //       }
        //       $scope.data.notifications += 1
        //       // $scope.autoScrollcomment();
        //       console.log('$scope.data.notifications: ', $scope.data.notifications)
        //     })
        //   } catch (catcherr) {
        //     console.log('catcherr: ', catcherr)
        //   }
        // }

        // function InitializePurchaseOrderSocket(data) {
        //   try {
        //     SocketService.on('company:' + data.details.number + ':shop:added:comment:purchaseorder', function (data) {
        //       // console.log('company comment and getting data in chat window directive[Purchase Order]', data);
        //       if ($scope.chat && $scope.chat.remarks && $scope.chat.remarks.length && $scope.chat.remarks[0]) {
        //         $scope.chat.remarks.push(data)
        //       } else {
        //         $scope.chat.remarks = []
        //         $scope.chat.remarks.push(data)
        //       }
        //       $scope.data.notifications += 1
        //       // $scope.autoScrollcomment();
        //       // console.log('$scope.data.notifications: ', $scope.data.notifications);
        //     })
        //   } catch (catcherr) {
        //     console.log('catcherr: ', catcherr)
        //   }
        // }

        // function InitializeTransactionSocket(data) {
        //   try {
        //     SocketService.on('company:' + data.transactionId + ':shop:added:comment:serviceitem', function (data) {
        //       data.userDetail = data.userId
        //       // console.log('company comment and getting data in chat window directive [Transaction]', data);
        //       if ($scope.chat && $scope.chat.remarks && $scope.chat.remarks.length && $scope.chat.remarks[0]) {
        //         $scope.chat.remarks.push(data)
        //       } else {
        //         $scope.chat.remarks = []
        //         $scope.chat.remarks.push(data)
        //       }
        //       $scope.data.notifications += 1
        //       // $scope.autoScrollcomment();
        //       // console.log('$scope.data.notifications: ', $scope.data.notifications);
        //     })
        //   } catch (catcherr) {
        //     console.log('catcherr: ', catcherr)
        //   }
        // }

        function addCommentOfPurchaseOrder() {
          try {
            var body = {
              purchaseOrderId: $scope.chat.purchaseOrderId,
              shop: $scope.chat.details.shopDetail,
              company: $scope.chat.details.companyDetail,
              purchaseOrderNumber: $scope.chat.details.number,
              remarks: {
                eType: 'company',
                remark: $scope.data.remark,
                companyId: $scope.chat.details.companyDetail._id
              }
            }
            $purchaseOrder.addComment(body).then(function (response) {
              addCurrentRemark()
            }).catch(function (err) {
              $scope.data.remark = ''
              console.log(err)
            })
          } catch (catcherr) {
            $scope.data.remark = ''
            console.log('addCommentOfPurchaseOrder catcherr: ', catcherr)
          }
        }

        function addCommentOfGeneralChat() {
          try {
            var body = {
              shopId: $scope.chat.details.shopDetail._id,
              shopSlug: $scope.chat.details.shopDetail.nameSlug,
              companyId: $scope.chat.details.companyDetail._id,
              companySlug: $scope.chat.details.companyDetail.nameSlug,
              eType: 'company',
              remark: $scope.data.remark
            }

            // console.log('body: ', body);
            $brand.addCommentofCompanyOrShop(body).then(function (response) {
              addCurrentRemark()
            }).catch(function (error) {
              $scope.data.remark = ''
              console.log(error)
            })
          } catch (catcherr) {
            $scope.data.remark = ''
            console.log('addCommentOfGeneralChat catcherr: ', catcherr)
          }
        }

        function addCommentOfTransaction() {
          try {
            var body = {
              transactionId: $scope.chat.transactionId,
              shopId: $scope.chat.details.shopDetail._id,
              shopName: $scope.chat.details.shopDetail.name,
              remarks: {
                eType: 'company',
                remark: $scope.data.remark,
                companyId: $scope.chat.details.companyDetail._id
              }
            }

            // console.log('body: ', body);
            $transaction.addComment(body).then(function (response) {
              addCurrentRemark()
            }).catch(function (err) {
              $scope.data.remark = ''
              console.log(err)
            })
          } catch (catcherr) {
            $scope.data.remark = ''
            console.log('addCommentOfTransaction catcherr: ', catcherr)
          }
        }

        function addCurrentRemark() {
          var data = {
            eType: 'company',
            remark: $scope.data.remark,
            updatedAt: new Date(),
            createdAt: new Date(),
            userDetail: $chat.getCurrentUserDetail()
          }
          $scope.chat.remarks.push(data)
          $scope.data.isInitialized = false
          $scope.autoScrollcomment()
          $scope.data.remark = ''
        }

        function removeNotification() {
          var lastScrollTop = 0
          $('#messagecontainer' + $scope.chat._id).scroll(function (event) {
            if ($scope.data.notifications) {
              var st = $(this).scrollTop()
              if (st > lastScrollTop) {
                $scope.data.notifications = 0
                $scope.$apply()
              }
              lastScrollTop = st
            }
          })
        }
      }]
  }
})
