prismanoteApp.directive('productFilterByCaseDepth', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-depth.html',
    scope: true,
    controller: ['$scope', function ($scope) {
      var filter = {}

      $scope.caseDepth = {
        min: 5,
        max: 15,
        options: {
          floor: 5,
          ceil: 15,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'ceil':
                return value + ' mm +'
              case 'model':
                return value + ' mm'
              case 'high':
                if (value == 15) {
                  return value + ' mm +'
                } else {
                  return value + ' mm'
                }
              default:
                return value + ' mm'
            }
          },
          onEnd: function () {
            if ($scope.caseDepth.min > 5) {
              filter['watch.case.depth'] = { $gte: $scope.caseDepth.min }

              if ($scope.caseDepth.max < 15) {
                filter['watch.case.depth'] = { $lte: $scope.caseDepth.max }
              }
            } else if ($scope.caseDepth.max < 15) {
              filter['watch.case.depth'] = { $lte: $scope.caseDepth.max }
            }

            $scope.getProducts({ filter: filter }, { getShopProducts: true, reset: true })

              .catch(function (reason) {
                console.error(reason)
              })
          }
        }
      }
    }]
  }
})
