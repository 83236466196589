prismanoteApp.directive('productSuggestion', function () {
  return {
    restrict: 'AE',
    replace: true,
    scope: false,
    template: function (elem, attrs) {
      var obj = attrs.productProperty.split('.')
      var model = obj[obj.length - 1] + 'Suggestion'

      var html =
                '<div class="row">\
                    <div class="col-md-6">\
                        <label for="' + model + '">Suggesties</label>\
                    </div>\
                    <div class="col-md-6">\
                            <select class="form-control" ng-options="o as o for o in getVersionValues(\'' + attrs.productProperty + '\')" ng-change="product.' + attrs.productProperty + ' = ' + model + '" ng-model="' + model + '" name="' + model + '">\
                                <option value="" selected hidden />\
                            </select>\
                    </div>\
                </div>\
            '

      return html
    }
  }
})
