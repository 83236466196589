prismanoteApp.directive('productVariantCollection', function () {
  var filter = {}

  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-variant-collection.html',
    scope: {
      product: '=product',
      isFor: '=isfor'
    },
    controller: ['$scope', '$rootScope', '$q', '$shop', '$collection', '$productConfig', function ($scope, $rootScope, $q, $shop, $collection, $productConfig) {
      console.log('I AM variant COLLECTION New DIRECTIVE')
      $scope.user = $rootScope.user
      $scope.language = $rootScope.language
      $scope.variantCollections = []
      $scope.variantBaseOptions = $productConfig.variantBaseOptions()
      $scope.tempName = ''

      $scope.$watch('product.brand', function () {
        if ($scope.product.brand && $scope.product.brand.name && ($scope.product.brand.name == 'Pool articles Jewellers')) {
          $scope.disabled = true
        }
      })

      $scope.getVariantCollections = function () {
        // Evrry Privale Lable Collcetions are shops specific collcetion
        $collection.getVariantCollections()
          .then(function (results) {
            console.log('product handling guy Here please check', results)
            console.log(results)
            $scope.allVariantCollections = results.data
          })
          .catch(function (reason) {
            console.error(reason)
          })
      }

      // Searching for the all the auto complete

      $scope.searchInVariantCollections = function (str) {
        console.log('--------------', str)
        return searchItems(str)
      }

      $scope.variantCollectionSelected = function (selected) {
        console.log('variantCollectionSelected=====================')
        if (selected.originalObject.variantsCollection != undefined && selected.originalObject.en.name != '' && selected.originalObject.nl.name != '' && selected.originalObject.de.name != '' && selected.originalObject.fr.name != '' && selected.originalObject.es.name != '') {
          $scope.product.variantCollections = []
          $scope.product.variantCollections.push(selected.originalObject)
        } else {
          console.log('selected', selected.originalObject)
          assignNameToBlank(selected.originalObject, selected.title)
        }
      }

      $scope.getTheCollectionName = function (collection) {
        var collectionName = ''
        if (collection.hasOwnProperty($rootScope.language)) { collectionName = collection[$rootScope.language].name } else if (collection.hasOwnProperty('en.name')) { collectionName = collection.en.name } else if (collection.hasOwnProperty('nl.name')) { collectionName = collection.nl.name } else if (collection.hasOwnProperty('de.name')) { collectionName = collection.de.name } else if (collection.hasOwnProperty('fr.name')) { collectionName = collection.fr.name } else if (collection.hasOwnProperty('name') && collection.name != '') { collectionName = collection.name } else { collectionName = 'NO NAME' }
        return collectionName
      }

      $scope.deleteVBCollection = function (index) {
        $scope.product.variantCollections = []
      }

      var searchItems = function (str) {
        var matches = []

        $scope.tempName = str
        var addNewCollectionItem = $productConfig.addNewCollection()

        matches.push(addNewCollectionItem)

        var collections = $scope.allVariantCollections
        var collcetionLength = collections.length

        if (collections != undefined && collcetionLength > 0) {
          collections.forEach(function (item) {
            if ((item[$rootScope.language] != undefined && item[$rootScope.language].name != undefined) && ((item[$rootScope.language].name.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0) || (item[$rootScope.language].nameSlug.toLowerCase().indexOf(str.toString().toLowerCase()) > 0))) {
              matches.push(item)
            }
          })
        }
        return matches
      }

      $scope.addCollection = function (collection) {
        console.log('Collection New', collection)
        console.log('++++++++++', $rootScope)
        if ($rootScope.currentShop == null) {
          $collection.addNewCollection(collection, null, $rootScope.currentCompany._id)
            .then(function (success) {
              console.log('product handling guy Please check this added new collection', success.collection)
              // addCollectionInProduct(success.collection);
              $scope.product.variantCollections = []
              $scope.product.variantCollections.push(success.collection)
              $scope.collection = {}
              $scope.addNewCollectionForm = false
            })
            .catch(function (error) {
              console.error('Error In adding New Collection in database', error)
            })
        } else {
          $collection.addNewCollection(collection, $rootScope.currentShop._id, null)
            .then(function (success) {
              console.log('product handling guy Please check this added new collection', success.collection)
              // addCollectionInProduct(success.collection);
              $scope.product.variantCollections = []
              $scope.product.variantCollections.push(success.collection)
              $scope.collection = {}
              $scope.addNewCollectionForm = false
            })
            .catch(function (error) {
              console.error('Error In adding New Collection in database', error)
            })
        }
      }

      $scope.changeCollectionName = function (tempName) {
        $scope.collection.en.name = tempName
        $scope.collection.de.name = tempName
        $scope.collection.nl.name = tempName
        $scope.collection.fr.name = tempName
        $scope.collection.es.name = tempName
      }

      function assignNameToBlank (collection, value) {
        $scope.addNewCollectionForm = true
        $scope.collection = collection

        $scope.collection.en.name = $scope.tempName
        $scope.collection.de.name = $scope.tempName
        $scope.collection.nl.name = $scope.tempName
        $scope.collection.fr.name = $scope.tempName
        $scope.collection.es.name = $scope.tempName

        $scope.collection.isVerified = false
        $scope.collection.usedByBrand = false
        $scope.collection.privateLabel = false
        $scope.collection.variantsCollection = true
        $scope.collection.isClientGroup = false
        $scope.collection.variantBase = []
        console.log('product handling guy Please Check here collection', $scope.collection)
      }

      $scope.addToVariantBaseArray = function (item) {
        if (!$scope.collection.variantBase) $scope.collection.variantBase = []

        if ($scope.collection.variantBase.length < 2) {
          // check if value already in the array
          if (!checkIfValueAlreadyInArray($scope.collection.variantBase, item)) { // if not in array
            // push to the variantBase array
            $scope.collection.variantBase.push(item)
          } else { // else
            for (var i = 0; i < $scope.collection.variantBase.length; i++) {
              if ($scope.collection.variantBase[i] == item) {
                $scope.collection.variantBase.splice(i, 1)
              };
            }
          }
        } else if ($scope.collection.variantBase.length == 2) {
          // remove the first element i.e remove 0th element
          $scope.collection.variantBase.splice(0, 1)
          // call this function again
          $scope.addToVariantBaseArray(item)
        }
      }

      function checkIfValueAlreadyInArray (array, item) {
        for (var i = 0; i < array.length; i++) {
          if (array[i] == item) {
            return true
          };
        }
        return false
      }
    }]
  }
})
