prismanoteApp.directive('productFilterByClientGroupCollection', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-client-group-collection.html',
    scope: false,
    controller: ['$scope', '$rootScope', '$company', '$retailerFilters', function ($scope, $rootScope, $company, $retailerFilters) {
      $scope.status

      function getCurrertCollectionFilters (clientGroupCollections) {
        $retailerFilters.getClientCollectionFilter()
          .then(function (collectionFilter) {
            collectionFilter = JSON.parse(collectionFilter)
            $scope.status = 'localStorage'
            if (collectionFilter && collectionFilter.collection && collectionFilter.collection.$in && collectionFilter.collection.$in.length) {
              var index = _.findIndex(clientGroupCollections, function (el) {
                return el._id.toString() === collectionFilter.collection.$in[0]
              })

              if (index > -1) $scope.setCollectionNew(collectionFilter.collection.$in[0], true, $scope.clientGroupCollections[index].en.name)

              if (index == -1) {
                if (collectionFilter.collection.$in[0] == $scope.serviceCollection._id.toString()) $scope.setCollectionNew(collectionFilter.collection.$in[0], true, $scope.serviceCollection.en.name)
              }
            } else {
              $scope.setCollectionNew(null, null, null)
            }
          })
          .catch(function (error) {
            console.log('Error', error)
          })
      }

      $scope.$on('client_collection_filter_remove', function (event, sort) {
        console.log('$collection =======>', sort)
        var remove = sort.removeFilter
        console.log('clientGroupCollections', $scope.clientGroupCollections)
        var index = _.findIndex($scope.clientGroupCollections, function (collection) {
          if (collection.en) {
            if (collection.en.name == remove.name) {
              return collection
            }
          }
        })

        if (index > -1) {
          $scope.clientGroupCollections[index].status = false
          $scope.setCollectionNew($scope.clientGroupCollections[index]._id, false, $scope.clientGroupCollections[index].en.name)
        }

        if (index == -1) {
          console.log('This here You are removing the services collections', $scope.serviceCollection, remove.name)
          if ($scope.serviceCollection.en.name == remove.name) {
            $scope.serviceCollection.status = false
            $scope.setCollectionNew($scope.serviceCollection._id, false, $scope.serviceCollection.en.name)
          }
        }
      })

      $scope.inCompanyClientGroupCollections = function () {
        $company.getCompanyCollectionsForFilter($rootScope.currentCompany.nameSlug)
          .then(function (collections) {
            $scope.clientGroupCollections = collections.collections
            $scope.serviceCollection = collections.servicesCollection
            getCurrertCollectionFilters($scope.clientGroupCollections)
            console.log('clientGroupCollections', $scope.clientGroupCollections)
          })
          .catch(function (error) {
            console.error('Error In Geting Collections', error)
          })
      }

      var filter = { collection: { $in: [] } }

      $scope.setCollectionNew = function (selectCollectionId, status, collectionName) {
        var selectCollectionIndex
        for (var i = 0; i < $scope.clientGroupCollections.length; i++) {
          if (($scope.clientGroupCollections[i]._id === selectCollectionId) && (status == true)) {
            $scope.clientGroupCollections[i].status = true
            selectCollectionIndex = i
          } else $scope.clientGroupCollections[i].status = false
        }

        var tempLength = $rootScope.selectdFilter.length

        for (var i = 0; i < tempLength; i++) {
          if ($rootScope.selectdFilter[i].filter == 'Collection') {
            $rootScope.selectdFilter.splice(i, 1)
            tempLength--
            i--
          }
        }

        if (status) {
          var temp = { name: collectionName, filter: 'Collection' }
          var index = _.findIndex($rootScope.selectdFilter, temp)
          if (index < 0) $rootScope.selectdFilter.push(temp)
        }

        if (status) filter.collection.$in[0] = selectCollectionId
        else filter.collection.$in = []

        $retailerFilters.setClientCollectionFilter(JSON.parse(JSON.stringify(filter)))

        if ($scope.status && $scope.status == 'localStorage') filter.status = 'localStorage'
        $scope.$emit('collection_filter_directive', { filter: filter })
      }
    }]
  }
})
