prismanoteApp.directive('productFilterByStrapMaterialNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-strap-material-new.html',
    scope: false,
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.setStrapMaterial = function () {
        var filter = { 'watch.strap.material': {} }
        var strapMaterials = []

        if ($scope.strapMaterial) {
          if ($scope.strapMaterial.leather) {
            strapMaterials.push('REAL_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'REAL_LEATHER', filter: 'Strap Material' })
          } else if (strapMaterials.includes('REAL_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('REAL_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'REAL_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.upperLeather) {
            strapMaterials.push('UPPER_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'UPPER_LEATHER', filter: 'Strap Material' })
          } else if (strapMaterials.includes('UPPER_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('UPPER_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'UPPER_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.puLeather) {
            strapMaterials.push('PU_LEATHER')
            if (_.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'PU_LEATHER', filter: 'Strap Material' })
          } else if (strapMaterials.includes('PU_LEATHER')) {
            strapMaterials.splice(strapMaterials.indexOf('PU_LEATHER'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PU_LEATHER', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.stainlessSteel) {
            strapMaterials.push('STAINLESS_STEEL')
            if (_.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'STAINLESS_STEEL', filter: 'Strap Material' })
          } else if (strapMaterials.includes('STAINLESS_STEEL')) {
            strapMaterials.splice(strapMaterials.indexOf('STAINLESS_STEEL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'STAINLESS_STEEL', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.titanium) {
            strapMaterials.push('TITANIUM')
            if (_.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'TITANIUM', filter: 'Strap Material' })
          } else if (strapMaterials.includes('TITANIUM')) {
            strapMaterials.splice(strapMaterials.indexOf('TITANIUM'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'TITANIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.aluminium) {
            strapMaterials.push('ALUMINIUM')
            if (_.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'ALUMINIUM', filter: 'Strap Material' })
          } else if (strapMaterials.includes('ALUMINIUM')) {
            strapMaterials.splice(strapMaterials.indexOf('ALUMINIUM'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'ALUMINIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.metal) {
            strapMaterials.push('METAL')
            if (_.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'METAL', filter: 'Strap Material' })
          } else if (strapMaterials.includes('METAL')) {
            strapMaterials.splice(strapMaterials.indexOf('METAL'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'METAL', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.plasticSilicon) {
            strapMaterials.push('PLASTIC_SILICONE')
            if (_.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'PLASTIC_SILICONE', filter: 'Strap Material' })
          } else if (strapMaterials.includes('PLASTIC_SILICONE')) {
            strapMaterials.splice(strapMaterials.indexOf('PLASTIC_SILICONE'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PLASTIC_SILICONE', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.canvas) {
            strapMaterials.push('CANVAS')
            if (_.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'CANVAS', filter: 'Strap Material' })
          } else if (strapMaterials.includes('CANVAS')) {
            strapMaterials.splice(strapMaterials.indexOf('CANVAS'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CANVAS', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.ceramics) {
            strapMaterials.push('CERAMICS')
            if (_.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'CERAMICS', filter: 'Strap Material' })
          } else if (strapMaterials.includes('CERAMICS')) {
            strapMaterials.splice(strapMaterials.indexOf('CERAMICS'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'CERAMICS', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.gold) {
            strapMaterials.push('GOLD')
            if (_.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'GOLD', filter: 'Strap Material' })
          } else if (strapMaterials.includes('GOLD')) {
            strapMaterials.splice(strapMaterials.indexOf('GOLD'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'GOLD', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if ($scope.strapMaterial.palladium) {
            strapMaterials.push('PALLADIUM')
            if (_.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Strap Material' }) < 0) $rootScope.selectdFilter.push({ name: 'PALLADIUM', filter: 'Strap Material' })
          } else if (strapMaterials.includes('PALLADIUM')) {
            strapMaterials.splice(strapMaterials.indexOf('PALLADIUM'))
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          } else {
            var index = _.findIndex($rootScope.selectdFilter, { name: 'PALLADIUM', filter: 'Strap Material' })
            if (index > -1) $rootScope.selectdFilter.splice(index, 1)
          }

          if (strapMaterials.length == 0) {
            // strapMaterials = ['TITANIUM', 'STAINLESS_STEEL', 'ALUMINIUM', 'METAL', 'PU_LEATHER', 'real leather', 'UPPER_LEATHER', 'PLASTIC_SILICONE', 'CANVAS', 'CERAMICS'];
          }

          filter['watch.strap.material'] = { $in: strapMaterials }

          $scope.$emit('strap_material_filter_directive', { filter: filter })
        }
      }
    }]
  }
})
