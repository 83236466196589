prismanoteApp.directive('purchaseOrderSettings', function () {
    return {
        restrict: 'E',
        replace: 'true',
        templateUrl: '../views/directives/purchase-order-settings.html',
        scope: {
            chat: '=chat'
        },
        controller: ['$scope', '$rootScope', '$state',
            function ($scope, $rootScope, $state) {
                $scope.localdata = {
                    prefillCompanySettings: true,
                    entryMethodCustomerValue: true,
                    margin: undefined,
                    discount: undefined,
                    storageFactor: undefined,

                    // isAutoOrderEnable: false,
                    // tenureTime: 'day',
                    // lastTenureTime: 'day',
                    // start_date: new Date(),
                    // phoneCountryCode: undefined,
                    // repairPhoneCountryCode: undefined,
                    // mycomment: '',
                    // canCompanySeeShopStatistics: false,
                    // shopStatisticsNotification: {},
                }
                console.log('purchaseOrderSettings called');
            }]
    }
})
