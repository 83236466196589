prismanoteApp.directive('productFilterByCaseSizeNew', function () {
  return {
    restrict: 'E',
    replace: 'true',
    templateUrl: '../views/directives/product-filter-by-case-size-new.html',
    scope: true,
    controller: ['$scope', '$retailerFilters', function ($scope, $retailerFilters) {
      var filter = {}

      $scope.caseSize = {
        min: 10,
        max: 150,
        options: {
          floor: 10,
          ceil: 150,
          step: 1,
          translate: function (value, sliderId, label) {
            switch (label) {
              case 'ceil':
                return value + ' mm +'
              case 'model':
                return value + ' mm'
              case 'high':
                if (value == 30) {
                  return value + ' mm +'
                } else {
                  return value + ' mm'
                }
              default:
                return value + ' mm'
            }
          },
          onEnd: function () {
            console.log('$scope.caseSize', $scope.caseSize)
            if ($scope.caseSize.min >= 10) {
              if (filter['watch.case.size'] == undefined) filter['watch.case.size'] = {}

              filter['watch.case.size'].$gte = $scope.caseSize.min

              if ($scope.caseSize.max < 151 && $scope.caseSize.max > 10) {
                filter['watch.case.size'].$lte = $scope.caseSize.max
              } else {
                filter['watch.case.size'] = undefined
              }
            } else if ($scope.caseSize.max < 151) {
              filter['watch.case.size'].$lte = $scope.caseSize.max
            }
            $scope.$emit('case_size_filter_directive', { filter: filter })
          }
        }
      }
    }]
  }
})
