prismanoteApp.directive('statisticsNew', function () {
	return {
		restrict: 'E',
		replace: 'true',
		templateUrl: '../views/directives/statistics-new.html',
		scope: {
			'shopid': '=shopid',
			'companyid': '=companyid'
		},
		controller: [
			'$scope', '$rootScope', '$q', '$shop', '$collection' ,'$state', '$productConfig', '$api', '$language', '$retailer', '$q', '$brand', 'cookieService', '$uibModal',
			function ($scope, $rootScope, $q, $shop, $collection, $state, $productConfig, $api, $language, $retailer, $q, $brand, cookieService, $uibModal) {
			$scope.collectionWiseData = []
			$scope.collectionWiseSwitch = false
			$scope.period = "week";
			$scope.filter = {}
			$scope.year = moment().year()
			$scope.earnedPoints = 0
			$scope.totalEarnedPointsDiscount = 0
			/* $scope.filter = {
				startDate: new Date(new Date(moment().subtract(1, 'week')).setHours(0, 0, 0)),
				endDate: new Date(new Date().setHours(23, 59, 59))
			}; */
			
			$scope.loadPage = {
				loading: true
			}
			$scope.currentWeek = moment().week()
			$scope.startDateOptions = {
				showWeeks: true,
				startingDay: 1
			}

			$scope.endDateOptions = {
				showWeeks: true,
				startingDay: 1
			}

			$scope.chartMode = 'turnover';
			$scope.statisticsData = {};
			
			$scope.suppliers = {};
			$scope.employees = [];
			
			$scope.statisticsFilter = {
				selectedGroups: [],
				selectedSuppliers: [],
				selectedEmployees: []
			};
			$scope.startDateForDisplay = ''
			$scope.endDateForDisplay = ''
			$scope.vatAmountInEuros = 0;
			$scope.collectionData = []
			$scope.totalTurnover = 0;
			$scope.totalVat = 0;
			$scope.turnOverWithoutVat = 0;
			$scope.grossProfit = 0;
			$scope.totalNumberOfOrders = 0;
			$scope.showLegend = false;
			$scope.currentPage = 1
			$scope.showStatisticsFor;
			$scope.showLess = false;
			$scope.pageSize = 10
			$scope.isSupplierAttachedToShop = true;
			$scope.totalPriceWithVat =0;
			$scope.totalPurchasePrice = 0;
			$scope.totalGross = 0;
			$scope.totalGoldSell = 0;
			$scope.showDates = false
			// storing currentShop and currentCompany into scope
			$scope.currentCompany = $rootScope && $rootScope.currentCompany && $rootScope.currentCompany._id ? $rootScope.currentCompany : {};
			$scope.currentShop = $rootScope && $rootScope.currentShop && $rootScope.currentShop._id ? $rootScope.currentShop : {};
			$scope.hideSupplier = $rootScope.currentCompany && $rootScope.currentCompany['hideSupplierSelection'] ? $rootScope.currentCompany['hideSupplierSelection'] : false;
			if($scope.hideSupplier){
				delete $rootScope.currentCompany;
			}

			// Check if this request is from company portal or retailer or admin
			if($rootScope.currentShop && $rootScope.currentShop._id){
				$scope.showStatisticsFor = "shop"
			} else if ($rootScope.currentCompany && $rootScope.currentCompany._id){
				$scope.showStatisticsFor = "company";
				$scope.suppliers = [
					{
						_id: $rootScope.currentCompany._id,
						name: $rootScope.currentCompany.name,
						ticked: true
					}
				];
				$scope.showLess = true;
			} else {
				$scope.showStatisticsFor = "admin";
			}

			$scope.resetFilters = function () {
				$scope.setFilter('day')
			}

			$scope.getYears = function () {
				var cYear = moment().year()
				//Define in which year we should start showing years
				var startYear = 2018
				$scope.years = []
		  
				for(var i = startYear; i < cYear +1; i++ ){
				  $scope.years.push(i)
				}
			  }
			  $scope.getMonths = function () {
				$scope.months = [
				  {
					id: 0,
					name: $language.translate('JANUARY')
				  },
				  {
					id: 1,
					name: $language.translate('FEBRUARY'),
				  },
				  {
					id: 2,
					name: $language.translate('MARCH')
				  },
				  {
					id: 3,
					name: $language.translate('APRIL')
				  },
				  {
					id: 4,
					name: $language.translate('MAY')
				  },
				  {
					id: 5,
					name: $language.translate('JUNE')
				  },
				  {
					id: 6,
					name: $language.translate('JULY')
				  },
				  {
					id: 7,
					name: $language.translate('AUGUST')
				  },
				  {
					id: 8,
					name: $language.translate('SEPTEMBER')
				  },
				  {
					id: 9,
					name: $language.translate('OCTOBER')
				  },
				  {
					id: 10,
					name: $language.translate('NOVEMBER')
				  },
				  {
					id: 11,
					name: $language.translate('DECEMBER')
				  }
				]
			  }
			  $scope.getWeeks = function () {
				var tWeek = $language.translate('WEEK')
				var weeks = moment().year($scope.year).isoWeeksInYear()
		  
				$scope.weeks = []
		  
				for (var i = 1; i < (weeks +1); i++) {
				  $scope.weeks.push(
					{
					  id: i,
					  name: tWeek + ' ' + i
					}
				  )
				}
			  }
		  
			  $scope.getYears()
			  $scope.getMonths()
			  $scope.getWeeks()

			$scope.collectionWiseSwitchChanged = function(data) {
				$scope.collectionWiseSwitch = data;
			}

			$scope.checkComapnyStatisticsValidity = function() {
				return $q(function (resolve, reject) {
					if ($rootScope.currentShop && $rootScope.currentShop._id) {
						resolve();
					} else if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
						// This statistics from company portal for a particular shop. So, we need to check this particular shop had attached favourite supplier to this logged in company or not
						$api
						.get('v2/statistics/validity/shop/'+ $scope.shopid + '/company/' + $rootScope.currentCompany._id)
						.then(function(res) {
							if (res && res.data && res.data.result && res.data.result.isValid) {
								$scope.isSupplierAttachedToShop = true;
								resolve();
							} else {
								$scope.isSupplierAttachedToShop = false;
								reject({
									message: "retailer didn't attach you as a favourite supplier"
								});
							}
						})
						.catch(function(error) {
							reject(error);
						})
					}
				})
			};

			$scope.openGenerateReportModal = function () {
				var reportModal = $uibModal.open({
				  templateUrl: '../views/modal/retailer-generate-dayclosure-report-modal.html',
				  controller: 'retailerGenerateDayClosureReportModalController',
				  size: 'lg',
				  keyboard: false,
					resolve: {
						startDate: function () {
							return $scope.filter && $scope.filter.startDate ? $scope.filter.startDate : null
						},
						endDate: function () {
							return $scope.filter && $scope.filter.endDate ? $scope.filter.endDate : null
						}
					}

				})
		  
				reportModal.result.then(function (result) {
		  
				}, function () {
		  
				})
			}

			$scope.openCashRegister = function () {
				$state.go('retailer.till')
			  }
			  
			  $scope.openSupplier = function () {
				$state.go('retailer.suppliers')

			  }

			$scope.showDate = function(data) {
				$scope.showDates = data
			}

			
			$scope.savePageSize = function (pageSize) {
				cookieService.setCookie('servicesPageSize=' + pageSize, 31536000)
			  }
		  
			  $scope.saveCurrentPage = function (currentPage) {
				cookieService.setCookie('servicesCurrentPage=' + currentPage, 1800)
			  }

			  $scope.fetchStatistics = function () {
			 	$scope.filter['suppliers'] = [];
			 	
				$scope.loadPage.loading = true;
				// $scope.filter['suppliers'] = $scope.statisticsFilter.selectedSuppliers.map(function (key) { return key._id; });
				$scope.filter['collections'] = $scope.statisticsFilter.selectedGroups.map(function (key) { return key._id; });
				$scope.filter['employees'] = $scope.statisticsFilter.selectedEmployees.map(function (key) { return key._id; });
				
				if ($rootScope.currentCompany && $rootScope.currentCompany._id) {
					$scope.filter['suppliers'] = [$rootScope.currentCompany._id];
				}
				
				if($scope.statisticsFilter.selectedSuppliers.length == 0 && $rootScope.currentCompany == undefined && $scope.companyid && $scope.companyid != undefined) {
					$scope.filter['suppliers'] = [$scope.companyid]
				}
				
				if ($scope.hideSupplier && $scope.currentCompany && $scope.currentCompany._id) {
					$scope.filter['suppliers'] = [$scope.currentCompany._id];
				}
				
				
				$scope.startDateForDisplay = moment($scope.filter.startDate).format('DD-MM-YYYY')
				$scope.endDateForDisplay = moment($scope.filter.endDate).format('DD-MM-YYYY')
					return $scope.checkComapnyStatisticsValidity().then(function () {
						return $api.get('shop/' + $scope.shopid + '/statistics', {
								filters: $scope.filter,
								language: $rootScope.language,
								collectionWiseSwitch: $scope.collectionWiseSwitch
							}, null, 2);
					}).then(function (res) {
						$scope.buildChartForTheStockValue($scope.filter.suppliers);

						$scope.statisticsData = res.data.result;
						if (res.data.result && res.data.result.earnings) {
						$scope.earnedPoints = res.data.result.earnings
						} else {
							$scope.earnedPoints = 0
						}
						// var result = $scope.statisticsData.collectionWiseData.map(a => a.category);
						
						$scope.collectionWiseData = []
						var collectionDataCategoryWise = []
						var selectedNames = $scope.statisticsData.collectionWiseData.map(function(id) {
							return id.category;
						  });
						  var unique = selectedNames.filter(onlyUnique);

						  function onlyUnique(value, index, self) {
							return self.indexOf(value) === index;
						  }
						  
						  for (var k = 0; k < unique.length; k++) {
							for (var l = 0; l < $scope.statisticsData.collectionWiseData.length; l++) {
							  if (unique[k] === $scope.statisticsData.collectionWiseData[l].category) {
								  var obj = {
									  categoryName: $scope.statisticsData.collectionWiseData[l].category,
									  data: {collectionName: $scope.statisticsData.collectionWiseData[l].collectionName,
									  purchasePriceInclVAT: $scope.statisticsData.collectionWiseData[l].total,
									  purchasePriceExclVAT: $scope.statisticsData.collectionWiseData[l].purchasePrice,
									  salePrice: $scope.statisticsData.collectionWiseData[l].price}
								  }
								  collectionDataCategoryWise.push(obj)
							  }
							  
							}
							  
						  }
						
						var lookup = {};
						var result = [], mainCollectionDataCategoryWise = [];
						
						var mainData = collectionDataCategoryWise.map(function (artikel) {
							var name = artikel.data.collectionName;

							if (!(name in lookup)) {
								lookup[name] = 1;
								result.push(name);
							}
						})
						for(i = 0; i < result.length; i++) {
							var totalpurchasePriceExclVAT = 0, totalpurchasePriceInclVAT = 0, totalsalePrice = 0
							var idVal = result[i].toString()
      						var resultsData = collectionDataCategoryWise.filter(function (f) { return f.data.collectionName == idVal })
							resultsData.map(function (artikel) {
								totalpurchasePriceExclVAT += artikel.data.purchasePriceExclVAT
								totalpurchasePriceInclVAT += artikel.data.purchasePriceInclVAT
								totalsalePrice += artikel.data.salePrice
							})
							resultsData[0].data.purchasePriceExclVAT = totalpurchasePriceExclVAT
							resultsData[0].data.purchasePriceInclVAT = totalpurchasePriceInclVAT
							resultsData[0].data.salePrice = totalsalePrice
							mainCollectionDataCategoryWise.push(resultsData[0])
						  }
						  var groups = mainCollectionDataCategoryWise.reduce(function(obj,item){
							obj[item.categoryName] = obj[item.categoryName] || [];
							obj[item.categoryName].push(item.data);
							return obj;
						}, {});
						$scope.collectionWiseData = Object.keys(groups).map(function(key){
							return {item: key, data: groups[key]};
						});
						  for (var m = 0; m < $scope.collectionWiseData.length; m++) {
							$scope.totalPurchasePriceInclVAT = 0, $scope.totalPurchasePriceExclVAT = 0, $scope.totalGrossProfitExclVAT = 0
							for (var n = 0; n < $scope.collectionWiseData[m].data.length; n++) {
							  $scope.totalPurchasePriceInclVAT += $scope.collectionWiseData[m].data[n].purchasePriceInclVAT 
							  $scope.totalPurchasePriceExclVAT += $scope.collectionWiseData[m].data[n].purchasePriceInclVAT - ($scope.collectionWiseData[m].data[n].salePrice - $scope.collectionWiseData[m].data[n].purchasePriceExclVAT)
							  $scope.totalGrossProfitExclVAT += $scope.collectionWiseData[m].data[n].salePrice - $scope.collectionWiseData[m].data[n].purchasePriceExclVAT
							}
							$scope.collectionWiseData[m]["totalPurchasePriceInclVAT"] = $scope.totalPurchasePriceInclVAT
							$scope.collectionWiseData[m]["totalPurchasePriceExclVAT"] = $scope.totalPurchasePriceExclVAT
							$scope.collectionWiseData[m]["totalGrossProfitExclVAT"] = $scope.totalGrossProfitExclVAT
						  }
						$scope.collectionData = []
						if ($scope.statisticsData && $scope.statisticsData.collectionWiseEarning) {
							Object.keys($scope.statisticsData.collectionWiseEarning).forEach(function (key) {
								var value = $scope.statisticsData.collectionWiseEarning[key];
								var objData = {
									collectionName: value.name,
									totalGrossIncome: value.totalGrossIncome,
									totalGrossProfit: value.totalGrossProfit,
									totalVatAmountInEuros: value.totalVatAmountInEuros,
									earnedPointsDiscount: value.totalDiscountPointsEarningWithoutVat
								}
								$scope.collectionData.push(objData)

							});
							$scope.totalPriceWithVat = 0; $scope.totalPurchasePrice = 0; $scope.totalGross = 0; $scope.vatAmountInEuros = 0;
							if ($scope.collectionData.length > 0) {
								$scope.totalEarnedPointsDiscount = 0
								for (var i = 0; i < $scope.collectionData.length; i++) {
									$scope.totalPriceWithVat += $scope.collectionData[i].totalGrossIncome;
									$scope.totalPurchasePrice += ($scope.collectionData[i].totalGrossIncome - $scope.collectionData[i].totalGrossProfit)
									$scope.totalGross += $scope.collectionData[i].totalGrossProfit;
									console.log('$scope.collectionData[i].totalVatAmountInEuros ', $scope.collectionData[i].totalVatAmountInEuros);
									$scope.vatAmountInEuros += $scope.collectionData[i].totalVatAmountInEuros || 0;
									$scope.totalEarnedPointsDiscount += $scope.collectionData[i].earnedPointsDiscount == null ? 0 : Number($scope.collectionData[i].earnedPointsDiscount);
								}
							}
							console.log('$scope.vatAmountInEuros ', $scope.vatAmountInEuros);
							console.log('$scope.totalGross ', $scope.totalGross);
						}
						if ($scope.statisticsData.transactions && $scope.statisticsData.transactions.goldSell) {
							$scope.totalGoldSell =  $scope.statisticsData.transactions.goldSell.totalEarning
						}
						
						$scope.totalNumberOfOrders = $scope.statisticsData.transactions.shopPurchase.paid +
							$scope.statisticsData.transactions.services.paid -
							$scope.statisticsData.transactions.shopPurchase.refund -
							$scope.statisticsData.transactions.services.refund;

							
						$scope.totalTurnover = $scope.statisticsData.transactions.shopPurchase.totalEarning +
							$scope.statisticsData.transactions.services.totalEarning +
							$scope.statisticsData.transactions.shopPurchase.totalRefundAmount +
							$scope.statisticsData.transactions.services.totalRefundAmount;
						console.log('$scope.totalTurnover ', $scope.totalTurnover);
						$scope.totalVat = $scope.statisticsData.transactions.shopPurchase.totalVat +
							$scope.statisticsData.transactions.services.totalVat +
							$scope.statisticsData.transactions.services.refundedTotalVat +
							$scope.statisticsData.transactions.shopPurchase.refundedTotalVat;

						$scope.grossProfit = $scope.statisticsData.transactions.shopPurchase.grossProfitExcludingVat +
							$scope.statisticsData.transactions.services.grossProfitExcludingVat +
							$scope.statisticsData.transactions.services.refundedGrossProfitExcludingVat +
							$scope.statisticsData.transactions.shopPurchase.refundedGrossProfitExcludingVat;

						$scope.totalDiscount = $scope.statisticsData.transactions.shopPurchase.totalDiscount +
							$scope.statisticsData.transactions.services.totalDiscount;

						$scope.totalRefundAmount = $scope.statisticsData.transactions.shopPurchase.totalRefundAmount +
							$scope.statisticsData.transactions.services.totalRefundAmount;

						// set suppliers
						$scope.suppliers = [];

						for (var i = 0; i < $scope.statisticsData.suppliers.length; i++) {
							if ($scope.showStatisticsFor === "company") {
								if ($scope.filter['suppliers'].indexOf($scope.statisticsData.suppliers[i]._id) >= 0) {
									$scope.statisticsData.suppliers[i]['ticked'] = true;
									$scope.statisticsData.suppliers[i]['disabled'] = true;
									$scope.suppliers.push($scope.statisticsData.suppliers[i]);
									break;
								}
							} else {
								if ($scope.filter['suppliers'].indexOf($scope.statisticsData.suppliers[i]._id) >= 0) {
									$scope.statisticsData.suppliers[i]['ticked'] = true;
								}
								$scope.suppliers.push($scope.statisticsData.suppliers[i]);
							}
						};


						// set collections
						$scope.groups = $scope.statisticsData.articleGroupCollection.map(function (key) {
							if ($scope.filter['collections'].indexOf(key._id) >= 0) {
								key['ticked'] = true;
							} else {
								key['ticked'] = false;
							}

							return key;
						});

						// set employees
						$scope.employees = $scope.statisticsData.employees.map(function (key) {
							if ($scope.filter['employees'].indexOf(key._id) >= 0) {
								key['ticked'] = true;
							} else {
								key['ticked'] = false;
							}

							return key;
						});

						$scope.buildChart($scope.statisticsData.chart, $scope.chartMode);
					}).catch(function (err) {
						$scope.loadPage.loading = false;
						console.error("err", err);
					})
			};

			$scope.updateByDateRange = function(){
				$scope.filter.startDate = new Date(new Date($scope.filter.startDate).setHours(0, 0, 0));
				$scope.filter.endDate = new Date(new Date($scope.filter.endDate).setHours(23, 59, 59));
				$scope.fetchStatistics();
				$scope.showDates = true

			};

			$scope.setFilter = function(period){
				$scope.filter.period = period;
				$scope.filter.endDate = new Date(new Date().setHours(23, 59, 59));

				if(period == 'day'){
					$scope.period = "day";
					$scope.filter.startDate = new Date(new Date().setHours(0, 0, 0));
				}
				if(period == 'week'){
					$scope.period = "week";
					$scope.filter.startDate = new Date(new Date(moment().isoWeekday(1).hour(0).minute(0).seconds(0)));
				}
				if(period == 'month'){
					$scope.period = "month";
					$scope.filter.startDate = new Date(new Date(moment().startOf('month')).setHours(0, 0, 0));
				}

				if(period == 'year'){
					$scope.period = "year";
					$scope.filter.startDate = new Date(new Date(moment().startOf('year')).setHours(0, 0, 0));
				}
				$scope.fetchStatistics();
			}
			if($scope.hideSupplier){
				$scope.setFilter('day')
			} else {
				$scope.setFilter('day')
			}
			
			
			$scope.filterChanged = function(value, time){
				if (time === 'year') {
				  $scope.filter.startDate = moment().year(value).startOf('year').toDate()
				  $scope.filter.endDate = moment().year(value).endOf('year').toDate()
				}
				if (time === 'month') {
				  $scope.filter.startDate = moment().year($scope.year).month(value).startOf('month').toDate()
				  $scope.filter.endDate = moment().year($scope.year).month(value).endOf('month').toDate()
				}
				if (time === 'week') {
					$scope.filter.startDate = moment().year($scope.year).week(value).isoWeekday(1).toDate()
					$scope.filter.endDate = moment().year($scope.year).week(value).isoWeekday(7).toDate()
				}
		  
				if(time === 'date') {
				  $scope.filter.period = 'custom'
				}
			  }

			$scope.getShop = function(){
				return $q(function (resolve, reject) {
					if($rootScope.currentShop){
						return resolve($rootScope.currentShop)
					}else{
						$retailer.getShop()
						.then(function(shop) {
							return resolve(shop)
						})
						.catch(function(reason){
							return reject(reason);
						})
					}
				})
			}

			$scope.supplierClose = function() {
				$scope.fetchStatistics();
			};

			$scope.toggleChart = function(){
				if($scope.chartMode == 'turnover'){
					$scope.chartMode = 'profit';
				}else{
					$scope.chartMode = 'turnover';
				}
				$scope.buildChart($scope.statisticsData.chart, $scope.chartMode)
			}

			$scope.assignDataInActualGraph = function(data, mode) {
				var ctx = document.getElementById('chart')
				if(ctx) {
					ctx = ctx.getContext('2d')
					if ($scope.chartInstance) {
						$scope.chartInstance.destroy();
					}

					$scope.chartInstance = new Chart(ctx, {
						type: 'line',

						// The data for our dataset
						data: {
							labels: data.labels,
							datasets: data[mode]
						},

						// Configuration options go here
						options: {
							responsive: true,
							legend: {
								display: $scope.showLegend
							},
							title: {
								display: true,
								text: (mode == 'turnover' ? $language.translate('TURNOVER') : $language.translate('GROSS_PROFIT') + '(Ex. VAT)') + ' ' + $language.translate('PER_ARTICLEGROUP')
							},
							tooltips: {
								mode: 'index'
							},
							hover: {
								mode: 'index'
							},
							scales: {
								yAxes: [{
									stacked: true
								}]
							},
							line: {
								stepped: true
							}
						}
					});
				}
				$scope.loadPage.loading = false;
			};

			$scope.toggleLegend = function() {
				$scope.showLegend = !$scope.showLegend;
				$scope.buildChart($scope.statisticsData.chart, $scope.chartMode);
			}

			$scope.buildChart = function(data, mode){
				if (document.getElementById('chart')) {
					$scope.assignDataInActualGraph(data, mode);					
				} else {					
					if (data.profit && data.profit.length && data.turnover && data.turnover.length) {
						setTimeout(function() {
							$scope.assignDataInActualGraph(data, mode);
						}, 200);
						$scope.loadPage.loading = false;
					} else {
						$scope.loadPage.loading = false;
					}
				}
			};

			$scope.articleGroupClose = function() {
				$scope.fetchStatistics();
			};

			$scope.employeeClose = function() {
				$scope.fetchStatistics();
			};
			
			$scope.$on('changeSelectedShop', function () {
				$retailer.getShop()
				.then(function (shop) {
					$rootScope.currentShop = shop;
					$scope.currentShop = shop;
					$scope.fetchStatistics();
				})
			});

			$scope.changeShoWLess = function() {
				$scope.showLess = !$scope.showLess;
			};

			$scope.assignDataInStockvalueGraph = function (data) {
				var ctx = document.getElementById('stockvaluechart')
				if(ctx) {
					ctx = ctx.getContext('2d')
					if ($scope.chartInstanceOfStockValue) {
						$scope.chartInstanceOfStockValue.destroy();
					}

					$scope.chartInstanceOfStockValue = new Chart(ctx, {
						"type": "line",
						"data": {
							labels: data.labels,
							"datasets": [
								{
									"label": "Voorraadwaardemutaties gedurende 3 maanden t.o.v. gemiddelde",
									data: data.data,
									"fill": false,
									"borderColor": "rgb(75, 192, 192)",
									"lineTension": 0.001
								}
							]
						},
						"options": {
							annotation: {
								annotations: [{
									type: 'line',
									mode: 'horizontal',
									scaleID: 'y-axis-0',
									value: data.average || 0,
									borderColor: 'rgb(169, 169, 169)',
									borderWidth: 2,
									label: {
										enabled: false,
										content: 'Test label'
									}
								}]
							}
						}
						// type: 'line',

						// // The data for our dataset
						// data: {
						// 	labels: ["10-07-19", "10-08-19", "10-09-19", "10-10-19",],
						// 	datasets: [{
						// 		// label: "ONLY LABLE",
						// 		data: [07, 06, 10, 09],
						// 		borderColor: [
						// 			'rgba(255, 99, 132, 0.2)',
						// 			'rgba(255, 99, 0, 5)',
						// 		]
						// 		// backgroundColor: 'rgba(54, 162, 235, 1)',
						// 		// fill: "origin",
						// 	}]
						// },

						// // Configuration options go here
						// options: {
						// 	responsive: true,
						// 	title: {
						// 		display: true,
						// 		text: $language.translate('STOCK_VALUE_GRAPH')
						// 	},
						// 	tooltips: {
						// 		mode: 'index'
						// 	},
						// 	// hover: {
						// 	// 	mode: 'index'
						// 	// },
						// 	// scales: {
						// 	// 	yAxes: [{
						// 	// 		stacked: true
						// 	// 	}]
						// 	// },
						// 	// line: {
						// 	// 	stepped: true
						// 	// },
						// 	// annotation: {
						// 	// 	annotations: [{
						// 	// 		type: 'line',
						// 	// 		mode: 'horizontal',
						// 	// 		scaleID: 'y-axis-0',
						// 	// 		value: 5,
						// 	// 		borderColor: 'rgb(75, 192, 192)',
						// 	// 		borderWidth: 4,
						// 	// 		label: {
						// 	// 			enabled: false,
						// 	// 			content: 'Test label'
						// 	// 		}
						// 	// 	}]
						// 	// }
						// }
					});
				}
				// $scope.loadPage.loading = false;
			};

			$scope.assignDataInRevenueGraph = function (data) {
				var ctx = document.getElementById('revenuevaluechart')
				if(ctx) {
					ctx = ctx.getContext('2d')
					if ($scope.chartInstanceOfRevenue) {
						$scope.chartInstanceOfRevenue.destroy();
					}

					$scope.chartInstanceOfRevenue = new Chart(ctx, {
						"type": "line",
						"data": {
							labels: data.labels,
							"datasets": [
								{
									"label": "Gemiddelde omzet per maand laatste 3 maanden",
									data: data.data,
									"fill": false,
									"borderColor": "rgb(75, 192, 192)",
									"lineTension": 0.001
								}
							]
						},
						"options": {
							annotation: {
								annotations: [{
									type: 'line',
									mode: 'horizontal',
									scaleID: 'y-axis-0',
									value: data.average || 0,
									borderColor: 'rgb(169, 169, 169)',
									borderWidth: 2,
									label: {
										enabled: false,
										content: 'Test label'
									}
								}]
							}
						}
					});
				}
			}

			$scope.buildChartForTheStockValue = function (suppliers) {
				var body = {
					shopId: $scope.shopid,
					suppliers: $scope.filter.suppliers
				}
				$brand.getTheStockvalueForGraph(body).then(function(response) {
					// var stockvaluegraphdata = JSON.parse(JSON.stringify(response.data.data));
					$scope.assignDataInStockvalueGraph(response.data.data.stockvalue);
					$scope.assignDataInRevenueGraph(response.data.data.revenue);
				}).catch(function(error) {
					console.error('error: ', error);
				});
			};

			$scope.editGoldStock = function() {
				var modalInstance = $uibModal.open({
					templateUrl: '../views/modal/gold-stock-correction-modal.html',
					controller: 'goldStockCorrectionModalController',
					size: 'lg'
				});
	
				modalInstance.result.then(function (result) {
					// Need to update gold stock and value
					$scope.fetchStatistics();
				}, function () {
					//dismissed
				})
			};
		}]
	};
});
